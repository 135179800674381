import React from "react";
import { withStyles, Fab } from "@material-ui/core";
import PropTypes from "prop-types";
import cx from "classnames";

import buttonStyle from "variables/styles/buttonStyle";

function ButtonFab({ ...props }) {
  const {
    classes,
    color,
    round,
    children,
    floating,
    fullWidth,
    disabled,
    ...rest
  } = props;
  const btnClasses = cx({
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled
  });
  return (
    <Fab {...rest}  className={classes.button + " " + btnClasses + " " + (floating ? classes.floating : null)}>
      {children}
    </Fab>
  );
}

ButtonFab.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "simple",
    "transparent",
    "iceq"
  ]),
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool
};

export default withStyles(buttonStyle)(ButtonFab);
