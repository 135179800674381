import React, { Component } from 'react';
import { Grid, List, ListItem } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import {
withAuth,
RegularCard,
ButtonFab,
ItemGrid
} from "components";

import ClientRegionStore from 'store/ClientRegionStore';
import  { observer } from "mobx-react";
import RegionForm from "./RegionForm"

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};


const Regions = observer(
class Regions extends Component {


  constructor(props) {
    super(props)
    this.state = {
      loaded: false
    }
  }

  componentDidMount(){
    ClientRegionStore.loadRegions()
    this.setState({
      loaded: true
    })
  }

  handleClickOpen = (idx) => {
     if(idx.currentTarget.getAttribute('arrayidx') < 0){
       ClientRegionStore.setSelectedRegionToDefault()
     } else {
       ClientRegionStore.setSelectedRegionToSelection(
         ClientRegionStore.regions[idx.currentTarget.getAttribute('arrayidx')]
       );
     }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
      <RegionForm/>
      <RegularCard headerColor="iceq" cardTitle="Regions" content={
        <List>
           { ClientRegionStore.regions.map((region, i) => (
           <ListItem key={i} button divider onClick={this.handleClickOpen} arrayidx={i} regionid={region.id}>
            <Grid container>
              <ItemGrid xs={12} sm={12} md={3}>{region.id}</ItemGrid>
              <ItemGrid xs={12} sm={12} md={6}>{region.name}</ItemGrid>
              <ItemGrid xs={12} sm={12} md={3}>{region.region_code}</ItemGrid>
            </Grid>
          </ListItem>
          ))}
        </List>
      }/>
      <ButtonFab 
        color="primary"
        aria-label="add"
        className={classes.button}
        onClick={this.handleClickOpen}
        arrayidx="-1"
        floating={true}
        regionid="new"
      >
        <AddIcon />
      </ButtonFab>
      </div>
    )
  }
})

export default withStyles(styles)(withAuth(Regions));
