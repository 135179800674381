import React, { Component, Fragment } from 'react';
import LocationsStore from 'store/LocationStore';
import IceQUtils from 'store/IceQUtils';
import { Grid } from "@material-ui/core";
import  { observer } from "mobx-react";
import LocationForm from "./LocationForm";
import FreezerForm from "./FreezerForm";
import { withStyles } from '@material-ui/core/styles';
import { withAuth, Button } from "components";
import { Link } from "react-router-dom";
import { Add as AddIcon, Edit } from "@material-ui/icons";
import {SignedInContext} from 'Contexts/signedin-context';
import Typography from "@material-ui/core/Typography";
import Pivot from 'quick-pivot';
import { DateTime } from 'luxon';
import qs from 'qs';
import  { Bar, BarChart, ResponsiveContainer,
  CartesianGrid, XAxis, Tooltip } from 'recharts';
import randomColor from 'randomcolor';
import IconButton from '@material-ui/core/IconButton';
import {
  ZoomChart,
  Table,
  RegularCard,
  ItemGrid,
  ButtonFab,
  SwitchCard
} from "components";
const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

const colorList = randomColor({count: 100,hue: 'blue'})
const Warehouse = observer(
class Warehouse extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      showAddFreezer: false, 
      detailTimeSerieModel: [],
      showLocationDetails: 'none',
      showAddTicket: false};
  }

  componentDidMount() {
   if("/locations/add" === this.props.location.pathname) {
     LocationsStore.setCurrentLocationToDefault();
   } else {
     if(this.props.match.params.id){
       var preferredFreezer = null
       if(this.props.location.search) {
          preferredFreezer = (qs.parse(this.props.location.search,
            {  ignoreQueryPrefix: true })).preferred_freezer;
       }
       LocationsStore.getLocation(this.props.match.params.id, null, preferredFreezer);
     } else {

     }
   }
 }

 handleAddFreezer = () => {
   this.setState({showAddFreezer: true})
 }

pivotFilter = (pivot) => {
  return (IceQUtils.pivotFilter(pivot))
}

pivotHeader = (val) => {
    return (IceQUtils.pivotHeader(val))
}

 handleClick = (data,idx) => {
   var mod_id = 0
    this.setState({detailTimeSerieModel:[]})
    mod_id = LocationsStore.currentLocation.warehouse_inventory_status.find((x) => x.model === data['sum unit']).model_id
    LocationsStore.locationStats("timeseries_by_model_by_condition", mod_id, ((res) => {
      var pivot = new Pivot((
        res.map((data, index) => {
          return {"unix_ts": data.unix_ts, "Condition": data.name, "total": data.total}
        })
      ), ["unix_ts"], ["Condition"] ,["total"], "sum");
      var val = this.pivotFilter(pivot)
      var headers = this.pivotHeader(val)

      this.setState({
        detailTimeSerieModel: val,
        detailTimeSerieModelHeaders: headers
      })
    }), null)
   //console.log(data, idx)
 }

  render() {
    const { classes } = this.props;
    if(!LocationsStore.currentLocation || !LocationsStore.currentLocation.warehouse_inventory_status) {return null}
    var pivot =null
    if(LocationsStore.currentLocation.warehouse_inventory_status){
      pivot = new Pivot((
        LocationsStore.currentLocation.warehouse_inventory_status.map((data, index) => {
          return {"condition": data.name, "model": data.model, "unit": data.unit}
        })
      ), ["model"], ["condition"] ,["unit"], "sum");
    }
    return (
      <div>
            <SignedInContext.Consumer>
              {({signedInUser, changeMe}) => {
                  if(signedInUser) {
                    return (
                    <div>
                      <SwitchCard headerColor="iceq" titleOnly={LocationsStore.currentLocation.id > 0 && this.state.showLocationDetails==='none'} cardTitle={
                      <Grid container>
                        <ItemGrid xs={7} sm={8} md={9}>
                        {LocationsStore.currentLocation && 
                          LocationsStore.currentLocation.id > 0 ? (
                            <Grid container>
                            <ItemGrid xs={12} sm={12} md={12}>{LocationsStore.currentLocation.name}</ItemGrid>
                            <ItemGrid xs={12} sm={12} md={12}>
                            <Typography variant="caption"
                              component="title"
                              className={classes.cardSubtitle}>
                              {
                                LocationsStore.currentLocation.street + " " +
                                LocationsStore.currentLocation.city + " " + 
                                LocationsStore.currentLocation.state + " " + 
                                LocationsStore.currentLocation.zip + " " +
                                LocationsStore.currentLocation.country + " " 
                              } 
                            </Typography>
                          </ItemGrid>
                          </Grid>
                          ) : (
                            <Grid container>
                            <ItemGrid xs={12} sm={12} md={12}>Add Location</ItemGrid>
                          </Grid>
                          )}

                        </ItemGrid>
                        <ItemGrid xs={5} sm={4} md={3} style={{textAlign: "right"}}>  
                        { LocationsStore.currentLocation.id > 0?(
                          <Fragment>
                          <IconButton aria-label="Details" onClick={(res) => {
                            this.setState({showLocationDetails: (
                              this.state.showLocationDetails==='none' || this.state.showLocationDetails==='detail' ? 'edit'  :'none'
                            )})}}><Edit /></IconButton>
                          </Fragment>
                        ):null}

                        </ItemGrid>
                      </Grid>} 

                       
                      content={ 
                        this.state.showLocationDetails ==='edit' || 
                          !LocationsStore.currentLocation || 
                          LocationsStore.currentLocation.id <=0 ? (
                          <LocationForm
                          data={LocationsStore.currentLocation}
                          store={LocationsStore}
                          loaded={(LocationsStore.currentLocation ? true :false)}
                          loggedInUser={signedInUser}
                        />
                        ): this.state.showLocationDetails ==='detail'&& LocationsStore.currentLocation.id > 0  ? (
                          <Grid container>
                          <ItemGrid xs={12} sm={12} md={12}>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                        </ItemGrid>
                      </Grid>
                        )
                        : null }/>
                    <Grid container>
                      <ItemGrid xs={12} sm={12} md={6}>
                      <RegularCard plainCard={false} headerColor="orange"
                        cardSubtitle="Leader Board" content={(
                          <Table tableHeaderColor="warning"
                            tableHead={["Name", "Done","Void", "Over", "Total"]}
                            tableData={
                              IceQUtils.convertingHashToArray(LocationsStore.currentLocation.user_leader_board, (item)=>{
                                return [`${item.firstname} ${item.lastname}`,
                                  String(item.closed_count),String(item.cancelled_count),
                                  String(item.overdue), String(item.ticket_count)
                                ]
                              })
                            }
                          />)}
                      />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                        <RegularCard plainCard={false} headerColor="orange" cardSubtitle="Open Vs Closed Tickets" content={(
                          <ZoomChart
                            chartData={Array.from(LocationsStore.currentLocation.ticket_open_vs_closed)}
                            xAxis={{datakey: 'unix_ts', type: 'number',
                              tickFormatter: (unixTime) => DateTime.fromMillis(unixTime*1000).toLocaleString(DateTime.DATE_SHORT)
                            }}
                            yAxis={[
                              {datakey: 'cumulative_open_tickets', type: 'number', color: '#8884d8', description: "Total opened Tickets"},
                              {datakey: 'cumulative_closed_tickets', type: 'number', color: '#82ca9d', description: "Total Closed Tickets"}
                            ]}/>
                        )}/>
                      </ItemGrid>
                    </Grid>
                    {pivot.data.table &&  pivot.data.table[0] ? (
                    <Grid container>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <RegularCard plainCard={false} headerColor="iceq" cardSubtitle="Inventory Status" content={(
                          <Grid container>
                            <ItemGrid xs={12} sm={12} md={6}>
                            <ResponsiveContainer minWidth={200} minHeight={350} width="100%" height="100%">
                              <BarChart width={250} height={80} data={pivot.data.table.map((ee) => {
                                if(ee.type === "data"){
                                  var blurp = {}
                                  ee.value.map((val, idx) => {
                                    if(pivot.data.table[0].value[idx]!=="Totals"){
                                      return blurp[pivot.data.table[0].value[idx]]=val
                                    } else {
                                      return null
                                    }
                                  })
                                  return blurp;
                                } else {
                                  return null
                                }
                              }).filter((val) =>val)}>
                              <CartesianGrid strokeDasharray="3 3"/>
                              <XAxis dataKey='sum unit'/>
                              <Tooltip labelFormatter={(val) => val}/>
                              {
                                pivot.data.table[0].value.filter((label) => label !=="sum unit" && label !=="Totals" ).map((label, idx) => {
                                  return (
                                    <Bar key={`bar${idx}`} dataKey={label} name={label} stackId="a" fill={colorList[idx]} onClick={this.handleClick}></Bar>
                                  )
                                })
                              }
                              </BarChart>
                            </ResponsiveContainer>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={6}>
                            {this.state.detailTimeSerieModel && this.state.detailTimeSerieModel.length>0 &&
                              this.state.detailTimeSerieModelHeaders && this.state.detailTimeSerieModelHeaders.length > 0 ?(
                              <ZoomChart
                                chartData={Array.from(this.state.detailTimeSerieModel)}
                                xAxis={{
                                  datakey: 'sum total', type: 'number',
                                  tickFormatter: (unixTime) => DateTime.fromMillis(unixTime*1000).toLocaleString(DateTime.DATE_SHORT)
                                }}
                                yAxis = {this.state.detailTimeSerieModelHeaders.map((data, idx) => (
                                  { datakey: data, type: 'number', color: colorList[idx], description: data }
                                ))}
                              />
                            ) : null }
                            </ItemGrid>
                       </Grid>
                       )}/>
                       </ItemGrid>
                     </Grid>


                    ) : null }

                    {(LocationsStore.currentLocation && LocationsStore.currentLocation.id >0 ?
                      (<div>
                        <Button color="primary" aria-label="Add"
                        component={Link}  to={`/warehouse/${LocationsStore.currentLocation.id}/freezers`}
                         className={classes.button}
                         arrayidx="-1"
                         floating={false}
                         location_id={this.props.match.params.id}
                       >SEARCH FOR FREEZERS IN THIS WAREHOUSE</Button>

                        <ButtonFab color="primary" aria-label="Add"
                         onClick={this.handleAddFreezer}
                         className={classes.button}
                         arrayidx="-1"
                         floating={true}
                         location_id={this.props.match.params.id}
                       >
                         <AddIcon />
                       </ButtonFab> </div>) :null)
                    }
                     
                    {(this.state.showAddFreezer) ? (
                      <FreezerForm
                        data={LocationsStore.getDefaultFreezer()}
                        store={LocationsStore}
                        loggedInUser={signedInUser}
                        loaded={(LocationsStore.currentLocation ? true :false)}
                        onClose={
                          (res) => {
                            this.setState({showAddFreezer: false})
                          }
                        }
                      />
                      ) : ( null )
                    }
                    </div>
                )
                  }
                }}
                </SignedInContext.Consumer>
        </div>
    );
  }
})

 export default  withStyles(styles)(withAuth(Warehouse));
