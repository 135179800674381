import React, { Component } from 'react';
import LocationsStore from 'store/LocationStore';
import { Redirect } from "react-router-dom";
import { withAuth } from "components";

import IceQFormSub from "./IceQFormSub";
import {SignedInContext} from 'Contexts/signedin-context';

class IceQSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {showIceQ: true, selectedIceq: {}}
  }

componentDidMount(){

}

  render() {
    const { showIceQ, selectedIceq } = this.state;
    if (selectedIceq && selectedIceq.freezer && selectedIceq.freezer.location) {
    return <Redirect to={`/location/${selectedIceq.freezer.location_id}`} push />
    }
    return (
      <div>
      <SignedInContext.Consumer>
        {({signedInUser, changeMe}) => {
            if(signedInUser) {
              return (
              <div>
                <IceQFormSub
                  data={LocationsStore.getDefaultFreezer()}
                  store={LocationsStore}
                  loaded={showIceQ}
                  lockToRegion={signedInUser.client_region_id}
                  allStatuses={true}
                  onSelected={(evnt) => {
                    if(evnt){
                      this.setState({"selectedIceq": evnt})
                    }
                  }}
                  />
              </div>
          )
            }
          }}
          </SignedInContext.Consumer>
      </div>);
  }


}

 export default (withAuth(IceQSearch));
