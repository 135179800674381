import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import {
  RegularCard,
  CustomInput,
  ItemGrid,
  ProgressButton,
  SwitchCard
} from "components";

import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});


const defaultSaveMsgs = {
  loadingSaveMsg: "",
  loadingSaveSuccessMsg: "",
  oadingSaveFailureMsg: ""
}

const defaultToNonNull = (val, type) => {
  if(val){
    return val
  } else {
    return (type && type ==="n") ? 0 : ""
  }
}

class LocationForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: this.props.loggedInUser,
      loadingSaveMsg: "",
      loadingSaveSuccessMsg: "",
      loadingSaveFailureMsg: "",
      propLocation: ""
    };
  }

  render() {
    const { classes, store, loaded } = this.props;
    if (!loaded) return null
    return (
      <div>
      <div className="center">
          <div className="card">


 
    <Formik
    initialValues={{
      id: store.currentLocation.id,
      name: defaultToNonNull(store.currentLocation.name),
      street: defaultToNonNull(store.currentLocation.street),
      city: defaultToNonNull(store.currentLocation.city),
      state: defaultToNonNull(store.currentLocation.state),
      country: (store.currentLocation.country ? store.currentLocation.country : "US"),
      zip: defaultToNonNull(store.currentLocation.zip),
      number: defaultToNonNull(store.currentLocation.number),
      contact_name: defaultToNonNull(store.currentLocation.contact_name),
      contact_phone: defaultToNonNull(store.currentLocation.contact_phone),
      contact_email: defaultToNonNull(store.currentLocation.contact_email),
      status_id: defaultToNonNull(store.currentLocation.status_id, 'n'),
      category_id: defaultToNonNull(store.currentLocation.category_id, 'n'),
      latitude: defaultToNonNull(store.currentLocation.latitude, 'n'),
      longitude: defaultToNonNull(store.currentLocation.longitude, 'n'),
      zone_num: defaultToNonNull(store.currentLocation.zone_num, 'n'),
      note: defaultToNonNull(store.currentLocation.note),
      client_region_id: (store.currentLocation.client_region_id <=0 ?
        this.state.loggedInUser.client_region_id : store.currentLocation.client_region_id)
    }}
    enableReinitialize={true}
    onSubmit={(values, {setSubmitting, resetForm, setErrors}) => {
      this.setState( { loadingSaveMsg: "Saving ...",
                loadingSaveSuccessMsg: "",
                loadingSaveFailureMsg: ""
              })
      this.props.store.updateLocation(values,
        (completed) => {
          this.setState( { loadingSaveMsg: "",
                    loadingSaveSuccessMsg: "Saved",
                    loadingSaveFailureMsg: ""
                  })
          setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
        },
        (error) => {
          setErrors(error)
          this.setState(
            {
              loadingSaveMsg: "",
              loadingSaveSuccessMsg: "",
              loadingSaveFailureMsg: "Error Saving"
            }
          )
          setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
        });
      setSubmitting(false)
    }}

    validationSchema= {Yup.object().shape({
      name: Yup.string().min(2).max(75).required(),
      street: Yup.string().min(4).max(75).required(),
      city: Yup.string().min(2).max(35).required(),
      state: Yup.string().min(2).max(2).required(),
      zip: Yup.string().min(5).max(10).required(),
      country: Yup.string().min(2).max(2).required(),
      number: Yup.string().min(0).max(15).nullable(),
      contact_name: Yup.string().min(5).max(50),
      contact_phone: Yup.string().min(7).max(25).nullable(),
      contact_email: Yup.string().max(1900).nullable(),
      status_id: Yup.number(),
      category_id: Yup.number(),
      latitude: Yup.number().nullable(),
      longitude: Yup.number().nullable(),
      note: Yup.string().max(2000).nullable(),
      zone_num: Yup.number(),
      client_region_id: Yup.number()
    })}

    render={({values, errors, touched, handleChange, isSubmitting, handleSubmit,  setValues}) => (
        <form onSubmit={handleSubmit}>
        <Grid container>
          <ItemGrid xs={12}>
         
            <Grid container>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Name"
                id="name"
                errorText={errors.name}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "name",
                  value: values.name,
                  onChange: handleChange
                }}
              />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Contact Name"
                id="contact_name"
                errorText={errors.contact_name}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "contact_name",
                  value: values.contact_name,
                  onChange: handleChange
                }}
              />
            </ItemGrid>
            </Grid>
            <Grid container>
              <ItemGrid xs={6} sm={6} md={3}>
              <CustomInput
                labelText="Contact Phone"
                id="contact_phone"
                errorText={errors.contact_phone}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "contact_phone",
                  value: values.contact_phone,
                  onChange: handleChange
                }}
              />
              </ItemGrid>
              <ItemGrid xs={6} sm={6} md={3}>
              <CustomInput
                labelText="Acct Number"
                id="number"
                errorText={errors.number}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "number",
                  value: values.number,
                  onChange: handleChange
                }}
              />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Contact Email"
                id="contact_email"
                errorText={errors.contact_email}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "contact_email", value: values.contact_email, onChange: handleChange
                }}
              />
            </ItemGrid>
            </Grid>
            <Grid container>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Street"
                id="street"
                errorText={errors.street}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "street",
                  value: values.street,
                  onChange: handleChange
                }}
              />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="City"
                id="city"
                errorText={errors.city}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "city",
                  value: values.city,
                  onChange: handleChange
                }}
              />

            </ItemGrid>
            </Grid>
            <Grid container>
              <ItemGrid xs={6} sm={6} md={6}>
              <CustomInput
                labelText="State"
                id="state"
                errorText={errors.state}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "state",
                  value: values.state,
                  onChange: handleChange
                }}
              />
              </ItemGrid>
              <ItemGrid xs={6} sm={6} md={6}>
              <CustomInput
                labelText="Zip"
                id="zip"
                errorText={errors.zip}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "zip", value: values.zip, onChange: handleChange
                }}
              />
            </ItemGrid>
            <ItemGrid xs={6} sm={6} md={6}>
            <TextField
            id="country"
            variant="outlined"
            select
            label={errors.country ? errors.country : "Country"}
            name="country"
            error={errors.country ? true : false}
            className={classes.textField}
            value={values.country}
            onChange={handleChange}
            SelectProps={{
              native: true,
              MenuProps: { className: classes.menu, },
            }}
            margin="normal"
            >
            <option key="country_list_US" value="US">United States</option>
            <option key="country_list_CA" value="CA">Canada</option>
            <option key="country_list_MX" value="MX">Mexico</option>
            <option key="country_list_AU" value="AU">Australia</option>
            </TextField>
            </ItemGrid>
            <ItemGrid xs={6} sm={6} md={6}>
              <CustomInput
                labelText="Zone"
                id="zone_num"
                errorText={errors.zone_num}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "zone_num",
                  value: values.zone_num,
                  onChange: handleChange
                }}
              />
            </ItemGrid>  
          
            </Grid>
            <Grid container>
            <ItemGrid xs={6} sm={6} md={6}>
            <CustomInput
              labelText="Latitude"
              id="latitude"
              errorText={errors.latitude}
              formControlProps={{fullWidth: true}}
              inputProps={{
                name: "latitude",
                value: values.latitude,
                onChange: handleChange
              }}
            />
          </ItemGrid>
          <ItemGrid xs={6} sm={6} md={6}>
              <CustomInput
                labelText="Longitude"
                id="longitude"
                errorText={errors.longitude}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "longitude", value: values.longitude, onChange: handleChange
                }}
              />
            </ItemGrid>
          </Grid>
          <Grid container>
            <ItemGrid xs={6} sm={6} md={6}>
              <TextField
              id="status_id"
              variant="outlined"
              select
              label={errors.status_id ? errors.status_id : "Status"}
              name="status_id"
              error={errors.status_id ? true : false}
              className={classes.textField}
              value={values.status_id}
              onChange={handleChange}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              >
              {this.props.store.locationStatus.map((optionValue ) => (
                <option key={"status_list_" +optionValue.val} value={optionValue.val}>{optionValue.desc}</option>
              ))}
              </TextField>
            </ItemGrid>
            <ItemGrid xs={6} sm={6} md={6}>
              {this.props.loggedInUser.permissions.account.can_manage ? (
                <TextField
                id="category_id"
                variant="outlined"
                select
                label={errors.category_id ? errors.category_id : "Category"}
                name="category_id"
                error={errors.category_id ? true : false}
                className={classes.textField}
                value={values.category_id}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                  MenuProps: { className: classes.menu, },
                }}
                margin="normal"
                >
                {this.props.store.category_list.map((optionValue ) => (
                  <option key={"status_list_" +optionValue.val} value={optionValue.val}>{optionValue.desc}</option>
                ))}
                </TextField>
              ):null}
                </ItemGrid>
            </Grid>
            <Grid container>
          <ItemGrid xs={12} sm={12} md={6}>
<CustomInput
  labelText="Note"
  id="note"
  errorText={errors.note}
  formControlProps={{fullWidth: true}}
  inputProps={{
    name: "note",
    value: values.note,
    onChange: handleChange
  }}
/>
</ItemGrid>
      <ItemGrid xs={12} sm={12} md={6}>
            <TextField
              id="client_region_id"
              variant="outlined"
              select
              label="Region"
              name="client_region_id"
              className={classes.textField}
              value={values.client_region_id}
              onChange={handleChange}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {
                this.state.loggedInUser.available_client_regions.map((optionValue ) => (
                  <option key={optionValue.id} value={optionValue.id}>{optionValue.name}</option>
                ))
              }
            </TextField>
            </ItemGrid>
            </Grid>

            <Grid container>
            <ItemGrid xs={12} sm={12} md={12}>
            <ProgressButton  color="primary"
              type="submit"
            className="form-submit"
            loadingMsg={this.state.loadingSaveMsg}
            loadingSuccessMsg={this.state.loadingSaveSuccessMsg}
            loadingFailureMsg={this.state.loadingSaveFailureMsg}
            disabled={isSubmitting}
            >
              SAVE
            </ProgressButton>
            </ItemGrid>
            </Grid>

            </ItemGrid>
          </Grid>
        </form>
    )}/>
    </div>
    </div>
      </div>);
  }


}

 export default withStyles(styles)(LocationForm);
