import {
  autorun,
  observable,
  decorate
} from "mobx";
import AuthService from 'components/AuthService';
class ClientRegionStore {
  regions = [];
  isLoading = true;
  selectedRegion = null;
  isSelected = false;
  Auth = new AuthService();
  constructor(){
    this.selectedRegion = {
      name: "",
      timezone: "",
      id: -1
    }
  }

  setSelectedRegionToDefault() {
    this.setSelectedRegionToSelection({
      name: "",
      timezone: "",
      id: -1
    })
  }

  setSelectedRegionToSelection(selection) {
    if(selection){
      this.selectedRegion = selection
      this.isSelected= true
    }
  }

  isRegionSelected() {
    return this.isSelected;
  }

  loadRegions() {
    this.isLoading = true
    this.Auth.fetch(`${this.Auth.domain}/client`, {
        method: 'GET'
      })
      .then(response => {
        if (response.length <= 0) {
          //TODO: raise exception as not found.
        }
        this.regions = response.client_regions;
        this.isLoading = false;
      })
    return true;
  }

  updateRegion(region, onComplete, onFailure) {
    var httpmethod = "POST"
    var url = `${this.Auth.domain}/client/client_regions`
    if (region.id && region.id > 0) {
      httpmethod = "PUT"
      url = `${this.Auth.domain}/client/client_regions/${region.id}`
    }
    this.Auth.fetch(url, {
        method: httpmethod,
        body: JSON.stringify(region)
      })
      .then(response => {
        this.loadRegions()
        if(onComplete){
          onComplete(response);
        }
      }).catch(err => {
        if(onFailure){
          onFailure(err);
        }
      })

    return true;
  }

}
decorate(ClientRegionStore, {
  isLoading: observable, 
  regions: observable,
  isSelected: observable

})
var clientRegionStore = window.clientRegionStore = new ClientRegionStore()
export default clientRegionStore
autorun(() => {});
