import React, { Component } from 'react';
import {  withStyles } from "@material-ui/core";
import { Button, ItemGrid } from "components";

import PropTypes from "prop-types";

import {List,  ListItem, ListItemText, Grid } from '@material-ui/core';
import {Dialog, DialogTitle } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

const orderedSelection = (regionList) => {
  return regionList.sort((a,b) => (a.client.name + " " + a.name).localeCompare((b.client.name + " " + b.name)))
}

const hasMultiClient = (regionList) => {
    let x = null
    let prevVal = -1
    for (x in regionList) {
      if(prevVal === -1){
        prevVal = x.client_id
      } else if(prevVal !== x.client_id){
        return false;
      }
    }
    return true
}

const findRegionFromID = (regionList, regionId) => {
  return regionList.filter((a) => (a.id === regionId))
}

class UserRegionSelection extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (value) => {
    if(value){
      this.props.onClose({client_region_id: value.id})
    }
    this.setState({ open: false });
  };

  render() {
    const { currentList, selectedValue } = this.props;
    let isMulti = hasMultiClient(currentList)
    let selRegion = findRegionFromID(currentList, selectedValue.id)
    return (
      <div>
      {(currentList && currentList.length > 1) ? (
        <div>
        <Button color="white" onClick={this.handleClickOpen} style={{width: '100%'}}>
          { (isMulti && selRegion)? (selRegion[0].client.name) : null } {selectedValue.name}
        </Button>
        <SimpleDialogWrapped
          currentList={currentList}
          selectedValue={selectedValue}
          isMulti={isMulti}
          open={this.state.open}
          onClose={this.handleClose}
        />
        </div>
      ) : (
        <Button disabled={true}>{selectedValue.name}</Button>
      )}

      </div>
    );
  }


}

UserRegionSelection.propTypes = {
  currentList: PropTypes.array,
  onClose: PropTypes.func,
  selectedValue: PropTypes.object
};

 export default UserRegionSelection;
 class SimpleDialog extends React.Component {
   handleClose = () => {
     this.props.onClose(this.props.selectedValue);
   };

   handleListItemClick = value => {
     this.props.onClose(value);
   };

   render() {
     const { classes, onClose, currentList, selectedValue, isMulti, ...other } = this.props;
     if(currentList){
     return (
       <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
         <DialogTitle id="simple-dialog-title">Select Region</DialogTitle>
         <div>
           <List>
             {orderedSelection(currentList).map(region => (
               <ListItem button onClick={() => this.handleListItemClick(region)} key={region.id}>
                  { isMulti ? (
                    <Grid container>
                    <ItemGrid xs={6} sm={6} md={6}>
                      <ListItemText primary={region.client.name} />
                      </ItemGrid>
                    <ItemGrid xs={6} sm={6} md={6}>
                      <ListItemText primary={region.name} />
                      </ItemGrid>
                      </Grid>
                  ) : (
                    <Grid container>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <ListItemText primary={region.name} />
                    </ItemGrid>
                    </Grid>
                    )}
               </ListItem>
             ))}
           </List>
         </div>
       </Dialog>
     );
   } else {
     return null;
   }
   }
 }

 SimpleDialog.propTypes = {
   classes: PropTypes.object.isRequired,
   isMulti: PropTypes.bool.isRequired,
   currentList: PropTypes.array,
   onClose: PropTypes.func,
   selectedValue: PropTypes.object

 };

 const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);
