import React, { Component } from 'react';
import UserStore from 'store/UserStore';
//import  { observer } from "mobx-react";
import UserForm from './UserForm'
import { withAuth } from "components";

import {SignedInContext} from 'Contexts/signedin-context';

class User extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    }
  }

  handleChangeUser = (userInfo, onSuccess, onError) => {
    UserStore.updateUser(userInfo,
    (savedUser) => {
      this.setState({ user: savedUser})
      onSuccess(savedUser)
    },
    (error) => {
      error.response.json().then((value) => { onError(value); })
      });
  };

  handleResetPassword = (userInfo, onSuccess, onError) => {
    UserStore.resetPassword(userInfo,
    (savedUser) => {
      this.setState({ user: savedUser})
      onSuccess(savedUser)
    },
    (error) => {
      error.response.json().then((value) => { onError(value); })
      });
  };

  componentDidMount() {
   var id =  "";
   if("/users/add" === this.props.location.pathname) {
     this.setState({user: UserStore.getDefaultUser(), loaded: true});
   } else {
     if("/me" === this.props.location.pathname) {
       id = "me";//this.Auth.getProfile()['user_id'];
     } else if(this.props.match.params.id){
       id =  this.props.match.params.id;
       UserStore.loadSingleUser(id, (response) => {
         this.setState({user: response, loaded: true});
       })
     }
   }
 }

  render() {
    return (
      <div>
        <SignedInContext.Consumer>
          {({signedInUser, changeMe}) => {
            if(signedInUser) {
              return (("/me" === this.props.location.pathname) ? (
              <div>
                <UserForm selectedUser={signedInUser}
                  loggedInUser={signedInUser}
                  loaded={true}
                  onSave={changeMe}
                  onLogout={() => {
                    UserStore.logout();
                    }
                  }
                  onResetPassword={this.handleResetPassword}
                />
              </div>
            ) : (
              <UserForm selectedUser={this.state.user}
                loggedInUser={signedInUser}
                loaded={this.state.loaded}
                onSave={this.handleChangeUser}
                onResetPassword={this.handleResetPassword}
              />
            ))
          }
          }}
        </SignedInContext.Consumer>
      </div>
    );
  }
}

 export default withAuth(User);
