/*global google*/
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Grid } from "@material-ui/core";

import { ItemGrid, IceQIcon } from "components";
import MapControl from "./MapControl";

import {
  BatteryFull,
  Whatshot,
  PhonelinkErase as NoContact,
  LocationOff as LocationOnly,
  AccessTime as NoRecentComm,
  Home
 } from "@material-ui/icons";

 const styles = {
   card: {
     minWidth: 20,
   },
   bullet: {
     display: 'inline-block',
     margin: '0 2px',
     transform: 'scale(0.8)',
   },
   title: {
     marginBottom: 16,
     fontSize: 14,
   },
   icons: {
     height:"14px",
     width:"14px"
   },
   pos: {
     marginBottom: 12,
   },
 };


const legendList = () => {
  return [
    {description: "%Full", icon: BatteryFull},
    {description: "No IceQ", icon: IceQIcon},
    {description: "Temp Alert", icon: Whatshot},
    {description: "Site Only", icon: LocationOnly},
    {description: "No Comm.", icon: NoContact},
    {description: "Old Comm.", icon: NoRecentComm},
    {description: "Warehouse", icon: Home}

    ]
}


class Legend extends React.Component {

  state = {
    isOpen: false,
//    activeMarker: this.props.activeMarker
  }

  render(){
const { classes } = this.props;
let LegendListMap = []
legendList().map((track, i) => {
  LegendListMap.push(
    <Grid container key={`leg${i}`}>
      <ItemGrid xs={2} sm={2} md={2}><track.icon className={classes.icons}/></ItemGrid>
      <ItemGrid xs={10} sm={10} md={10}>{track.description}</ItemGrid>
    </Grid>
    )
    return null;
})
    return(
      <div>
      <MapControl position={google.maps.ControlPosition.RIGHT_CENTER}>
      <Card className={classes.card}>
        {LegendListMap}
        </Card>
      </MapControl>
      </div>
    )

  }
}

Legend.propTypes = {
  classes: PropTypes.object
};


export default  withStyles(styles)(Legend);
