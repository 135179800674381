import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import {
RegularCard,
CustomInput,
ItemGrid,
ProgressButton
} from "components";

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const defaultSaveMsgs = {
  loadingSaveMsg: "",
  loadingSaveSuccessMsg: "",
  oadingSaveFailureMsg: ""
}

class AccountForm extends Component {

  constructor(props) {
    super(props);
    this.state = {open: false}
    //this.loadRegion = this.loadRegion().bind(this)
  }


handleClickOpen = () => {
  this.setState({ open: true });
};


  render() {
    //const { classes } = this.props;
    if (!this.props.loaded) return null

    return (

    <div>
    <Formik
    initialValues={{
      name: this.props.data.name,
      street1: this.props.data.street1,
      street2: (this.props.data.street2 ? this.props.data.street2 : ""),
      city: this.props.data.city,
      state: this.props.data.state,
      zip: this.props.data.zip,
      firstname: this.props.data.firstname,
      lastname: this.props.data.lastname,
      email: this.props.data.email,
      phone:   (this.props.data.phone ? this.props.data.phone : ""),
      note: (this.props.data.note ? this.props.data.note : "")
    }}
    onSubmit={(values, {setSubmitting, resetForm, setErrors}) => {
      var client = values;
      this.setState( { loadingSaveMsg: "Saving ...",
                loadingSaveSuccessMsg: "",
                loadingSaveFailureMsg: ""
              })
      this.props.store.updateAccount(client,
        (account) => {
          this.setState(
            { loadingSaveMsg: "",
              loadingSaveSuccessMsg: "Saved",
              loadingSaveFailureMsg: ""
            })
          setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
        },
        (error) => {
          error.response.json().then((value) => {
            setErrors(value);
          })
          this.setState(
            {
              loadingSaveMsg: "",
              loadingSaveSuccessMsg: "",
              loadingSaveFailureMsg: "Error Saving"
            }
          )
          setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
        })

      setSubmitting(false)
    }}

    validationSchema= {Yup.object().shape({
      name: Yup.string().min(2).max(75).required(),
      street1: Yup.string().min(1).max(75).required(),
      street2: Yup.string().min(4).max(75).nullable(),
      city: Yup.string().min(2).max(35).required(),
      state: Yup.string().min(2).max(2).required(),
      zip: Yup.string().min(5).max(10).required(),
      firstname: Yup.string().min(2).max(50).required(),
      lastname: Yup.string().min(2).max(50).required(),
      email: Yup.string().email().max(300).nullable(),
      phone: Yup.string().min(7).max(16).nullable(),
      note: Yup.string().max(2000).nullable(),
    })}

    render={({values, errors, touched, handleChange, isSubmitting, handleSubmit,  setValues}) => (
    <div>
          <RegularCard headerColor="iceq" content={
        <form  onSubmit={handleSubmit}>
            <div>
            <Grid container>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Account name"
                id="name"
                errorText={errors.name}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "name", value: values.name, onChange: handleChange
                }}
              />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Street1"
                id="street1"
                errorText={errors.street1}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "street1", value: values.street1, onChange: handleChange
                }}
              />
            </ItemGrid>
            </Grid>
            <Grid container>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Street2"
                id="street2"
                errorText={errors.street2}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "street2", value: values.street2, onChange: handleChange
                }}
              />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="City"
                id="city"
                errorText={errors.city}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "city", value: values.city, onChange: handleChange
                }}
              />
            </ItemGrid>
            </Grid>
            <Grid container>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="State"
                id="state"
                errorText={errors.state}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "state", value: values.state, onChange: handleChange
                }}
              />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Zip"
                id="zip"
                errorText={errors.zip}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "zip", value: values.zip, onChange: handleChange
                }}
              />

            </ItemGrid>
            </Grid>
            <Grid container>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Account Holder First Name"
                id="firstname"
                errorText={errors.firstname}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "firstname", value: values.firstname, onChange: handleChange
                }}
              />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Account Holder Last Name"
                id="lastname"
                errorText={errors.lastname}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "lastname", value: values.lastname, onChange: handleChange
                }}
              />
            </ItemGrid>
            </Grid>
            <Grid container>
            <ItemGrid xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Email"
              id="email"
              errorText={errors.email}
              formControlProps={{fullWidth: true}}
              inputProps={{
                name: "email", value: values.email, onChange: handleChange
              }}
            />
          </ItemGrid>
              <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Phone"
                id="phone"
                errorText={errors.phone}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "phone", value: values.phone, onChange: handleChange
                }}
              />
              </ItemGrid>
            </Grid>
            <Grid container>
              <ItemGrid xs={12} sm={12} md={12}>
                <CustomInput
                labelText="Note"
                id="note"
                errorText={errors.note}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "note", value: values.note, onChange: handleChange
                }}
              />
            </ItemGrid>

          </Grid>

            <Grid container>
            <ItemGrid xs={12} sm={12} md={12}>
            <ProgressButton className="form-submit"
              type="submit" color="primary"
              loadingMsg={this.state.loadingSaveMsg}
              loadingSuccessMsg={this.state.loadingSaveSuccessMsg}
              loadingFailureMsg={this.state.loadingSaveFailureMsg}
              disabled={isSubmitting}>SAVE</ProgressButton>
            </ItemGrid>
            </Grid>
            </div>

        </form>
        }/>

    </div>
    )}/>

      </div>);
  }


}

 export default withStyles(styles)(AccountForm);
