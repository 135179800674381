import React, { Component } from 'react';
import AssetLookup from 'views/locations/AssetLookup';
import { Redirect } from "react-router-dom";
import { withAuth } from "components";

import {SignedInContext} from 'Contexts/signedin-context';

class FreezerSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showIceQ: true,
      selectedIceq: {},
      delay: 100,
      result: 'No result',
      assetTagValue: "",
      assetTagError: "",
      manualEntry: false
    }
  }

  componentDidMount(){}

  handleError(err){
    console.error(err)
  }
  handleManualTextChange = (event) => {

     this.setState({manualEntry: event.target.value ? true : false, assetTagValue : event.target.value, showQRReader:false})
  }
  render() {
    const { classes } = this.props;
    if (this.state.freezer) {
      if(this.state.freezer.location.category_id ===0){
        return <Redirect to={`/location/${this.state.freezer.location_id}?preferred_freezer=${this.state.freezer.id}`} push />
      }else {
        return <Redirect to={`/warehouse/${this.state.freezer.location_id}/freezers?search=freezer_id=${this.state.freezer.id}`} push />
      }
    }
    return (
      <div>
      <SignedInContext.Consumer>
        {({signedInUser, changeMe}) => {
          if(signedInUser) {
            return (
              <div>
              <AssetLookup classes={classes} onSuccess={(freezer) => {
                this.setState({freezer: freezer})}
              }/>
              </div>
            )
          }
        }}
      </SignedInContext.Consumer>
      </div>);
  }


}

 export default (withAuth(FreezerSearch));
