import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Freezer from "./Freezer";

import { ItemGrid } from "components";

import PropTypes from "prop-types";
const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});


class Freezers extends Component {
  render() {
    const { classes, store } = this.props;
    if (!this.props.loaded) return null
    var freezers = [];
    if(store.currentLocation.freezers){
      store.currentLocation.freezers.map((freezer, i) => {
        freezers.push(
          <ItemGrid xs={12} sm={12} md={12}  key={`freezerCard${i}`}>
          <Freezer
            freezer={freezer}
            store={store}
            loaded={this.props.loaded}
            loggedInUser={this.props.loggedInUser}
          />
        </ItemGrid>
        );
        return "";
    });
  }
    return (
    <div className={classes.root}>
      <Grid container>
        {freezers}
      </Grid>

      </div>
    );
  }
}
Freezers.propTypes = {
  data: PropTypes.object,
  store: PropTypes.object,
  loggedInUser: PropTypes.object,
  loaded: PropTypes.bool,
  preferredFreezer: PropTypes.string

};
 export default withStyles(styles, { withTheme: true })(Freezers);
 // export default withStyles(styles)(Freezers);
