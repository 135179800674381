import {
  autorun,
  observable,
  decorate
} from "mobx";
import AuthService from 'components/AuthService';
class FreezerStore {
  freezers = [];
  currentFreezer = null;
  hasNextPage = true;
  currentPage = 1;
  previousSize = -1;
  currentLocationId = -1;
  searchCrit = "";

  Auth = new AuthService();

  resetStore(){
    this.freezers = []
    this.currentFreezer = null
    this.hasNextPage = true
    this.currentPage = 1
    this.previousSize = -1
    this.currentLocationId = -1
    this.searchCrit = ""

  }

 defaultToNonNull = (val, type) => {
    if(val){
      return val
    } else {
      return (type && type ==="n") ? 0 : ""
    }
  }


  startSearch(currentLocation, search){
  //  this.locations.clear()
    this.searchCrit = search
    this.previousSize = -1
    this.hasNextPage = true
    this.currentPage = 1
    this.currentLocationId = currentLocation
    this.loadFreezers(this.currentPage)
  }

// data is either { asset_tag: val } or { freezer_id: val}
  quickSearch(data, onFound, onNotFound, onError) {
    let Auth = new AuthService();
    var url = ""
    if(data.hasOwnProperty("freezer_id")){
       url = `${Auth.domain}/asset_tag?search_by_freezer_id=${String(data['freezer_id'])}`
    } else {
       url = `${Auth.domain}/asset_tag?search=${String(data['asset_tag'])}`
    }

    Auth.fetch(url, { method: 'GET' }).then(response => {
      if(response ){
        if(onFound){
          onFound(response, data)
        }
      } else {
        if(onNotFound) {
          onNotFound(data)
        }
      }
    }).catch(error => {
        if(onError){
          error.response.json().then((value) => { onError(value); })
        }
      })
  }

  storeApiCall(id){
    this.Auth.fetch(`${this.Auth.domain}/freezers/${id}/check_in_confirm/?command=CHECKIN`, {
      method: 'GET'
    })
  }
  storeApiRestartCall(id){
    this.Auth.fetch(`${this.Auth.domain}/freezers/${id}/check_in_confirm/?command=RESTART`, {
      method: 'GET'
    })
  }

    storeApiConfigSend(id){
        this.Auth.fetch(`${this.Auth.domain}/freezers/${id}/check_in_confirm/?command=CONFIG`, {
            method: 'GET'
        })
    }
  storeApiConfigInfo(configData, id){

    return this.Auth.fetch(`${this.Auth.domain}/freezers/${id}/config_save`, {
      method: 'POST',
      header:{'Content-Type':'application/json'},
      body: JSON.stringify ({key:configData})
    })
        .then(data => {
            if (data.message === "Saved to database") {
                return "success"
            } else {
                return "failure"
            }
        })
        .catch(error => {
            return "error"
        });

  }

  getFreezer(freezerId, onComplete, onError) {
    if (freezerId < 0) {
      //load default
    } else {
      this.Auth.fetch(`${this.Auth.domain}/locations/${this.currentLocationId}/freezers/${freezerId}`, {
          method: 'GET'
        })
        .then(response => {
          if (response.length <= 0) {
            //TODO: raise exception as not found.
          }

          this.currentFreezer = response;
          if(onComplete){
           onComplete(response)
          }
      }).catch(error => {
        error.response.json().then((value) => { onError(value); })
        })
    }
    return true;
  }

  loadIceQReadings(freezerId, dateFrom, dateTo, onComplete, onError){
    if(freezerId > 0 ){
      this.Auth.fetch(`${this.Auth.domain}/locations/${this.currentLocationId}/freezers/${freezerId}/iceq_readings?dfrom=${dateFrom}&dto=${dateTo}`, {
        method: 'GET'
      })
      .then(response => {
        if (response.length <= 0) {
          //TODO: raise exception as not found.
        }

        this.currentFreezer = response;
        if(onComplete){
         onComplete(response)
        }
    }).catch(error => {
      error.response.json().then((value) => { onError(value); })
      })
    } else {
      if(onComplete){
        onComplete([])
      }
    }

  }

  loadFreezers(page, onComplete, onFailure)  {
    if (page) {
      this.currentPage = page
    } else {
      this.currentPage = 1
    }
    var url = `${this.Auth.domain}/locations/${this.currentLocationId}/freezers?page=${this.currentPage}`
    if(this.searchCrit) {
      url = `${this.Auth.domain}/locations/${this.currentLocationId}/freezers?page=${this.currentPage}&search=${this.searchCrit}`
    }
    this.Auth.fetch(url, {
      method: 'GET'
    }).then(response => {
      if (this.previousSize === -1 || this.previousSize < response.length) {
        this.previousSize = response.length;
        this.hasNextPage = true;
      } else if (this.previousSize === response.length) {
        this.hasNextPage = false;
      }
      this.freezers = response;
    })
    return true;
  }
}
decorate(FreezerStore, {
  freezers: observable,
  currentFreezer: observable,
  hasNextPage: observable


})
var freezerStore = window.freezerStore = new FreezerStore()
export default freezerStore
autorun(() => {});
