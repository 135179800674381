import {
  autorun,
  observable, 
  decorate
} from "mobx";
import AuthService from 'components/AuthService';
class AccountStore {
  regions = [];
  currentPage = 0;
  isLoading = true;
  previousSize = -1;
  currentAccount = null;
  Auth = new AuthService();


  loadAccount() {
    this.isLoading = true
    this.Auth.fetch(`${this.Auth.domain}/client`, {
        method: 'GET'
      })
      .then(response => {
        if (response.length <= 0) {
          //TODO: raise exception as not found.
        }
        this.currentAccount = response;
        this.isLoading = false;
      })
    return true;
  }

  updateAccount(account, onComplete, onError) {
    //this.isLoading = true
    var httpmethod = "PUT"
    var url = `${this.Auth.domain}/client`
    this.Auth.fetch(url, {
        method: httpmethod,
        body: JSON.stringify(account)
      })
      .then(response => {
        this.currentAccount = response;
        if(onComplete){
          onComplete(response)
        }
      //  this.isLoading = false;

    }).catch(error => {
        if(onError){
          error.response.json().then((value) => { onError(value); })
        }
      })
    return true;
  }

}
decorate(AccountStore, {
  isLoading: observable
})
var accountStore = window.accountStore = new AccountStore()
export default accountStore

autorun(() => {});
