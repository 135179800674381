import React, { Component } from 'react';
import { DateTime } from 'luxon';
import {SignedInContext} from 'Contexts/signedin-context';
import PropTypes from "prop-types"
class TZConvert extends Component {
  render() {
    const {utcSqlDate } = this.props
    if(!utcSqlDate) { return "" }
    return (
      <SignedInContext.Consumer>
        {({signedInUser, changeMe}) => {
          let destTZdate = null
          let loc = DateTime.fromISO(utcSqlDate, {zone: "UTC"})
          // if(signedInUser && signedInUser.client_region.timezone) {
          //   destTZdate = loc.setZone(signedInUser.client_region.timezone)
          // } else {
          //   destTZdate = loc.toLocal()
          // }
          destTZdate = loc.toLocal()
          let format = DateTime.DATETIME_SHORT
          if(this.props.format){
            if(this.props.format === "DATE_SHORT"){
              format = DateTime.DATE_SHORT
            } else if(this.props.format === "TIME_SIMPLE"){
              format = DateTime.TIME_SIMPLE
            }
          }
          return destTZdate.toLocaleString(format);
        }}
      </SignedInContext.Consumer>
    );
  }
}

TZConvert.propTypes = {
  utcSqlDate: PropTypes.string,
  format: PropTypes.oneOf(["DATETIME_SHORT", "DATE_SHORT", "TIME_SIMPLE"])
}
export default TZConvert;
