import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
//import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Formik } from 'formik';
import { Grid } from "@material-ui/core";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tooltip from 'rc-tooltip';
import { Route } from 'react-router-dom'
import LocationStore from "store/LocationStore";
import UserStore from "store/UserStore";
import TicketStore from "store/TicketStore";
import FreezerStore from "store/FreezerStore";
import {SignedInContext} from 'Contexts/signedin-context';
import { Search } from "@material-ui/icons";
import * as Yup from 'yup';
import {
  CustomInput,
  ItemGrid,
  AsyncCustomSelect,
  IconButton as SearchButton,
  Button,
  CustomSelect
} from "components";

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  cellFormat: {
    width: "80%",
    margin: "15px"
  }
});

const percent_marks = {
  0:   <strong>0%</strong>,
  100: <strong>100%</strong>
};

const distance_marks = {
  0:   <strong>0 in</strong>,
  100: <strong>100 in</strong>
};

const hours_marks = {
  0:   <strong>Now</strong>,
  100: <strong>Forever</strong>
};

const orderByList = [
  { label: 'By location name A-Z (ascending)', value: 'locname asc' },
  { label: 'By location name Z-A (descending)', value: 'locname desc' },
  { label: 'Empty to Full', value: 'percent_full asc' },
  { label: 'Full to Empty', value: 'percent_full desc' },
  { label: 'Coolest to Hottest', value: 'temperature asc' },
  { label: 'Hottest to Coolest', value: 'temperature desc'}
]
const defaultLocationValues = {
  percent_full:[0,100],
  temperature:[-100,100],
  right_percent_full:[0,100],
  left_percent_full:[0,100],
  sensors:[0,100],
  callin:[0,100],
  order_by:orderByList[2] ,
  search:""
}


const indoorOutdoorList = [
  { label: 'Indoor', value: 1 },
  { label: 'Outdoor', value: 0 }
]


const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};


class SwipeableTemporaryDrawer extends React.Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
    expanded: "panel1"
  };
  componentDidMount(){
    LocationStore.loadAllSupportData(
      (res) => { this.setState({
        productModelRatiosLoaded:true,
        conditionsLoaded:true,modelsLoaded:true,
        productsLoaded:true, placementsLoaded:true,
      }) }
    )
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
    return open;
  };

  userSearch = (inputValue, callback) => {
    UserStore.userRoaster(inputValue, (dd) => {
      callback(dd.map(opt => ({ label: `${opt['firstname']} ${opt['lastname']}`, value: opt['id'] })));
    }, null);
  }

  locationSearch = (inputValue, callback) => {
    var search="category_id=1"
    if(inputValue)
      search = `${search} and ${inputValue}`
      LocationStore.quickSearch(search,
        (dd) => {
          callback(dd.map(opt => (
          { label: `${opt['name']} ${opt['street']} ${opt['city']} ${opt['state']} ${opt['zip']}`, value: opt['id'] }
        )));
      }, null);
    }

  modelSearch = (inputValue, callback) => {
    LocationStore.loadModels((dd) => {
      var ddf = dd
      if(inputValue)
        ddf = dd.filter((item) => {
          var ar = inputValue.split(" ")
          for(var x =0 ; x < ar.length; x++){
            if(!(item.manufacturer + item.model + (item.indoor_outdoor ===1 ? "Indoor" : "Outdoor")).toLowerCase().includes(ar[x].toLowerCase())){
              return false
            }
          }
          return true
        })
      callback(ddf.map(opt => ({ label: `${opt['manufacturer']} ${opt['model']} ${opt['indoor_outdoor'] ===1 ? "Indoor" : "Outdoor"}`, value: opt['id'] })));
    })
  }

  conditionSearch = (inputValue, callback) => {
    LocationStore.loadConditions((dd) => {
      var ddf = dd
      if(inputValue)
        ddf = dd.filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()))
      callback(ddf.map(opt => ({ label: `${opt['name']}`, value: opt['id'] })));
    })
  }

  ticketStatusSearch = (inputValue, callback) => {
    TicketStore.loadTicketStatuses((dd) => {
      var ddf = dd
      if(inputValue)
        ddf = dd.filter(item => item.description.toLowerCase().includes(inputValue.toLowerCase()))
        callback(ddf.map(opt => ({ label: `${opt['description']}`, value: opt['id'] })));
      }
    )
  }

  ticketTypeSearch = (inputValue, callback) => {
    TicketStore.loadTicketTypes((dd) => {
      var ddf = dd
      if(inputValue)
        ddf = dd.filter(item => item.description.toLowerCase().includes(inputValue.toLowerCase()))
      callback(ddf.map(opt => ({ label: `${opt['description']}`, value: opt['id'] })));
    })
  }

  computeChangedValue = (searchVals, defaultValues) => {
    var chVal = {}
    var ent = Object.entries(searchVals)


    ent.forEach((e) => {
      var defVal = defaultValues[e[0]]
      if(defVal || defVal === ""){
        if (Array.isArray(defVal)){
          var isDifferent = false
          defVal.forEach((f, idx) => { if(f !== e[1][idx]) { isDifferent = true } })
          if(isDifferent) chVal[e[0]]=e[1]
        } else {
          if( defVal !== e[1]) chVal[e[0]]=e[1]
        }
      }
    })
    return chVal
  }


  

  buildString = (filt) => {
    var dd = ""
    if( Object.keys(filt).length === 0) {return dd;}
    Object.entries(filt).forEach((obj, idx) => {
      if(idx >0 ) { dd = dd + " and " }
      if (!obj[0].startsWith("multi") && Array.isArray(obj[1])){
        dd = dd + obj[0] + ">=" + obj[1][0] + " and " + obj[0] + "<=" + obj[1][1]
      } else if(obj[0].startsWith("multi")){
        var arr_size = Object.entries(filt[obj[0]]).length
        Object.entries(filt[obj[0]]).forEach((multi_obj, multi_idx) => {
          if(multi_idx === 0){
            dd = dd + "("
          }
          if(multi_idx > 0){
            dd = dd + " or "
          }
          dd = dd + obj[0].slice(6) + "=" + multi_obj[1].value
          if(multi_idx + 1 === arr_size){
            dd = dd + ")"
          }
        })
      } else {
        if(obj[0] === "search"){
          dd = dd + obj[1]
        } else {
          dd = dd + obj[0] + "=" + obj[1]
        }
      }
    })
    return dd;
  }

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;
    const iceQSearchScreen = (
      <Route render={({ history}) => (
      <Formik
      initialValues={defaultLocationValues}
      onSubmit={(values, actions) => {

    var tt = this.computeChangedValue(values, defaultLocationValues)
    delete tt['order_by']
    var order_by = ""
    if(values.order_by && values.order_by.value) {
     order_by= values.order_by.value
    }

    if(values.freezer_status) {
      tt['freezer_status'] = values.freezer_status.value
    }
    if(values.indoor_outdoor) {
      tt['indoor_outdoor'] = values.indoor_outdoor.value
    }
    if(values.multi_model_id) {
      tt['multi_model_id'] = values.multi_model_id
    }
    if(values.multi_condition_id) {
      tt['multi_condition_id'] = values.multi_condition_id
    }

    if(history.location.pathname === "/locations"){
      LocationStore.startSearch(this.buildString(tt), order_by)
    } else {
      history.push(`/locations?order_by=${order_by}&search=${this.buildString(tt)}`)
    }  
    this.setState({'top': false});
  }}

      render={({values, errors, touched, handleChange, setFieldValue, isSubmitting, handleSubmit,  setValues}) => (

      <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Location & IceQ Search</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
              <form onSubmit={handleSubmit} style={{width:"100%"}}>
                <Grid container>
                  <ItemGrid xs={12} sm={12} md={12}>
                  <div className={classes.cellFormat} style={{margin: "0 15px"}}>
                    <CustomInput
                      labelText="Generic Location Search" id="search"
                      errorText={errors.search} formControlProps={{margin: "none",fullWidth: true}}
                      inputProps={{
                        name: "search",
                        value: values.search,
                        onChange: handleChange
                      }}
                    />
                    </div>
                  </ItemGrid>
                </Grid>
                <Grid container>
                  <ItemGrid xs={12} sm={12} md={6}>
                  <div className={classes.cellFormat}>
                    <Typography variant="caption" className={classes.flex}>
                      % Full:
                      <Range allowCross={false} value={values.percent_full} tipFormatter={value => `${values.percent_full}%`} onChange={rcslide => setFieldValue( 'percent_full',  rcslide)} marks={percent_marks} />
                    </Typography>
                    </div>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                  <div className={classes.cellFormat}>
                    <Typography variant="caption" className={classes.flex}>
                      Temperature:
                      </Typography>
                      <Range allowCross={false} value={values.temperature} onChange={rcslide => setFieldValue( 'temperature',  rcslide)} marks={percent_marks} />
                    </div>
                  </ItemGrid>
                </Grid>
                <Grid container>
                  <ItemGrid xs={12} sm={12} md={6}>
                  <div className={classes.cellFormat}>
                  <Typography variant="caption" className={classes.flex}>
                  Left Side % Full:
                    <Range allowCross={false} value={values.left_percent_full} onChange={rcslide => setFieldValue( 'left_percent_full',  rcslide)} marks={percent_marks} />
                    </Typography>
                    </div>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                  <div className={classes.cellFormat}>
                    <Typography variant="caption" className={classes.flex}>
                    Right Side % Full:
                      <Range allowCross={false} value={values.right_percent_full} onChange={rcslide => setFieldValue( 'right_percent_full',  rcslide)} marks={percent_marks} />
                    </Typography>
                    </div>
                  </ItemGrid>
                </Grid>
                <Grid container>
                <ItemGrid xs={12} sm={12} md={6}>
                  <div className={classes.cellFormat}>
                    <Typography variant="caption" className={classes.flex}>
                      Sensors distance:
                      <Range allowCross={false} value={values.sensors} onChange={rcslide => setFieldValue( 'sensors',  rcslide)} marks={distance_marks} />
                    </Typography>
                  </div>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={6}>
                <div className={classes.cellFormat}>
                  <CustomSelect 
                    formControlProps={{fullWidth: true}}
                      labelText="Sort By:"
                      inputProps={{
                        menuPosition:'absolute',
                        menuPlacement:'auto',
                        menuPortalTarget: document.body,
                        styles:{ menuPortal: base => ({ ...base, zIndex: 9999 }) },
                        isClearable:true,
                        defaultValue:orderByList[2],
                        isSearchable:true,
                        name:"order_by",
                        options:orderByList,
                        id: "order_by", 
                        onChange: data => setFieldValue( 'order_by',  data)
                      }}
                  />
                </div>
                {false ? (
                  
                  <div className={classes.cellFormat}>
                    <Typography variant="caption" className={classes.flex}>
                      Last Communication Time:
                      <Range allowCross={false} disabled={false} value={values.callin} onChange={rcslide => setFieldValue( 'callin',  rcslide)} marks={hours_marks} />
                    </Typography>
                  </div>
                ) : null }
                </ItemGrid>
                </Grid>
                <Grid container>
                <ItemGrid xs={12} sm={12} md={6}>
                <div className={classes.cellFormat}>
                    <CustomSelect 
                    formControlProps={{fullWidth: true}}
                      labelText="Indoor/Outdoor:"
                      inputProps={{
                        menuPosition:'absolute',
                        menuPlacement:'auto',
                        menuPortalTarget: document.body,
                        styles:{ menuPortal: base => ({ ...base, zIndex: 9999 }) },
                        isClearable:true,
                        isSearchable:true,
                        name:"indoor_outdoor",
                        options:indoorOutdoorList,
                        id: "indoor_outdoor", 
                        onChange: data => setFieldValue( 'indoor_outdoor',  data)
                      }}
                      />
                </div>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={6}>
                  <div className={classes.cellFormat}>
                    <CustomSelect 
                      formControlProps={{fullWidth: true}}
                        labelText="Freezer Status:"
                        inputProps={{
                          menuPosition:'absolute',
                          menuPlacement:'auto',
                          menuPortalTarget: document.body,
                          styles:{ menuPortal: base => ({ ...base, zIndex: 9999 }) },
                          isClearable:true,
                          isSearchable:true,
                          name:"freezer_status",
                          options: LocationStore.freezerStatus.map(opt => ({ label: `${opt['desc']}`, value: opt['val'] })) ,
                          id: "freezer_status", 
                          onChange: data => setFieldValue( 'freezer_status',  data)
                      }}
                    />
                </div>
                  </ItemGrid>
                </Grid>
                <Grid container>
                  <ItemGrid xs={12} sm={6} md={6}>
                    <div className={classes.cellFormat}>
                    <AsyncCustomSelect
                      formControlProps={{fullWidth: true}}
                      labelText="Model"
                      inputProps={{
                        menuPosition: 'absolute',
                        menuPlacement: 'auto',
                        menuPortalTarget: document.body,
                        styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
                        defaultValue: values.multi_model_id,
                        cacheOptions: true,
                        isMulti: true,
                        defaultOptions: true,
                        isClearable: true, 
                        id: "multi_model_id",
                        name: "multi_model_id",
                        onInputChange: (newValue) => {
                          const inputValue = newValue.replace(/\W/g, ' ');
                          this.setState({ "inputValue_multi_model_id":inputValue });
                          return inputValue;
                        },
                        onChange: value => setFieldValue('multi_model_id', value),
                        value: values.multi_model_id,
                        loadOptions: this.modelSearch
                      }} />
                      </div>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={6}>
                      <div className={classes.cellFormat}>
                        <AsyncCustomSelect
                          formControlProps={{fullWidth: true}}
                          labelText="Conditions"
                          inputProps={{
                            menuPosition: 'absolute',
                            menuPlacement: 'auto',
                            menuPortalTarget: document.body,
                            styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
                            defaultValue: values.condition_id,
                            cacheOptions: true,
                            defaultOptions: true,
                            isMulti: true,
                            isClearable: true, 
                            onInputChange: (newValue) => {
                              const inputValue = newValue.replace(/\W/g, '');
                              this.setState({ "inputValue_multi_condition_id":inputValue });
                              return inputValue;
                            },
                          id: "multi_condition_id",
                          name: "multi_condition_id",
                          onChange: value => setFieldValue('multi_condition_id', value),
                          value: values.multi_condition_id,
                          loadOptions: this.conditionSearch
                        }} />
                      </div>
                      
                      </ItemGrid>

                </Grid>
                <Grid container>
                  <ItemGrid xs={12} sm={12} md={12}>
                    <Button size="small"  className={classes.button} type="submit" color="primary" variant="contained" onClick={handleSubmit}>Search</Button>
                  </ItemGrid>
                </Grid>
              </form>

              
          </ExpansionPanelDetails>
          <ExpansionPanelActions><div></div></ExpansionPanelActions>
        </ExpansionPanel>
            )} />)}/>
    )

    const assetSearchScreen = (
      <Route render={({ history}) => (
      <Formik
      initialValues = {{}}
      onSubmit={(values, actions) => {
        var searchVal = {}
        if(values.warehouse && values.warehouse.value){
          if(values.multi_condition_id){
            searchVal['multi_condition_id'] = values.multi_condition_id
          }
          if(values.multi_model_id){
            searchVal['multi_model_id'] = values.multi_model_id
          }
          if(values.indoor_outdoor) {
            searchVal['indoor_outdoor'] = values.indoor_outdoor.value
          }
          if(history.location.pathname === `/warehouse/${values.warehouse.value}/freezers`){
            FreezerStore.startSearch(values.warehouse.value, this.buildString(searchVal))
          } else {
            if(Object.keys(searchVal).length > 0) {
              history.push(`/warehouse/${values.warehouse.value}/freezers?search=${this.buildString(searchVal)}`)
            } else {
              history.push(`/warehouse/${values.warehouse.value}/freezers`)
            }
          }
          this.setState({'top': false});
        }
      }}
      validationSchema= {Yup.object().shape({ warehouse: Yup.object().required() })}

      render={({values, errors, touched, handleChange, setFieldValue, isSubmitting, handleSubmit,  setValues}) => (
  <ExpansionPanel expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Assets Search</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
      <form onSubmit={handleSubmit} style={{width:"100%"}}>
      <Grid container>
      <ItemGrid xs={12} sm={12} md={12}>
      <div className={classes.cellFormat}>
      <AsyncCustomSelect
        formControlProps={{fullWidth: true}}
        labelText={(errors != null && errors.warehouse != null)? errors.warehouse : "Warehouse"}
        error={ (errors != null && errors.warehouse != null) ? true : false}
        inputProps={{
          menuPosition: 'absolute',
          menuPlacement: 'auto',
          menuPortalTarget: document.body,
          styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
          defaultValue: values.warehouse,
          cacheOptions: true,
          defaultOptions: true,
          onInputChange: (newValue) => {
            const inputValue = newValue.replace(/\W/g, '');
            this.setState({ "inputValue":inputValue });
            return inputValue;
          },
        id: "warehouse",
        name: "warehouse",
        onChange: value => setFieldValue('warehouse', value),
        value: values.warehouse,
        loadOptions: this.locationSearch
        }} />
        </div>
      </ItemGrid>
      </Grid>

      { values.warehouse ? 
      <Grid container>
      <ItemGrid xs={12} sm={12} md={4}>
      <div className={classes.cellFormat}>
      <AsyncCustomSelect
        formControlProps={{fullWidth: true}}
        labelText="Model"
        inputProps={{
          menuPosition: 'absolute',
          menuPlacement: 'auto',
          menuPortalTarget: document.body,
          styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
          defaultValue: values.multi_model_id,
          cacheOptions: true,
          defaultOptions: true,
          isMulti: true,
          isClearable: true, 
          id: "multi_model_id",
          name: "multi_model_id",
          onInputChange: (newValue) => {
            const inputValue = newValue.replace(/\W/g, ' ');
            this.setState({ "inputValue_multi_model_id":inputValue });
            return inputValue;
          },
          onChange: value => setFieldValue('multi_model_id', value),
          value: values.multi_model_id,
          loadOptions: this.modelSearch
        }} />
        </div>
      </ItemGrid>
      <ItemGrid xs={12} sm={6} md={4}>
        <div className={classes.cellFormat}>
        <AsyncCustomSelect
          formControlProps={{fullWidth: true}}
          labelText="Conditions"
          inputProps={{
            menuPosition: 'absolute',
            menuPlacement: 'auto',
            menuPortalTarget: document.body,
            styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
            defaultValue: values.condition_id,
            cacheOptions: true,
            defaultOptions: true,
            isMulti: true,
            isClearable: true, 
            onInputChange: (newValue) => {
              const inputValue = newValue.replace(/\W/g, '');
              this.setState({ "inputValue_multi_condition_id":inputValue });
              return inputValue;
            },
          id: "multi_condition_id",
          name: "multi_condition_id",
          onChange: value => setFieldValue('multi_condition_id', value),
          value: values.multi_condition_id,
          loadOptions: this.conditionSearch
          }} />
          </div>
    
        </ItemGrid>
        <ItemGrid xs={12} sm={6} md={4}>        
          <div className={classes.cellFormat}>
            <CustomSelect 
              formControlProps={{fullWidth: true}}
              labelText="Indoor/Outdoor:"
              inputProps={{
                menuPosition:'absolute',
                menuPlacement:'auto',
                menuPortalTarget: document.body,
                styles:{ menuPortal: base => ({ ...base, zIndex: 9999 }) },
                isClearable:true,
                isSearchable:true,
                name:"indoor_outdoor",
                options:indoorOutdoorList,
                id: "indoor_outdoor", 
                onChange: data => setFieldValue( 'indoor_outdoor',  data)
              }}
            />
          </div>
        </ItemGrid>
      </Grid>
      : null}
      <Grid container>
                  <ItemGrid xs={12} sm={12} md={12}>
                    <Button size="small" type="submit" color="primary" onClick={handleSubmit}>Search</Button>
                  </ItemGrid>
                </Grid>
      </form>
      </ExpansionPanelDetails>
      <ExpansionPanelActions><div></div></ExpansionPanelActions>
    </ExpansionPanel>

                )} />)}/>
    )
    const ticketSearchScreen = (
      <Route render={({ history}) => (
        <Formik
        initialValues = {{}}
      onSubmit={(values, actions) => {
      var searchVal = {}
      if( values.user_id && values.user_id.value){ searchVal['user_id']= values.user_id.value}
      if(values.ticket_type_id && values.ticket_type_id.value){ searchVal['ticket_type_id']= values.ticket_type_id.value}
      if( values.created_by_id && values.created_by_id.value){ searchVal['created_by_id']= values.created_by_id.value}
      if( values.updated_by_id && values.updated_by_id.value){ searchVal['updated_by_id']= values.updated_by_id.value}
      if( values.status_id && values.status_id.value){ searchVal['ticket_status_id']= values.status_id.value}
      if( values.ticket_id){ searchVal['ticket_id']= values.ticket_id }
      if(history.location.pathname === "/tickets"){
      TicketStore.startSearch(this.buildString(searchVal))
    } else {
      if(searchVal.length > 0) {
        history.push(`/tickets?search=${this.buildString(searchVal)}`)
      } else {
        history.push('/tickets')
      }
    }
    this.setState({'top': false});
  }}

    render={({values, errors, touched, handleChange, setFieldValue, isSubmitting, handleSubmit,  setValues}) => (
      <ExpansionPanel expanded={expanded === 'panel3'} onChange={this.handleChange('panel3')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Ticket Search</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <form onSubmit={handleSubmit} style={{width:"100%"}}>
          <Grid container>
          <ItemGrid xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Ticket Id" id="ticket_id"
            errorText={errors.ticket_id} formControlProps={{margin: "none",fullWidth: true}}
            inputProps={{name: "ticket_id",value: values.ticket_id,onChange: handleChange}}
          />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={6}>
          <AsyncCustomSelect
            formControlProps={{fullWidth: true}}
            labelText="Assigned To"
            inputProps={{
              menuPosition: 'absolute',
              menuPlacement: 'auto',
              menuPortalTarget: document.body,
              styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
              defaultValue: values.user_id,
              cacheOptions: true,
              defaultOptions: true,
              onInputChange: (newValue) => {
                const inputValue = newValue.replace(/\W/g, '');
                this.setState({ "inputValue_user_id":inputValue });
                return inputValue;
              },
            id: "user_id",
            name: "user_id",
            onChange: value => setFieldValue('user_id', value),
            value: values.user_id,
            loadOptions: this.userSearch
            }} />
          </ItemGrid>
          </Grid>
          <Grid container>
          <ItemGrid xs={12} sm={12} md={6}>
            <AsyncCustomSelect
              formControlProps={{fullWidth: true}}
              labelText="Ticket Type"
              inputProps={{
                menuPosition: 'absolute',
                menuPlacement: 'auto',
                menuPortalTarget: document.body,
                styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
                defaultValue: values.ticket_type_id,
                cacheOptions: true,
                defaultOptions: true,
                onInputChange: (newValue) => {
                  const inputValue = newValue.replace(/\W/g, '');
                  this.setState({ "inputValue_ticket_type_id":inputValue });
                  return inputValue;
                },
                id: "ticket_type_id",
                name: "ticket_type_id",
                onChange: value => setFieldValue('ticket_type_id', value),
                value: values.ticket_type_id,
                loadOptions: this.ticketTypeSearch
              }}/>
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={6}>
            <AsyncCustomSelect
              formControlProps={{fullWidth: true}}
              labelText="Created By"
              inputProps={{
                menuPosition: 'absolute',
                menuPlacement: 'auto',
                menuPortalTarget: document.body,
                styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
                defaultValue: values.created_by_id,
                cacheOptions: true,
                defaultOptions: true,
                onInputChange: (newValue) => {
                  const inputValue = newValue.replace(/\W/g, '');
                  this.setState({ "inputValue_created_by_id":inputValue });
                  return inputValue;
                },
                id: "created_by_id",
                name: "created_by_id",
                onChange: value => setFieldValue('created_by_id', value),
                value: values.created_by_id,
                loadOptions: this.userSearch
              }} />
          </ItemGrid>
          </Grid>
          <Grid container>
          <ItemGrid xs={12} sm={12} md={6}>
          <AsyncCustomSelect
            formControlProps={{fullWidth: true}}
            labelText="Updated By"
            inputProps={{
              menuPosition: 'absolute',
              menuPlacement: 'auto',
              menuPortalTarget: document.body,
              styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
              defaultValue: values.updated_by_id,
              cacheOptions: true,
              defaultOptions: true,
              onInputChange: (newValue) => {
                const inputValue = newValue.replace(/\W/g, '');
                this.setState({ "inputValue_updated_by_id":inputValue });
                return inputValue;
              },
            id: "updated_by_id",
            name: "updated_by_id",
            onChange: value => setFieldValue('updated_by_id', value),
            value: values.updated_by_id,
            loadOptions: this.userSearch
            }} />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={6}>
          <AsyncCustomSelect
            labelText="Status"
            formControlProps={{fullWidth: true}}
            inputProps={{
              menuPosition: 'absolute',
              menuPlacement: 'auto',
              menuPortalTarget: document.body,
              styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
              defaultValue: values.status_id,
              cacheOptions: true,
              defaultOptions: true,
              onInputChange: (newValue) => {
                const inputValue = newValue.replace(/\W/g, '');
                this.setState({ "inputValue_status_id":inputValue });
                return inputValue;
              },
            id: "status_id",
            name: "status_id",
            onChange: value => setFieldValue('status_id', value),
            value: values.status_id,
            loadOptions: this.ticketStatusSearch
            }} />
          </ItemGrid>
          </Grid>
          <Grid container>
                  <ItemGrid xs={12} sm={12} md={12}>
                    <Button size="small" type="submit" color="primary" onClick={handleSubmit}>Search</Button>
                  </ItemGrid>
                </Grid>
          </form>
          </ExpansionPanelDetails>
          <ExpansionPanelActions><div></div></ExpansionPanelActions>
        </ExpansionPanel>
      )} />)}/>
    )
    const fullList = (
      <div className={classes.fullList}>
      <SignedInContext.Consumer>
        {({signedInUser, changeMe}) => {
          return (
            <Fragment>
            {iceQSearchScreen}
              {signedInUser.plan_features_list &&
                signedInUser.plan_features_list.feat_inventory? (
                  <Fragment>{assetSearchScreen}{ticketSearchScreen}</Fragment>
          ) : null}
            </Fragment>
      ) }}
        </SignedInContext.Consumer>
      </div>
    );

    return (
      <Fragment>
      <SearchButton
      color="white"
      aria-label="edit"
      style={{top: "-6px"}}
      customClass={classes.top + " " + classes.search}
        onClick={this.toggleDrawer('top', true)}
      >
        <Search  className={classes.searchIcon}/>
      </SearchButton>
        <Drawer
          anchor="top"
          open={this.state.top}
          onClose={this.toggleDrawer('top', false)}
          style={{overflow: "visible"}}
        >
            {fullList}

        </Drawer>
      </Fragment>
    );
  }
}

SwipeableTemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  openSearch: PropTypes.bool
};

export default withStyles(styles)(SwipeableTemporaryDrawer);
