
class IceQUtils {
  static test() {
    return "awesome"
  }

  static modelDropDrownFormat = (model) => {
    return `${model['manufacturer']} ${model['model']} ${model['indoor_outdoor'] ===1 ? "Indoor" : "Outdoor"}`
  }

  static freezerTypes = (freezer_type_id) => {
    
    var freezerTypeList =  [
      {
        "id":"0", 
        "type": "AutoDefrost"
      },
      {
        "id":"1", 
        "type": "ColdWall"
      }
    ]

    if(freezer_type_id){
      return freezerTypeList.filter(item => item.id === freezer_type_id)
    } else {
      return freezerTypeList
    }

  }

  static freezerTypeById = (freezer_type_id) => {
    var res = IceQUtils.freezerTypes(freezer_type_id)
    if(res.length > 0 ){
      return res[0]
    } else {
      return null
    }

  }

  static retrieveHeadersFromArrayObject = (obj) => {
    if(obj && obj.length >0){
      return Object.entries(obj[0]).map((item) =>{
        return item[0];
      })
    } else {
      return []
    }
  }

  static freezerIcon = (freezer) => {
    if(!freezer) return  require("assets/img/freezers/noimageavailable.svg")
    var img = ""
    try {
      img = freezer.model.manufacturer === "Leer" ? require("assets/img/freezers/"+freezer.model_id+ ".jpg") :
      require("assets/img/freezers/"+freezer.model_id+ ".png")
    } catch(err) {
      img = require("assets/img/freezers/noimageavailable.svg")
    }
    return img
  }

  static convertingHashToArray = (obj, func) => {
    if(obj && obj.length >0){
      return (obj).map((item) =>{
        return func(item)
      })
    } else {
      return []
    }
  }

  static pivotFilter = (pivot) => {
    return (pivot.data.table.map((ee) => {
       if(ee.type === "data"){
         var blurp = {}
          ee.value.map((val, idx) => {
            //remove totals
            if(pivot.data.table[0].value[idx]!=="Totals"){
               return blurp[pivot.data.table[0].value[idx]]=val
            } else {
              return null
            }

         })
         return blurp;
       } else { return null }
    }).filter((val) =>val))
  }

  static pivotHeader = (val) => {
    var headers = []
    var removedRowCol = false
    if(!val || val.length <= 0){
      return []
    }
    for (const [key] of Object.entries(val[0])) {
      if(!removedRowCol){
        removedRowCol = true
      }else {
        headers.push(key)
      }
    }
    return headers
  }
}

export default IceQUtils
