import React, { Component, Fragment } from 'react';
import LocationsStore from 'store/LocationStore';
import  { observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import LocationForm from "./LocationForm";
import Freezers from "./Freezers";
import FreezerForm from "./FreezerForm";
import { withStyles } from '@material-ui/core/styles';
import { withAuth, Button } from "components";
import { Add as AddIcon  } from "@material-ui/icons";
import {SignedInContext} from 'Contexts/signedin-context';
import qs from 'qs';
import { Link } from "react-router-dom";
import ExpandMore from '@material-ui/icons/ExpandMore';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Ticket from "views/Tickets/Ticket";
import TicketForm from "views/Tickets/TicketForm";
import TicketStore from "store/TicketStore";
import Typography from "@material-ui/core/Typography";
import {
  ItemGrid,
  SwitchCard,
  ButtonFab
} from "components";

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};


const Location = observer(
class Location extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showAddFreezer: false,
      showLocationDetails: 'none',
      showAddTicket: false
    };
  }

  componentDidMount() {
   LocationsStore.currentLocation = {}
   if("/locations/add" === this.props.location.pathname) {
     LocationsStore.setCurrentLocationToDefault();
   } else {
     if(this.props.match.params.id){
       var preferredFreezer = null
       if(this.props.location.search) {
          preferredFreezer = (qs.parse(this.props.location.search,
            {  ignoreQueryPrefix: true })).preferred_freezer;
       }
       LocationsStore.getLocation(this.props.match.params.id, null, preferredFreezer);
     } else {
       console.log("Location.componentDidMount should not be here")
     }
   }
 }

 handleAddFreezer = () => {
   this.setState({showAddFreezer: true})
 }

  render() {
    const { classes } = this.props;
    var preferredFreezer = null
    if(!LocationsStore.currentLocation) {return null}
    if(this.props.location.search) {
       preferredFreezer = (qs.parse(this.props.location.search,
         { ignoreQueryPrefix: true })).preferred_freezer;
    }

    return (
      <div>
            <SignedInContext.Consumer>
              {({signedInUser, changeMe}) => {
                  if(signedInUser) {
                    return (
                    <div>
                    <SwitchCard headerColor="iceq" titleOnly={LocationsStore.currentLocation.id > 0 && this.state.showLocationDetails==='none'} cardTitle={
                      <Grid container>
                        <ItemGrid xs={7} sm={8} md={9}>
                        {LocationsStore.currentLocation && 
                          LocationsStore.currentLocation.id > 0 ? (
                            <Grid container>
                            <ItemGrid xs={12} sm={12} md={12}>{LocationsStore.currentLocation.name}</ItemGrid>
                            <ItemGrid xs={12} sm={12} md={12}>
                            <Typography variant="caption"
                              component="title"
                              className={classes.cardSubtitle}>
                              {
                                LocationsStore.currentLocation.street + " " +
                                LocationsStore.currentLocation.city + " " + 
                                LocationsStore.currentLocation.state + " " + 
                                LocationsStore.currentLocation.zip + " " +
                                LocationsStore.currentLocation.country + " " 
                              } 
                            </Typography>
                          </ItemGrid>
                          </Grid>
                          ) : (
                            <Grid container>
                            <ItemGrid xs={12} sm={12} md={12}>Add Location</ItemGrid>
                          </Grid>
                          )}

                        </ItemGrid>
                        <ItemGrid xs={5} sm={4} md={3} style={{textAlign: "right"}}>  
                        { LocationsStore.currentLocation.id > 0?(
                          <Fragment>
                            {signedInUser.plan_features_list && signedInUser.plan_features_list.feat_inventory ? (
                              <IconButton aria-label="Details" onClick={(res) => {
                                this.setState({showLocationDetails: (
                                  this.state.showLocationDetails==='none' || this.state.showLocationDetails==='edit' ? 'detail'  :'none'
                            )})}}><ExpandMore /></IconButton>): null}
                          <IconButton aria-label="Details" onClick={(res) => {
                            this.setState({showLocationDetails: (
                              this.state.showLocationDetails==='none' || this.state.showLocationDetails==='detail' ? 'edit'  :'none'
                            )})}}><Edit /></IconButton>
                          </Fragment>
                        ):null}

                        </ItemGrid>
                      </Grid>} 

                       
                      content={ 
                        this.state.showLocationDetails ==='edit' || 
                          !LocationsStore.currentLocation || 
                          LocationsStore.currentLocation.id <=0 ? (
                          <LocationForm
                          data={LocationsStore.currentLocation}
                          store={LocationsStore}
                          loaded={(LocationsStore.currentLocation ? true :false)}
                          loggedInUser={signedInUser}
                        />
                        ): this.state.showLocationDetails ==='detail'&& LocationsStore.currentLocation.id > 0  ? (
                          <Grid container>
                          <ItemGrid xs={12} sm={12} md={12}>
                            <Typography variant="caption"
                              component="title"
                              className={classes.cardSubtitle}>
                            {LocationsStore.currentLocation.open_tickets.concat(LocationsStore.currentLocation.last_5_closed_tickets).map((ticket, readingIdx) => (
                              <div  key={`ticket${readingIdx}`}>
                              <Grid container>
                                <ItemGrid xs={12} sm={12} md={12} style={{textAlign: "left"}}>
                                  <Ticket store={TicketStore} displayFormat="at-location"
                                    onClickTicket={(ticket_id) => {
                                      TicketStore.getTicket(ticket_id, (res) => {
                                        this.setState({
                                          showIceQ: false,
                                          showAddFreezer: false,
                                          showAddTicket: true
                                        })
                                      })
                                  }} ticket={ticket}/>
  
                                </ItemGrid>
                              </Grid>
                              </div>
                            ))}
                          </Typography>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                        <Button color="primary" aria-label="Add"
                    component={Link} to={`/tickets?title=${encodeURIComponent("History for" + LocationsStore.currentLocation.name + " " + LocationsStore.currentLocation.street + " " + LocationsStore.currentLocation.city)}&search=${encodeURIComponent("location_id=" + LocationsStore.currentLocation.id+" or dest_location_id=" + LocationsStore.currentLocation.id)}`}
                    className={classes.button}
                    arrayidx="-1"
                    floating={false}
                    location_id={this.props.match.params.id}
                   >History</Button>
                      <Button color="primary" aria-label="Add Location Ticket"
                        onClick={ (res) =>{
                          TicketStore.currentTicket = TicketStore.getDefaultTicket({
                            location_id: LocationsStore.currentLocation.id,
                            location: LocationsStore.currentLocation,
                          })
                          this.setState({
                            showIceQ: false,
                            showAddFreezer: false,
                            showAddTicket: true,
                          })
                        }}
                        className={classes.button}
                        arrayidx="-1"
                        floating={false}>Add Location Ticket
                      </Button>
                        </ItemGrid>
                      </Grid>
                        )
                        : null }/>
                      <Freezers
                      data={LocationsStore.currentLocation}
                      store={LocationsStore}
                      loaded={(LocationsStore.currentLocation ? true :false)}
                      loggedInUser={signedInUser}
                      preferredFreezer={preferredFreezer}
                    />

                    {(LocationsStore.currentLocation && LocationsStore.currentLocation.id > 0 ?
                      (
                        <ButtonFab color="primary" aria-label="Add"
                         onClick={this.handleAddFreezer}
                         className={classes.button}
                         arrayidx="-1"
                         floating={true}
                         location_id={this.props.match.params.id}
                       >
                         <AddIcon />
                       </ButtonFab>) :null)
                    }
                    {(this.state.showAddFreezer) ? (
                        <FreezerForm
                          data={LocationsStore.getDefaultFreezer()}
                          store={LocationsStore}
                          loaded={(LocationsStore.currentLocation ? true :false)}
                          loggedInUser={signedInUser}
                          onClose={
                            (res) => {
                              this.setState({showAddFreezer: false})
                            }
                          }
                        />
                        ) : ( null )
                      }
                    </div>
                )
                  }
                }}
                </SignedInContext.Consumer>
                <TicketForm
                  store={TicketStore}
                  ticket={TicketStore.currentTicket ? TicketStore.currentTicket : TicketStore.getDefaultTicket({location_id: LocationsStore.currentLocation.id })}
                  loaded={(this.state.showAddTicket)}
                  onClose={ (res) => {
                    this.setState({showAddTicket: false})
                  }}
                  onSave={ (res) => {
                    var idx = LocationsStore.currentLocation.open_tickets.findIndex(function(element) { return element.id === res.id})
                    if(idx < 0) {
                      LocationsStore.currentLocation.open_tickets.unshift(res)
                    } else {
                      LocationsStore.currentLocation.open_tickets[idx] = res
                    }
                    
                    this.props.store.refreshLocation()
                  }}
                />
        </div>
    );
  }
})

 export default withAuth(withStyles(styles)(withAuth(Location)));
