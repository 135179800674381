import React, { Component } from 'react';
import { 
  IconButton } from "@material-ui/core";
import { PhotoCamera, Search } from "@material-ui/icons";
import {
AuthService,
withAuth,
CustomInput
} from "components";

import {SignedInContext} from 'Contexts/signedin-context';
import PropTypes from "prop-types";
import QrReader from 'react-qr-reader'


class AssetLookup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showIceQ: true,
      selectedIceq: {},
      delay: 100,
      result: 'No result',
      assetTagValue: "",
      assetTagError: "",
      manualEntry: false,
      showQRReader: false
    }
    this.handleScan = this.handleScan.bind(this)
  }

  componentDidMount(){}

  handleScan = (data) =>{
    if(data){
      this.setState({ result: data,assetTagValue: data})
      let Auth = new AuthService();
      var url = `${Auth.domain}/asset_tag?search=${data}`
      Auth.fetch(url, { method: 'GET' }).then(response => {
        if(response ){
          this.setState({ freezer: response, showQRReader: false, manualEntry: false })
          if(this.props.onSuccess)

            this.props.onSuccess(response, data)

        } else {
          if(!this.props.disableScanErrors)
            this.setState({ assetTagError: "Asset not found" })
          if(this.props.onFailure) {
            this.props.onFailure(data)
          }
        }
      })
    }
  }

  handleError(err){
    console.error(err)
  }
  handleManualTextChange = (event) => {
     this.setState({manualEntry: event.target.value ? true : false, assetTagError:"",
      assetTagValue : event.target.value, showQRReader:false})
  }
  render() {
    const { error } = this.props;
    return (
      <div>
      <SignedInContext.Consumer>
        {({signedInUser, changeMe}) => {
          if(signedInUser) {
            return (
              <div>

               <CustomInput
                labelText="Asset Tag"
                id="asset_tag"
                error={this.state.assetTagError || error ? true : false}
                errorText={this.state.assetTagError ? this.state.assetTagError : error ? error : null}
                formControlProps={{}}
                inputProps={{margin:"normal", name: "asset_tag", value: this.state.assetTagValue, onChange: this.handleManualTextChange}}
                endAdornment={this.state.manualEntry ? (<IconButton color="primary" 
                    onClick={(evt) => {this.handleScan(this.state.assetTagValue)  }}><Search /></IconButton>
                ) : (
                  <IconButton color="primary" 
                    onClick={(evt) =>{this.state.showQRReader? this.setState({showQRReader:false}) : this.setState({showQRReader:true})  }}>
                    <PhotoCamera />
                    </IconButton>
                )}
              />
              
              {!this.state.manualEntry && this.state.showQRReader ? (
                <QrReader delay={this.state.delay}
                onError={this.handleError}
                onScan={this.handleScan}
                style={{ width: '60%' }}
                />
              ) : null}

              </div>
            )
          }
        }}
      </SignedInContext.Consumer>
      </div>);
  }


}

AssetLookup.propTypes = {
  classes: PropTypes.object,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  error: PropTypes.string,
  disableScanErrors: PropTypes.bool
};
 export default withAuth(AssetLookup);
