import React, { Component } from 'react';
import AccountStore from 'store/AccountStore';
import  { observer } from "mobx-react";
import AccountForm from "./AccountForm";
import Regions from "./Regions";
import {LoaderCircle,
withAuth} from "components";

const Account = observer(
class Account extends Component {

  constructor(props) {
    super(props);
    this.state = {loaded: false};
  }

  componentDidMount() {
   AccountStore.loadAccount();
 }

  render() {
    if(AccountStore.isLoading){ return (
      <LoaderCircle/>
    )}
    return (
      <div>
        <AccountForm
          data={AccountStore.currentAccount}
          store={AccountStore}
          loaded={!AccountStore.isLoading}/>
        <Regions />
      </div>
    );
  }
})

export default withAuth(Account);
