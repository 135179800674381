import React, { Component } from 'react';
import AuthService from './AuthService';

import { Grid } from "@material-ui/core";
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  RegularCard,
  ProgressButton,
  CustomInput,
  ItemGrid
} from "components";
import {SignedInContext} from 'Contexts/signedin-context';

const defaultSaveMsgs = {
  loadingSaveMsg: "",
  loadingSaveSuccessMsg: "",
  loadingSaveFailureMsg: ""
}

class Signup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingSaveMsg: "",
      loadingSaveSuccessMsg: "",
      loadingSaveFailureMsg: ""
    };
  }

  render () {
    return (
        <div className="center">
        <SignedInContext.Consumer>
          {({signedInUser, changeMe}) => {
            return (
              <Formik
              initialValues={{
                email: '',
                name: '',
                phone: '',
                firstname: '',
                lastname: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: ''
              }}
              validationSchema= {Yup.object().shape({
                email: Yup.string().email('Email not valid').required('Email is required'),
                name: Yup.string().min(2, 'Company name must be 2 characters or more').required("Company name is required"),
                phone: Yup.string().min(10, 'Company phone must be 10 digits or more').required("Company phone is required"),
                firstname: Yup.string().min(2, 'Your first name must be 2 characters or more')
                .required("Your first name is required"),
                lastname: Yup.string().min(2, 'Your last name must be 2 characters or more').required("Your last name is required"),
                street1: Yup.string().min(2, 'Company Street Line 1 must be 2 characters or more').required("Company Street Line 1 is required"),
                street2: Yup.string().min(2, 'Company Street Line 2 must be 2 characters or more'),
                city: Yup.string().min(2, 'Company City must be 2 characters or more').required('City is required'),
                state: Yup.string().min(2, 'Company State must be 2 characters or more').required('State is required'),
                zip: Yup.string().min(2, 'Company Postal Code must be 2 characters or more').required('Postal Code is required')
              })}
              onSubmit={(values, {setSubmitting, resetForm, setErrors}) => {
                var Auth = new AuthService();
                this.setState( { loadingSaveMsg: "Saving ...",
                loadingSaveSuccessMsg: "",
                loadingSaveFailureMsg: ""
              })

              Auth.fetch(`${Auth.domain}/signup`,  {
                method: 'POST',
                body: JSON.stringify(values)
              })
              .then(res => {
              //  changeMe({client_region_id: res.client_regions[0].id, client_id: res.id})
                this.setState( { loadingSaveMsg: "",
                loadingSaveSuccessMsg: "Saved",
                loadingSaveFailureMsg: ""
              })
              setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
              this.props.history.replace('/dashboard')
              //resetForm()
            }).catch(err => {
              err.response.json().then((value) => {
                this.setState(
                  {
                    loadingSaveMsg: "",
                    loadingSaveSuccessMsg: "",
                    loadingSaveFailureMsg: "Error Saving"
                  }
                )
                setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
                setErrors(value.messages);
              })
            })
            setSubmitting(false)
          }}
          render={({values, errors, touched, handleChange, isSubmitting, handleSubmit}) => (
            <Grid container>
              <ItemGrid xs={12} sm={12} md={12}>
              <form onSubmit={handleSubmit}>
              <RegularCard
              headerColor="iceq" cardTitle="Add New Customer"
                cardSubtitle="Complete your Company Profile"
                content={
                  <div>
                  <Grid container>
                    <ItemGrid xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Company Name"
                        id="name"
                        errorText={errors.name}
                        formControlProps={{fullWidth: true}}
                        inputProps={{disabled: false, name: "name", onChange: handleChange, defaultValue: values.name}}

                        />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={3}>
                        <CustomInput
                        labelText="Company Phone"
                        id="phone"
                        errorText={errors.phone}
                        formControlProps={{fullWidth: true}}
                        inputProps={{name: "phone",  onChange: handleChange, defaultValue:values.phone}}
                        />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={4}>
                        <CustomInput
                        labelText="Email address"
                        id="email"
                        errorText={errors.email}
                        formControlProps={{fullWidth: true}}
                        inputProps={{name: 'email', onChange: handleChange }}
                        />
                        </ItemGrid>
                        </Grid>
                        <Grid container>
                        <ItemGrid xs={12} sm={12} md={6}>
                        <CustomInput
                        labelText="First Name"
                        id="firstname"
                        errorText={errors.firstname}
                        formControlProps={{fullWidth: true}}
                        inputProps={{name: 'firstname', onChange: handleChange}}
                        />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={6}>
                        <CustomInput
                        labelText="Last Name"
                        id="lastname"
                        errorText={errors.lastname}
                        formControlProps={{fullWidth: true}}
                        inputProps={{name: 'lastname', onChange: handleChange}}
                        />
                        </ItemGrid>
                        </Grid>
                        <Grid container>
                        <ItemGrid xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Address Line 1"
                          id="street1"
                          errorText={errors.street1}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'street1', onChange: handleChange
                          }}
                          />
                          </ItemGrid>
                          <ItemGrid xs={12} sm={12} md={6}>
                          <CustomInput
                          labelText="Address Line 2"
                          id="street2"
                          errorText={errors.street2}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'street2', onChange: handleChange
                          }}
                          />
                          </ItemGrid>
                          </Grid>
                          <Grid container>
                          <ItemGrid xs={12} sm={12} md={4}>
                          <CustomInput
                          labelText="City"
                          id="city"
                          errorText={errors.city}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'city', onChange: handleChange
                          }}
                          />
                          </ItemGrid>
                          <ItemGrid xs={12} sm={12} md={4}>
                          <CustomInput
                          labelText="State"
                          id="state"
                          errorText={errors.state}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'state', onChange: handleChange
                          }}
                          />
                          </ItemGrid>
                          <ItemGrid xs={12} sm={12} md={4}>
                          <CustomInput
                          labelText="Postal Code"
                          id="zip"
                          errorText={errors.zip}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'zip', onChange: handleChange
                          }}
                          />
                          </ItemGrid>
                          </Grid>
                          <Grid container>
                          <ItemGrid xs={12} sm={12} md={12}>
                          <ProgressButton  color="primary"
                          type="submit"
                          className="form-submit"
                          loadingMsg={this.state.loadingSaveMsg}
                          loadingSuccessMsg={this.state.loadingSaveSuccessMsg}
                          loadingFailureMsg={this.state.loadingSaveFailureMsg}
                          disabled={isSubmitting}
                          >
                          Add New Customer
                          </ProgressButton>
                          </ItemGrid>
                          </Grid>
                          </div>
                        } />
                        </form>
                        </ItemGrid>
                        </Grid>
                      )} />
                    )
                }}
              </SignedInContext.Consumer>
        </div>
    );
  }
}

export default Signup;
