import React from "react";
import { withStyles, FormControl, TextField, InputAdornment } from "@material-ui/core";
import { Clear, Check } from "@material-ui/icons";
import PropTypes from "prop-types";
import cx from "classnames";

import customInputStyle from "variables/styles/customInputStyle";

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    errorText,
    endAdornment
  } = props;

  const labelClasses = cx({
    [" " + classes.labelRootError]: (error || (errorText !== undefined && errorText !=='')),
    [" " + classes.labelRootSuccess]: success && !(error || (errorText !== undefined && errorText !==''))
  });

  const marginTop = cx({
    [classes.marginTop]: labelText === undefined
  });
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      <TextField 
        id={id}
        {...inputProps}
        error={(errorText != null ) ? true : false}
        label={(errorText != null)? errorText : labelText}
        variant="outlined"
        InputProps={endAdornment ? ({
          endAdornment: <InputAdornment position="end"> {endAdornment}</InputAdornment>}
        ) : (error || (errorText !== undefined && errorText !==null && errorText !== "")) ? ({
          endAdornment: <InputAdornment position="end"><Clear className={classes.feedback + " " + classes.labelRootError} /></InputAdornment>,
        }):success ? ({
          endAdornment: <InputAdornment position="end"><Check className={classes.feedback + " " + classes.labelRootSuccess} /></InputAdornment>}
        ) :  null } 
      />
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  errorText: PropTypes.node,
  endAdornment: PropTypes.node 
};

export default withStyles(customInputStyle)(CustomInput);
