import {
  autorun,
  observable,
  decorate
} from "mobx";
import AuthService from 'components/AuthService';
import { DateTime } from 'luxon';
class UserStore {
  users = [];
  hasNextPage = true;
  currentPage = 0;
  isLoading = true;
  previousSize = -1;
  lastLoadedUser = null;
  role_list = {
      "iceq_admin": "ICEQ Admin",
      "account_admin": "Account Admin",
      "region_admin": "Region Admin",
      "user": "Users"
    }
  me = null;
  Auth = new AuthService();

  isRowLoaded({
    index
  }) {
    return !this.hasNextPage || index < this.users.size
  }

  resetStore(){
    this.users = []
    this.hasNextPage = true;
    this.currentPage = 0;
    this.isLoading = true;
    this.previousSize = -1;
    this.lastLoadedUser = null;
  }
  getUserTimeZone(){
    return "America/Los_Angeles"
  }
  getLuxon(){
    return DateTime
  }
  loadUsers(page) {
    //this.users = []
    if (page) {
      this.currentPage = page
    } else {
      this.currentPage = 1
    }
    this.Auth.fetch(`${this.Auth.domain}/users?page=${this.currentPage}`, {
      method: 'GET'
    }).then(response => {
      if (this.previousSize === -1 || this.previousSize < response.length) {
        this.previousSize = response.length;
        this.hasNextPage = true;
      } else if (this.previousSize === response.length) {
        this.hasNextPage = false;
      }
      this.users = response;
      this.isLoading = false;
    })
    return true;
  }

  updateUser(ori_user, onComplete, onFailure) {
    //  this.isLoading = true
    //cpy the user object so that we  don t change the
    // original obj.
    var user = JSON.parse(JSON.stringify(ori_user));
    var httpmethod = "POST"
    var url = `${this.Auth.domain}/users`
    if (user.id && user.id > 0) {
      httpmethod = "PUT"
      url = `${this.Auth.domain}/users/${user.id}`

    }
    delete user['id']
    this.Auth.fetch(url, {
        method: httpmethod,
        body: JSON.stringify(user)
      })
      .then(response => {
        if(onComplete){
          onComplete(response);
        }

      }).catch(err => {
        //alert(err);
        if(onFailure){
          onFailure(err);
        }
      })

    return true;
  }

  resetPassword(userId, onComplete, onFailure) {
    //  this.isLoading = true
    var httpmethod = "GET"
    var url = `${this.Auth.domain}/users/${userId}/reset_password`
    this.Auth.fetch(url, {
        method: httpmethod
      })
      .then(response => {
        if(onComplete){
          onComplete(response);
        }
      }).catch(err => {
        alert(err);
        if(onFailure){
          onFailure(err);
        }
      })

    return true;
  }

  getMeID() {
    return 'me';
  }

  getDefaultUser(){
      return ({
        "name": "",
        "timezone": "",
        "id": -1,
        "firstname": "",
        "lastname": "",
        "email": "",
        "mobile_number": "",
        "client_region_id": 0,
        "roles": "user",
        "warehouse_ids": []
      })
  }

  loadMe() {
    return this.loadSingleUser(this.getMeID());
  }

  logout() {
    this.Auth.logout()
  }

  userRoaster(searchCrit, onComplete, onFailure) {
    //  this.isLoading = true
    var httpmethod = "GET"
    var url = `${this.Auth.domain}/users_roaster?search=${searchCrit}`
    this.Auth.fetch(url, {
        method: httpmethod
      })
      .then(response => {
        if(onComplete){
          onComplete(response);
        }
      }).catch(err => {
        alert(err);
        if(onFailure){
          onFailure(err);
        }
      })

    return true;
  }


  loadSingleUser(user_id, onCompletion) {
    this.Auth.fetch(`${this.Auth.domain}/users/${user_id}`, {
        method: 'GET'
      })
      .then(response => {
        if (response.length <= 0) {
          //TODO: raise exception as not found.
        }
        this.lastLoadedUser = response;
        onCompletion(response);
      })

    return true;
  }
}
decorate(UserStore, {
  isLoading: observable,
  users: observable,
  hasNextPage: observable
})
var store = window.store = new UserStore();
export default store
autorun(() => {});
