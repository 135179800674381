import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { withStyles } from "@material-ui/core";

import { Header, Footer, Sidebar, withAuth } from "components";

import appRoutes from "routes/app.jsx";

import appStyle from "variables/styles/appStyle.jsx";

import image from "assets/img/backdrop.jpg";
import logo from "assets/img/icelogo-nameside-xtrasmall.png";

import userStore from "store/UserStore";
import locationStore from "store/LocationStore";

import {SignedInContext} from 'Contexts/signedin-context';
import freezerStore from "../../store/FreezerStore";
import ticketStore from "../../store/TicketStore";



const switchRoutes = (
  <Switch>
    {appRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      mobileOpen: false,
      currentUser: null,
      toDash:false
    };
  }

  handleChangeMe = (userInfo, onSuccess, onError) => {
    var cUser = userInfo;
    cUser.id = this.state.currentUser.id;
    userStore.updateUser(cUser,
    (me) => {
      if(me.account_id !== this.state.currentUser.account_id ||
        me.client_region_id !== this.state.currentUser.client_region_id ) {
        locationStore.resetStore()
        userStore.resetStore()
        freezerStore.resetStore()
        ticketStore.resetStore()
        }
      this.setState({ currentUser: me, toDash: true})
      if(onSuccess) { onSuccess(me) } 
    },
    (error) => {
      if(onError) {
        error.response.json().then(
          (value) => {
            onError(value);
          }
        )
      }
    });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps";
  }
  componentDidMount() {
    if(navigator.platform.indexOf('Win') > -1){
      // eslint-disable-next-line
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    userStore.loadSingleUser(userStore.getMeID(),
    (me) => {
      this.setState({ currentUser: me}
       )
    })
  }

  componentDidUpdate() {
    this.refs.mainPanel.scrollTop = 0;
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <SignedInContext.Provider
        value={
          {signedInUser: this.state.currentUser,
          changeMe: this.handleChangeMe}}>
      <div className={classes.wrapper}>
        <Sidebar
          routes={appRoutes}
          logoText={""}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Header
            routes={appRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
          {this.getRoute() ? <Footer /> : null}
        </div>
      </div>
      </SignedInContext.Provider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(withAuth(App));
