import {
  autorun,
  observable,
  action,
  decorate
} from "mobx";
import AuthService from 'components/AuthService';
import { DateTime } from 'luxon';
class LocationStore {
  locations = [];
  hasNextPage = true;
  currentPage = 1;
  isLoading = true;
  previousSize = -1;
  currentLocation = {};
  searchCrit = "";
  searchOrder = "";
  Auth = new AuthService();
  freezerStatus = [
    {"val": "Active", "desc": "Active"},
    {"val": "Inactive", "desc": "Inactive"},
    {"val": "Seasonal", "desc": "Seasonal"}
  ]
  locationStatus = [
    {"val": "0", "desc": "Active"},
    {"val": "1", "desc": "Inactive"}
  ]

  category_list =  [
    {"val": "0", "desc": "Customer Location"},
    {"val": "1", "desc": "Warehouse"}
  ]

  conditionsList = []
  productsList = []
  placementsList = []
  modelsList = []
  productModelRatioList = []

  resetStore(){
    this.locations = []
    this.hasNextPage = true
    this.currentPage = 1
    this.isLoading = true
    this.previousSize = -1
    this.currentLocation = null
    this.searchCrit = ""

  }
  locationStats(statsType, arg, onComplete, onError){
    if (this.currentLocation.id >0) {
      this.Auth.fetch(`${this.Auth.domain}/locations/${this.currentLocation.id}/stats?stats=${statsType}&model_id=${arg}`, {
          method: 'GET'
        })
        .then(response => {
          if (response.length <= 0) {
          }else {
             if(onComplete){ onComplete(response) } }
      }).catch(error => {
        error.response.json().then((value) => { if(onError){ onError(value); } })
      })
    }
    return true;
  }

  updateFreezer(freezer, onComplete, onError){
    var httpmethod = "POST"
    var url = `${this.Auth.domain}/locations/${this.currentLocation.id}/freezers`
    if (freezer.id && freezer.id > 0) {
      httpmethod = "PUT"
      url = `${this.Auth.domain}/locations/${this.currentLocation.id}/freezers/${freezer.id}`
    }
    this.Auth.fetch(url, {
        method: httpmethod,
        body: JSON.stringify(freezer)
      })
      .then(response => {
        this.getLocation(this.currentLocation.id);
        if(onComplete){
          onComplete(response);
        }
        this.isLoading = false;
      }).catch(error => {
        if(onError){
          error.response.json().then((value) => { onError(value); })
        }
      })
    return true;
  }

 defaultToNonNull = (val, type) => {
    if(val){
      return val
    } else {
      return (type && type ==="n") ? 0 : ""
    }
  }
  isRowLoaded({
    index
  }) {
    return !this.hasNextPage || index < this.users.size
  }

  addIceQ(iceQ, freezer, onCompletion, onFailure){
    //  this.isLoading = true
      if (freezer.id && freezer.id > 0) {
        freezer.iceq_id = iceQ.id
        this.updateFreezer(freezer, onCompletion, onFailure)
      } else {
        return false;
      }
      return true;
  }

  removeIceQ(freezer, onCompletion, onFailure){
    //  this.isLoading = true
      if (freezer.id && freezer.id > 0) {
        freezer.iceq_id = null
        this.updateFreezer(freezer, onCompletion, onFailure)
      } else {
        return false;
      }
      return true;
  }

  analyzeLocation(location) {
   location.alert = "cool"
  }

  refreshLocation(){
    if(this.currentLocation && this.currentLocation.id > 0 ){
      this.getLocation(this.currentLocation.id)
    }
  }

   getLocation(locationId, onError, preferredFreezer) {
    if (locationId < 0) {
      //load default
    } else {
      this.Auth.fetch(`${this.Auth.domain}/locations/${locationId}`, {
          method: 'GET'
        })
        .then(response => {
          if (response.length <= 0) {
            //TODO: raise exception as not found.
          }
          //if we passed a favorite freezer , we move it on top of array
          if(preferredFreezer) {
            var fidx = response.freezers.findIndex( o => o.id === Number(preferredFreezer))
            if(fidx > 0){
              var item = response.freezers[fidx]
              response.freezers.splice( fidx, 1 );
              response.freezers.unshift( item );
            }
          }
          this.currentLocation = response;
          this.isLoading = false;
      }).catch(error => {
        error.response.json().then((value) => { onError(value); })
        this.isLoading = false;
        })
    }
    return true;
  }

  updateLocation(ori_location, onSuccess, onError) {
    //this.isLoading = true
    var location = JSON.parse(JSON.stringify(ori_location));
    var httpmethod = "POST"
    var url = `${this.Auth.domain}/locations`
    if (location.id && location.id > 0) {
      httpmethod = "PUT"
      url = `${this.Auth.domain}/locations/${location.id}`

    }
    delete location['id']
    this.Auth.fetch(url, {
        method: httpmethod,
        body: JSON.stringify(location)
      })
      .then(response => {
        this.currentLocation = response;
//update local array
        var idx = this.locations.findIndex(function(element) { return element.id === response.id})
        if(idx < 0) {
          this.locations.unshift(response)
        } else {
          this.locations[idx] = response
        }
        //this.isLoading = false;
        if(onSuccess) {
          onSuccess(response)
        }
      }).catch(error => {
        if(onError){
          error.response.json().then((value) => { onError(value); })
        }
      })
    return true;
  }

  setCurrentLocationToDefault(){
    this.isLoading = true
    this.currentLocation = this.getDefaultLocation();
    this.isLoading = false
  }

  getDefaultLocation(){
      return ({
        "name": "",
        "id": -1,
        "street": "",
        "city": "",
        "state": "",
        "zip": "",
        "contact_phone": "",
        "contact_email": "",
        "latitude": "",
        "longitude": "",
        "note": "",
        "zone_num": 0,
        "client_region_id": 0
      })
  }

  getDefaultFreezer(){
    return ({
    "id": -1,
    "status": "Active",
    "event_status": "",
    "event_reason": "",
    "install_date": DateTime.local().toISO(),
    "installed_by": null,
    "removal_date": null,
    "removal_by": null,
    "serial_number": "",
    "model_id": 0,
    "outside": 0,
    "placement_id": 0,
    "condition_id": 0,
    "product_model_ratio_id": 0,
    "product_left_side_id": 0,
    "product_right_side_id": 0,
    "width": 0,
    "height": 0,
    "door_count": 0,
    "comment": ""
    })
  }

  loadConditions(onCompletion) {
    return this.loadSupportData(this.conditionsList, "conditions", null, onCompletion);

  }

  loadProducts(onCompletion) {
    return this.loadSupportData(this.productsList, "products", null, onCompletion);
  }

  loadPlacements(onCompletion) {
    return this.loadSupportData(this.placementsList, "placements", null, onCompletion);
  }

  loadModels(onCompletion) {
    return this.loadSupportData(this.modelsList, "models", null, onCompletion);
  }

  loadProductModelRatios(onCompletion) {
    return this.loadSupportData(this.productModelRatioList, "product_model_ratios", null, onCompletion);
  }

  loadAllSupportData(onCompletion){
    this.loadConditions((res) => {
      this.loadProducts((res) => {
        this.loadPlacements((res) => {
          this.loadModels((res) => {
            this.loadProductModelRatios(onCompletion)
          })
        })
      })
    })
  }

  loadSupportData(list, supportDataDesc, stateVarWhenLoaded, onCompletion) {
    //if already loaded then return with cached value
    if(list.length > 0 ) {
      onCompletion(list);
      return true;
    }
    this.Auth.fetch(`${this.Auth.domain}/${supportDataDesc}`, {
        method: 'GET'
      })
      .then(response => {
        if (response.length <= 0) {
          //TODO: raise exception as not found.
        }
        list.splice(0, list.length);
        list.push(...response);
        onCompletion(response);
      })

    return true;
  }

  quickSearch(search, onComplete, onError){
    var url = `${this.Auth.domain}/locations_quicklook?search=${search}`
    this.Auth.fetch(url, { method: 'GET' }).then(response => {
      if (response.length <= 0) { onComplete(response)  }
      if(onComplete){ onComplete(response) }
    }).catch(error => {
      error.response.json().then((value) => { onError(value); })
    })
    return true;
  }

  startSearch(search, orderBy){
  //  this.locations.clear()
    this.isLoading = true
    this.searchOrder = orderBy
    this.searchCrit = search
    this.previousSize = -1
    this.hasNextPage = true
    this.currentPage = 1
    this.loadLocations(this.currentPage)
  }

  loadLocations(page) {
    if (page) {
      this.currentPage = page
    } else {
      this.currentPage = 1
    }
    var url = `${this.Auth.domain}/locations?page=${this.currentPage}`
    if(this.searchCrit) {
      url = `${url}&search=${this.searchCrit}`
    } 
    if(this.searchOrder) {
      url = `${url}&order_by=${this.searchOrder}`
    } 

    this.Auth.fetch(url, {
      method: 'GET'
    }).then(response => {
      if(response.length < 20 ){
        this.previousSize = response.length;
      }
//      console.log( "this.previousSize", this.previousSize, "response.length", response.length)
      if (this.previousSize === -1 || this.previousSize < response.length) {
        this.previousSize = response.length;
        this.hasNextPage = true;
      } else if (this.previousSize === response.length) {
        this.hasNextPage = false;
      }
      this.locations = response;
      this.isLoading = false;
    })
    return true;
  }
}
decorate(LocationStore, {
  locations: observable,
  hasNextPage: observable,
  isLoading: observable,
  currentLocation: observable,
  refreshLocation: action,
  startSearch: action,
  loadLocations: action
})
var locationStore = window.locationStore = new LocationStore()
export default locationStore
autorun(() => {});
