import React from "react";
import { withStyles } from "@material-ui/core";
import { AddLocation as AddIcon } from "@material-ui/icons";
import { List, ListItem } from "@material-ui/core";
import PropTypes from "prop-types";
import InfiniteScroll from 'react-infinite-scroller';
import  { observer } from "mobx-react";
import tasksStyle from "variables/styles/tasksStyle.jsx";
import TicketStore from 'store/TicketStore';
import TicketForm from './TicketForm';
import qs from 'qs';
import Ticket from "views/Tickets/Ticket";

import {
withAuth,
RegularCard,
ButtonFab,
LoaderCircle
} from "components";

const TicketsCard = observer(
class TicketsCard extends React.Component {
  state = {
    checked: this.props.checkedIndexes,
    searchCrit: "",
    showAddTicket: false,
    ticketList: null,
    boxTitle: "Ticket List"
  };

  parseSearch = (ticketSearch) => {
    const values = qs.parse(ticketSearch, { ignoreQueryPrefix: true });
    return values;
  }

  componentDidMount() {

    if(this.props.location.search) {
      var para = this.parseSearch(this.props.location.search)
      if(para.title){
        this.setState({boxTitle: para.title})
      }
      TicketStore.startSearch(para.search)
    } else if (TicketStore.tickets.length === 0) {
      TicketStore.startSearch("ticket_status_id=1 or ticket_status_id=0")
    }
  }

  loadItems = (page) => {
    TicketStore.loadTickets(page,null, null, this.props.freezer_id);
  }

  render() {
    const { classes } = this.props;
    if(!TicketStore.tickets) {
      return (
        <LoaderCircle/>
      )
    }
    var items = [];
    TicketStore.tickets.map((track, i) => {
        items.push(
          <div key={`divloc${track.id}`}>
            <ListItem key={`ticket${track.id}`}   button>
            <Ticket store={TicketStore} displayFormat="ticket-list"
              onClickTicket={(ticket_id) => {
                TicketStore.getTicket(ticket_id, (res) => {
                  this.setState({ currentTicket: TicketStore.currentTicket,
                    showIceQ: false,
                    showAddFreezer: false,
                    showAddTicket: true
                  })
                })
              }} ticket={track}/>
              </ListItem>
            </div>
        );
        return "";
    });


return (
    <div>
        <RegularCard headerColor="iceq" cardTitle={this.state.boxTitle} cardSubtitle=""
          content={
          <div style={{height: "calc(100vh - 220px)",overflow:"auto"}}>

              <InfiniteScroll
                  pageStart={1}
                  loadMore={this.loadItems.bind(this)}
                  hasMore={TicketStore.hasNextPage}
                  loader={
                    <div className="loader" key={0}> <LoaderCircle/></div>
                  }
                  useWindow={false}
              ><List>{items}</List></InfiniteScroll>
          </div>
        }/>
        <ButtonFab color="primary" aria-label="Add Ticket"
          onClick={ (res) =>{
            TicketStore.currentTicket = TicketStore.getDefaultTicket({client_region_id: 0 })
            this.setState({
              showIceQ: false,
              currentTicket: TicketStore.currentTicket,
              showAddFreezer: false,
              showAddTicket: true
            })
          }}
          floating={true}
          className={classes.button}
          arrayidx="-1"
          >  <AddIcon />
        </ButtonFab>


      <TicketForm
        store={TicketStore}
        ticket={TicketStore.currentTicket}
        loaded={(this.state.showAddTicket && TicketStore.currentTicket) ? true : false }
        onClose={ (res) => {
          this.setState({showAddTicket: false})
        }
      }/>
    </div>

)
}
})

TicketsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  store: PropTypes.arrayOf(PropTypes.node)
};

export default withAuth(withStyles(tasksStyle)(TicketsCard));
