import React, { Component } from 'react';
import qs from 'qs';
//import  { observer } from "mobx-react";

import { withAuth } from "components";

import {SignedInContext} from 'Contexts/signedin-context';
import { Route } from "react-router-dom";
class DeepLink extends Component {
// http://localhost:3000/deeplink/75/128?url=https://www.iceq.cool
// http://localhost:3000/deeplink/75/41?url=/users
  render() {
    return (
      <div>
        <Route refresh={true} render={({ history}) => (
        <SignedInContext.Consumer>
          {({signedInUser, changeMe}) => {
            var usr = {}
            var pArray = this.props.location.pathname.split("/")
            if(pArray.length !== 3)
              return null
            usr.client_region_id = Number(pArray[2])

            const values = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            if(signedInUser) {
              changeMe(usr,(nUser)=>{ history.push(values['url']) })
            }
          }}
        </SignedInContext.Consumer>
        )} />
      </div>
    );
  }
}
export default withAuth(DeepLink);
