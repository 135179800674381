import React, { Component } from 'react';
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import userStore from 'store/UserStore';
import { Formik} from 'formik';
import * as Yup from 'yup';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
RegularCard,
CustomInput,
ItemGrid,
ProgressButton,
Button
} from "components";

import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';

import { Link } from "react-router-dom";

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  checked: {},
  size: {
    width: 40,
    height: 40,
  },
  sizeIcon: {
    fontSize: 20,
  },
};
const defaultResetPassMsgs =      { loadingResetPassMsg: "",
      loadingResetPassSuccessMsg: "",
      loadingResetPassFailureMsg: ""
    }

const defaultSaveMsgs =      { loadingSaveMsg: "",
          loadingSaveSuccessMsg: "",
          loadingSaveFailureMsg: ""
        }

const createInitialValuesListFromPerms = (permList, selectedUser) => {
  let initialValues = {}
  for(let permittedField of permList){
      initialValues[permittedField] = selectedUser[permittedField]
  }
  if(selectedUser.id){
    initialValues.id = selectedUser.id;
  }
  return initialValues;
}

class UserForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: this.props.loggedInUser,
      loadingResetPassMsg: "",
      loadingResetPassSuccessMsg: "",
      loadingResetPassFailureMsg: "",
      loadingSaveMsg: "",
      loadingSaveSuccessMsg: "",
      loadingSaveFailureMsg: ""
    };
  }

  resetPassword = (user) => {
    let uid = user.currentTarget.getAttribute('user_id')
    this.setState({
      loadingResetPassMsg: "Sending Reset Password Request",
      loadingResetPassSuccessMsg: "",
      loadingResetPassFailureMsg: ""
    })
    this.props.onResetPassword(uid,
       (completed) => {
         this.setState({
         loadingResetPassMsg: "",
         loadingResetPassSuccessMsg: "Reset Password Request Sent",
         loadingResetPassFailureMsg: ""
       })
       setTimeout(() =>{ this.setState(defaultResetPassMsgs); }, 2000 );
       },
       (errors) => {
         this.setState({
         loadingResetPassMsg: "",
         loadingResetPassSuccessMsg: "",
         loadingResetPassFailureMsg: "Request Failed To Send"
       })
       setTimeout(() =>{ this.setState(defaultResetPassMsgs); }, 2000 );
       }
    )
  }

  render() {
    const { classes } = this.props;
    if (!this.props.loaded) return null
    //find out if it s me.
    let initialValuesList = {};
    let permFieldList = []

    if(this.state.loggedInUser.id === this.props.selectedUser.id){
      permFieldList = this.state.loggedInUser.me_permissions.user.field_list
        .concat(this.state.loggedInUser.permissions.user.field_list);
      initialValuesList = createInitialValuesListFromPerms(permFieldList, this.state.loggedInUser)
    } else {
      permFieldList = this.state.loggedInUser.permissions.user.field_list;
      initialValuesList = createInitialValuesListFromPerms(permFieldList, this.props.selectedUser)
      if (initialValuesList.client_region_id === 0){
        initialValuesList.client_region_id = this.state.loggedInUser.client_region_id;
      }
    }
    var infoRole = this.props.selectedUser.roles
    var canEdit = (this.props.selectedUser.id === this.state.loggedInUser.id || (this.props.loggedInUser.available_roles.find(
      function(element) { return element.key === infoRole }
    )) ? true : false )
  //  canEdit = true
      return (
        <div>
        <Formik
          initialValues={initialValuesList}
          enableReinitialize={true}
          onSubmit={(values, {setSubmitting, resetForm, setErrors}) => {
            this.setState( { loadingSaveMsg: "Saving ...",
                      loadingSaveSuccessMsg: "",
                      loadingSaveFailureMsg: ""
                    })
            this.props.onSave(values,
              (completed) => {
                this.setState( { loadingSaveMsg: "",
                          loadingSaveSuccessMsg: "Saved",
                          loadingSaveFailureMsg: ""
                        })
                setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
              },
              (error) => {
                setErrors(error)
                this.setState(
                  {
                    loadingSaveMsg: "",
                    loadingSaveSuccessMsg: "",
                    loadingSaveFailureMsg: "Error Saving"
                  }
                )
                setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
              });
            setSubmitting(false);

          }}

          validationSchema= {Yup.object().shape({
            email: Yup.string().email('Email not valid').required('Email is required'),
            mobile_number: Yup.string().min(10, 'Mobile phone must be 10 digits or more').required("Mobile phone is required").nullable(),
            firstname: Yup.string().min(2, 'Your first name must be 2 characters or more')
              .required("Your first name is required"),
            lastname: Yup.string().min(2, 'Your last name must be 2 characters or more').required("Your last name is required")
      })}

      render={({values, errors, touched, handleChange, isSubmitting, setFieldValue,  handleSubmit,  setValues}) => (
      <div className="center">
      <div className="card">
          <form onSubmit={handleSubmit}>
          <Grid container>
            <ItemGrid xs={12}>
            <RegularCard headerColor="iceq"
              cardTitle={values.id <= 0 ? "Add User" :
                (this.props.loggedInUser.id === this.props.selectedUser.id) ?
                "My Profile" : "Manage User"
              }
              cardSubtitle="" content={
              <div>
              <Grid container>
                <ItemGrid xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="First Name"
                  id="firstname"
                  errorText={errors.firstname}
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: (permFieldList.indexOf("firstname") < 0 || !canEdit),
                    name: "firstname",
                    value: values.firstname,
                    onChange: handleChange
                  }}
                />
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Last Name"
                  id="lastname"
                  errorText={errors.lastname}
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    name: "lastname",
                    disabled: (permFieldList.indexOf("lastname") < 0 || !canEdit),
                    value: values.lastname,
                    onChange: handleChange
                  }}
                />

              </ItemGrid>
              </Grid>
  <Grid container>
    <ItemGrid xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Email"
                id="email"
                errorText={errors.email}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  name: "email",
                  disabled: (permFieldList.indexOf("email") < 0 || !canEdit),
                  value: values.email,
                  onChange: handleChange
                }}
              />
              </ItemGrid>
              <ItemGrid xs={12} sm={12} md={6}>
    <CustomInput
      labelText="Mobile Phone"
      id="mobile_number"
      errorText={errors.mobile_number}
      formControlProps={{fullWidth: true}}
      inputProps={{
        name: "mobile_number",
        disabled: (permFieldList.indexOf("mobile_number") < 0 || !canEdit),
        value: values.mobile_number,
        onChange: handleChange
      }}
    />
    </ItemGrid>
            </Grid>
              <Grid container>
              {(permFieldList.indexOf("client_region_id") >= 0) ? (
                <ItemGrid xs={12} sm={12} md={6}>
                  <TextField
                    id="client_region_id"
                    variant="outlined"
                    select
                    label="Region"
                    disabled={(permFieldList.indexOf("client_region_id") < 0 || !canEdit)}
                    name="client_region_id"
                    className={classes.textField}
                    value={values.client_region_id}
                    onChange={handleChange}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                  >
                    {
                      this.state.loggedInUser.available_client_regions.map((optionValue) => (
                        <option key={optionValue.id} value={optionValue.id}>{optionValue.name}</option>
                      ))
                    }
                  </TextField>
                </ItemGrid>
              ) : null }
              {(permFieldList.indexOf("client_region_id") >= 0) ? (
                <ItemGrid xs={12} sm={12} md={6}>
                  <TextField
                    id="roles"
                    variant="outlined"
                    select
                    label="Roles"
                    disabled={(permFieldList.indexOf("roles") < 0 || !canEdit)}
                    name="roles"
                    className={classes.textField}
                    value={values.roles}
                    onChange={handleChange}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                  >
                  {
                    this.state.loggedInUser.available_roles.findIndex(function(element) {
                      return element.key === infoRole}) <0 ?
                      (<option key={infoRole} value={infoRole}>{userStore.role_list[infoRole]}</option>)
                   : null
                  }
                  {
                    canEdit ? this.state.loggedInUser.available_roles.map((optionValue ) => (
                    <option key={optionValue.key} value={optionValue.key}>{optionValue.value}</option>
                  )) : null
                  }
                  </TextField>
                </ItemGrid>
              ) : null }
              <ItemGrid xs={12} sm={12} md={6}>
              <FormControlLabel control={
                <Checkbox
                  className={classes.size}
                  icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}

                  checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
                  checked={(values.active && (values.active === 1 || values.active === true)) ? true : false}
                  disabled={permFieldList.indexOf("active") < 0 || !canEdit}
                  onChange={handleChange}
                  name="active"
                />
              }
              label="Active"
              />
              </ItemGrid>
              </Grid>

              <Grid container>
                <ItemGrid xs={12} sm={12} md={12}></ItemGrid>
              </Grid>
              <Grid container>
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControlLabel control={
                  <Checkbox
                    className={classes.size}
                    icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                    checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
                    checked={(values.alert_nocontact && (values.alert_nocontact === 1 || values.alert_nocontact === true))? true: false}
                    disabled={permFieldList.indexOf("alert_nocontact") < 0 || !canEdit}
                    onChange={handleChange}
                    name="alert_nocontact"
                  />
                }
                label="No Recent link Alert"
                />
                <FormControlLabel control={
                  <Checkbox
                    className={classes.size}
                    icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                    checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
                    checked={(values.alert_temp && (values.alert_temp === 1 || values.alert_temp === true)) ? true : false}
                    disabled={permFieldList.indexOf("alert_temp") < 0 || !canEdit}
                    onChange={handleChange}
                    name="alert_temp"
                  />
                }
                label="Temperature Alert"
                />
                <FormControlLabel control={
                  <Checkbox
                    className={classes.size}
                    icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                    checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
                    checked={(values.alert_inventory && (values.alert_inventory === 1 || values.alert_inventory === true)) ? true : false}
                    disabled={permFieldList.indexOf("alert_inventory") < 0 || !canEdit}
                    onChange={handleChange}
                    name="alert_inventory"
                  />
                }
                label="% Full Alert"
                />
                <FormControlLabel control={
                  <Checkbox
                    className={classes.size}
                    icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                    checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
                    checked={(values.alert_inventory_lr && (values.alert_inventory_lr === 1 || values.alert_inventory_lr === true)) ? true : false}
                    disabled={permFieldList.indexOf("alert_inventory_lr") < 0 || !canEdit}
                    onChange={handleChange}
                    name="alert_inventory_lr"
                  />
                }
                label="Left/Right % Full Alert"
                />
                <FormControlLabel control={
                  <Checkbox
                    className={classes.size}
                    icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                    checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
                    checked={(values.alert_inventory_replenish && (values.alert_inventory_replenish === 1 || values.alert_inventory_replenish === true)) ? true : false}
                    disabled={permFieldList.indexOf("alert_inventory_replenish") < 0 || !canEdit}
                    onChange={handleChange}
                    name="alert_inventory_replenish"
                  />
                }
                label="Fill Alert"
                />
                </ItemGrid>
              </Grid>
              {(this.props.loggedInUser.plan_features_list && (this.props.loggedInUser.plan_features_list.feat_inventory)) ? (
              <Grid container>
                <ItemGrid xs={12} sm={12} md={12}>
                  <FormControlLabel control={
                    <Checkbox
                      className={classes.size}
                      icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                      checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
                      checked={(values.ticket_i_created_alert && (values.ticket_i_created_alert === 1 || values.ticket_i_created_alert === true)) ? true : false}
                      disabled={permFieldList.indexOf("ticket_i_created_alert") < 0 || !canEdit}
                      onChange={handleChange}
                      name="ticket_i_created_alert"
                    />
                  }
                  label="Tickets I Created"
                  />
                    <FormControlLabel control={
                      <Checkbox
                        className={classes.size}
                        icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                        checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
                        checked={(values.ticket_iam_assigned_alert && (values.ticket_iam_assigned_alert === 1 || values.ticket_iam_assigned_alert === true)) ? true : false}
                        disabled={permFieldList.indexOf("ticket_iam_assigned_alert") < 0 || !canEdit}
                        onChange={handleChange}
                        name="ticket_iam_assigned_alert"
                      />
                    }
                    label="Tickets Assigned to me"
                    />
                      <FormControlLabel control={
                        <Checkbox
                          className={classes.size}
                          icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                          checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
                          checked={(values.ticket_overdue_alert && (values.ticket_overdue_alert === 1 || values.ticket_overdue_alert === true)) ? true : false}
                          disabled={permFieldList.indexOf("ticket_overdue_alert") < 0 || !canEdit}
                          onChange={handleChange}
                          name="ticket_overdue_alert"
                        />
                      }
                      label="All Overdue Open Tickets"
                      />

                </ItemGrid>
              </Grid>) : null}

              {canEdit ? (
              <Grid container>
              <ItemGrid xs={12} sm={4} md={4}>
              <ProgressButton  color="primary"
                type="submit"
              className="form-submit"
              loadingMsg={this.state.loadingSaveMsg}
              loadingSuccessMsg={this.state.loadingSaveSuccessMsg}
              loadingFailureMsg={this.state.loadingSaveFailureMsg}
              disabled={isSubmitting}
              >
                SAVE
              </ProgressButton>
              </ItemGrid>
              <ItemGrid xs={12} sm={6} md={4}>
                <ProgressButton  color="primary"
                className="form-submit"
                user_id={values.id}
                onClick={this.resetPassword}
                loadingMsg={this.state.loadingResetPassMsg}
                loadingSuccessMsg={this.state.loadingResetPassSuccessMsg}
                loadingFailureMsg={this.state.loadingResetPassFailureMsg}
                >
                  RESET PASSWORD
                </ProgressButton>
              </ItemGrid>
              {
                (this.props.onLogout) ? (
                  <ItemGrid xs={12} sm={6} md={4}>
                    <Button  color="primary"
                    className="form-submit"
                    component={Link} to="/login"
                    onClick={this.props.onLogout}
                    >
                      LOGOUT
                    </Button>
                  </ItemGrid>

                ) : (
                  <ItemGrid xs={12} sm={2} md={4}></ItemGrid>
                )
              }
              </Grid>) : null}

              </div>
              } />
              </ItemGrid>
            </Grid>
          </form>
      </div>
      </div>
      )}/>
        </div>);

  }


}

UserForm.propTypes = {
  selectedUser: PropTypes.object,
  loggedInUser: PropTypes.object,
  loaded: PropTypes.bool,
  onSave: PropTypes.func,
  onLogout: PropTypes.func,
  onResetPassword: PropTypes.func
};

 export default withStyles(styles)(UserForm);
