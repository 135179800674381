import React from 'react';
//import pure from 'recompose/pure';
import { SvgIcon } from "@material-ui/core";

export default function IceQIcon(props) {
return (<SvgIcon {...props}>
<path  d="M 17.817315,3.4917084 10.781401,0.23628505 c -0.665089,-0.31504673 -1.7852354,-0.31504673 -2.4853193,0 L 1.2601671,3.4917084 C 0.56007315,3.8067452 0,4.6818551 0,5.4519594 L 0,13.74804 c 0,0.770094 0.56007315,1.645214 1.2601671,1.960251 l 7.0359146,3.255423 c 0.6650887,0.315047 1.7852353,0.315047 2.4853193,0 l 7.035914,-3.255423 c 0.700094,-0.315037 1.260167,-1.190157 1.260167,-1.960251 l 0,-8.2960806 c 0,-0.7701043 -0.560073,-1.6452142 -1.260167,-1.960251 z m -1.155151,3.1154025 -5.32069,2.4503138 c -0.455058,0.2100312 -0.840105,0.8051095 -0.840105,1.2951713 l 0,5.635737 c 0,0.490062 -0.420052,0.910115 -0.910125,0.910115 l -0.1050057,0 c -0.4900727,0 -0.910125,-0.420053 -0.910125,-0.910115 l 0,-5.635737 c 0,-0.4900618 -0.3850472,-1.0851401 -0.8401048,-1.2951713 L 2.4153186,6.6071109 C 1.9602511,6.3970798 1.7502298,5.8370066 1.9602511,5.381949 L 1.9952264,5.2769335 C 2.2052576,4.8218759 2.7653307,4.6118547 3.2203883,4.8218759 L 8.681099,7.34221 c 0.4550576,0.2100212 1.2251619,0.2100212 1.680219,0 l 5.460711,-2.5203341 c 0.455058,-0.2100212 1.015131,0 1.225162,0.4550576 l 0.07005,0.1050155 c 0.210021,0.4550576 0,1.0151308 -0.455067,1.2251619 z" ></path>
  </SvgIcon>)
};
//IceQIcon = pure(IceQIcon);
//IceQIcon.displayName = 'IceQIcon';
