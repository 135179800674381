import  { LineChart, Line, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceArea } from 'recharts';
import React from "react";
import PropTypes from "prop-types";

// const getAxisYDomain = (from, to, ref, offset) => {
// 	const refData = data.slice(from-1, to);
//   let [ bottom, top ] = [ refData[0][ref], refData[0][ref] ];
//   refData.forEach( d => {
//   	if ( d[ref] > top ) top = d[ref];
//     if ( d[ref] < bottom ) bottom = d[ref];
//   });
//
//   return [ (bottom|0) - offset, (top|0) + offset ]
// };

// const initialState = {
//   data: this.props.chartData,
//   left : 'dataMin',
//   right : 'dataMax',
//   refAreaLeft : '',
//   refAreaRight : '',
//   top : 'dataMax+1',
//   bottom : 'dataMin-1',
//   top2 : 'dataMax+20',
//   bottom2 : 'dataMin-20',
//   animation : true
// };

class ZoomChart extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
      data: this.props.chartData,
      left : 'dataMin',
      right : 'dataMax',
      refAreaLeft : '',
      refAreaRight : '',
      top : 'dataMax+1',
      bottom : 'dataMin-1',
      top2 : 'dataMax+20',
      bottom2 : 'dataMin-20',
      animation : true
    };
  }

  zoom(){
  	let { refAreaLeft, refAreaRight, data } = this.state;

		if ( refAreaLeft === refAreaRight || refAreaRight === '' ) {
    	this.setState( () => ({
      	refAreaLeft : '',
        refAreaRight : ''
      }) );
    	return;
    }

		// xAxis domain
	  if ( refAreaLeft > refAreaRight )
    		[ refAreaLeft, refAreaRight ] = [ refAreaRight, refAreaLeft ];

		// yAxis domain

    //const [ bottom, top ] = getAxisYDomain( refAreaLeft, refAreaRight, 'cost', 1 );
    //const [ bottom2, top2 ] = getAxisYDomain( refAreaLeft, refAreaRight, 'impression', 50 );

    // this.setState( () => ({
    //   refAreaLeft : '',
    //   refAreaRight : '',
    // 	data : data.slice(),
    //   left : refAreaLeft,
    //   right : refAreaRight,
    //   bottom, top, bottom2, top2
    // } ) );
    this.setState( () => ({
      refAreaLeft : '',
      refAreaRight : '',
      data : data.slice(),
      left : refAreaLeft,
      right : refAreaRight
    } ) );

  };

	zoomOut() {
  	const { data } = this.state;
  	this.setState( () => ({
      data : data.slice(),
      refAreaLeft : '',
      refAreaRight : '',
      left : 'dataMin',
      right : 'dataMax',
      top : 'dataMax+1',
      bottom : 'dataMin'
    }) );
  }

  render() {
    const { data, left, right, refAreaLeft, refAreaRight, top, bottom } = this.state;
const { yAxis, xAxis } = this.props;

if(!data || (data && data.length<=0)) { return null}
    return (
      <div className="highlight-bar-charts">
  			<ResponsiveContainer minWidth={200} minHeight={350} width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 40, right: 0, left: 0, bottom: 0 }}
            onMouseDown = { (e) => e ? this.setState({refAreaLeft:e.activeLabel}) : null }
            onMouseMove = { (e) => this.state.refAreaLeft && this.setState({refAreaRight:e.activeLabel}) }
            onClick = { (e) => this.props.onClick ? this.props.onClick(e) : null}
            onMouseUp = { this.zoom.bind( this ) }
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis
              allowDataOverflow={false}
              dataKey={xAxis['datakey']}
              domain={[left, right]}
              type={xAxis['type']}
              tickFormatter={xAxis['tickFormatter']}
            />
            { yAxis.map((yAxisInfo,i) => {
              return(
                <YAxis
								mirror={true}
                key={`yAxis${i}`}
                  allowDataOverflow={false}
                  domain={[bottom, top]}
                  type={yAxisInfo['type']}
                  yAxisId="1"
                  width={40}
                 />
              )
            })}


            <Tooltip labelFormatter={xAxis['tickFormatter']}/>
            { yAxis.map((yAxisInfo, i) => {
              return(
                <Line yAxisId="1" key={`Line${i}`} type='natural'
                  dataKey={yAxisInfo['datakey']} stroke={yAxisInfo['color']}
                  name={yAxisInfo['description']}
                  animationDuration={300}/>
              )
            })}

            {
            	(refAreaLeft && refAreaRight) ? ( <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight}  strokeOpacity={0.3} /> ) : null
            }

          </LineChart>
</ResponsiveContainer>
      </div>
    );
  }
}
ZoomChart.propTypes = {
  classes: PropTypes.object,
  chartData: PropTypes.array,
  xAxis: PropTypes.object.isRequired,
  yAxis: PropTypes.array.isRequired,
  scatterChart: PropTypes.bool,
  onClick: PropTypes.func
};

export default ZoomChart;
