import React, { Component } from 'react';
import { Typography, Slide, Dialog, AppBar,
  Toolbar } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import {
withAuth,
ProgressButton,
IconButton
} from "components";

import PropTypes from "prop-types";
import IceQFormSub from './IceQFormSub'
const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  appBar: {
    position: 'relative',
    backgroundColor: "#EEEEEE"
  },
  flex: {
    paddingLeft: '10px',
    flex: 1,
    color:  "#0b678f"
  },
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  colorPrimary: {
      backgroundColor: "#0b678f"
    },
  content: {
    flex: '1 0 auto',
  },
  avatar: {
    margin: 10,
    borderRadius: '10px'
  }
});
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const isEmpty = (obj) => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

class IceQFormDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {items: [], selectedIceq: {}}
  }

componentDidMount(){

}

addIceQ = (evnt) => {
  this.props.store.addIceQ(this.state.selectedIceq, {id: this.props.data.id});
  this.props.onClose(evnt);
}

  render() {
    const { classes, data, allStatuses, store, loaded } = this.props;
    if (!this.props.loaded) return null
    return (
      <div>
        <Dialog
          fullScreen={true}
          open={true}
          onClose={this.handleClose}
          TransitionComponent={Transition}>
          <div className="center">
            <div className="card">
              <AppBar className={classes.appBar}>
                <Toolbar>
                <IconButton color="primary" onClick={this.props.onClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="title" color="primary" className={classes.flex}>
                  Add IceQ
                </Typography>
                {(this.state.selectedIceq && !isEmpty(this.state.selectedIceq) && isEmpty(allStatuses) && !allStatuses) ? (
                  <ProgressButton color="primary" type="submit" onClick={this.addIceQ} >save</ProgressButton>
                ) : null}
                </Toolbar>
              </AppBar>
          <IceQFormSub
            data={data}
            store={store}
            loaded={loaded}
            onSelected={(item) => { this.setState({selectedIceq: item}); }}
            />
          </div>
          </div>
        </Dialog>

      </div>);
  }


}
IceQFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  store: PropTypes.object,
  loaded: PropTypes.bool,
  allStatuses: PropTypes.bool,
  onClose: PropTypes.func
}
 export default withStyles(styles)(withAuth(IceQFormDialog));
