
import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import LocationsStore from 'store/LocationStore';
import LocationMarker from './LocationMarker';
import { withAuth } from "components";
import Legend from "./Legend"

const { compose, withStateHandlers } = require("recompose");

//const CustomSkinMap = withScriptjs(
//  withGoogleMap
const CustomSkinMap = compose(
  withStateHandlers((evt) => {
  return ({
    activeMarker: -1,
    isOpen: false
  })}, {
    onToggleOpen: ({ isOpen}) => ( isOpen, activeMarker) => {
    return ({
    activeMarker: activeMarker,
      isOpen: !isOpen,
    })}
  }),
  withScriptjs,
  withGoogleMap
) ( props => {
    var markerList = [];
    var defaultMarker = { lat: 32.79850387573242, lng: -117.2470932006836 };
    let topLeftMarker = { lat: null, lng: null };
    let bottomRightMarker = { lat: null, lng: null };
    LocationsStore.locations.map((track, i) => {
      if(track.latitude && track.longitude){
        if(!(topLeftMarker.lat) || !(bottomRightMarker.lat)){
          topLeftMarker.lat = track.latitude;
          topLeftMarker.lng = track.longitude;

          bottomRightMarker.lat = track.latitude;
          bottomRightMarker.lng = track.longitude;

          defaultMarker.lat = track.latitude;
          defaultMarker.lng = track.longitude;

        }

        if(track.latitude > topLeftMarker.lat){
          topLeftMarker.lat = track.latitude;
        }
        if(track.longitude < topLeftMarker.lng){
          topLeftMarker.lng = track.longitude;
        }
        if(track.latitude < bottomRightMarker.lat){
          bottomRightMarker.lat = track.latitude;
        }
        if(track.longitude > bottomRightMarker.lng){
          bottomRightMarker.lng = track.longitude;
        }
        if (!(defaultMarker === topLeftMarker === bottomRightMarker)){
          defaultMarker.lat = ((topLeftMarker.lat - bottomRightMarker.lat) / 2) +  bottomRightMarker.lat
          defaultMarker.lng = ((topLeftMarker.lng - bottomRightMarker.lng) / 2) +  bottomRightMarker.lng
        }

        markerList.push(
          (
            (track.latitude && track.longitude) ?
              (
                <LocationMarker
                  key={track.id}
                  closeMarkers = {props.closeOtherMarkers}
                  toggleShowPage = {props.toggleShowPage}
                  onToggleOpen = {props.onToggleOpen}
                  freezerLocation = {track}
                  isOpen = {props.isOpen}
                  location = {{ lat: track.latitude, lng: track.longitude }}
                  isActiveMarker = { track.id === props.activeMarker ? true : false }
                />
              )
            : null
          )
        )
      }
      return ""
    }
    )

    return (<GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: defaultMarker.lat, lng: defaultMarker.lng }}
      defaultOptions={{
        scrollwheel: true,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
{markerList}
<Legend/>
    </GoogleMap>)}
//  )
);

function Maps({ ...props }) {
  return (
    <CustomSkinMap
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOm5J29n41YiNV5LER9uQAOX8lXsCGOXo"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100vh` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
}

export default withAuth(Maps);
