import React from "react";
import {
  withStyles,
  Typography,
  Avatar
} from "@material-ui/core";
import { List, Grid, ListItem } from "@material-ui/core";
import PropTypes from "prop-types";
import InfiniteScroll from 'react-infinite-scroller';
import  { observer } from "mobx-react";
import tasksStyle from "variables/styles/tasksStyle.jsx";
import FreezerStore from 'store/FreezerStore';
import qs from 'qs';
import Freezer from "./Freezer";
import {SignedInContext} from 'Contexts/signedin-context';
import LocationsStore from 'store/LocationStore';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
withAuth,
RegularCard,
ItemGrid,
LoaderCircle
} from "components";


const FreezersCard = observer(
class FreezersCard extends React.Component {
  state = {
    checked: this.props.checkedIndexes,
    searchCrit: this.props.location.search,
    currentFreezer: null
  };

  parseSearch = (freezerSearch) => {
    const values = qs.parse(freezerSearch, { ignoreQueryPrefix: true });
    return values.search;
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    FreezerStore.freezers = null;
    if(params && params.id) {
      FreezerStore.currentLocationId = params.id
      if(this.props.location.search) {
        FreezerStore.startSearch(FreezerStore.currentLocationId, this.parseSearch(this.props.location.search))
      } else if (!FreezerStore.freezers || (FreezerStore.freezers && FreezerStore.freezers.length === 0)) {
        FreezerStore.startSearch(FreezerStore.currentLocationId,"")
      }
    } else {
        console.log("yeah you should be there without a location id ")
      }

  }

  showFreezer = (freezer_id, idx) => {
    if(FreezerStore.currentFreezer && FreezerStore.currentFreezer.id === Number(freezer_id)){
      FreezerStore.currentFreezer = null
      this.setState({
        currentFreezer: null,
        currentIdx: -1})
    } else {
      FreezerStore.getFreezer(freezer_id, (res) =>{
        this.setState({currentFreezer: FreezerStore.currentFreezer,currentIdx: idx})
      })
    }
  }

  loadItems = (page) => {
    // LocationsStore.loadLocations(page, this.parseSearch(this.props.location.search));
    FreezerStore.loadFreezers(page);

  }

  render() {
    const { classes } = this.props;
    if(!(FreezerStore.freezers)) {
      return (
        <LoaderCircle/>
      )
    }

return (
    <div>
    <SignedInContext.Consumer>
      {({signedInUser, changeMe}) => {
          if(signedInUser) {
            var items = [];
            var detail =   (
              <Freezer
                freezer={this.state.currentFreezer}
                store={LocationsStore}
                loaded={(this.state.currentFreezer ? true : false)}
                loggedInUser={signedInUser}
              />)
            FreezerStore.freezers.map((freezer, i) => {
              var img = ""
              try {
                img = freezer.model.manufacturer === "Leer" ? require("assets/img/freezers/"+freezer.model_id+ ".jpg") :
                require("assets/img/freezers/"+freezer.model_id+ ".png")
              } catch(err) {
                img = require("assets/img/freezers/noimageavailable.svg")
              }
                items.push(
                  <div key={`divloc${freezer.id}`}>
                    <ListItem style={{paddingLeft:"0px", paddingRight:"0px"}} divider>
                    {(this.state.currentFreezer && Number(this.state.currentIdx) === i)? (
                        <Grid container direction="row" justify="space-between" alignItems="flex-start">
                          <ItemGrid xs={3} sm={2} md={1}></ItemGrid>
                          <ItemGrid xs={6} sm={8} md={10}></ItemGrid>
                          <ItemGrid xs={3} sm={2} md={1}>
                          <IconButton aria-label="Collapse" onClick={
                            (res) => {
                              this.showFreezer(
                                res.currentTarget.getAttribute('freezer_id'),
                                res.currentTarget.getAttribute('idx')
                              )
                            }
                          } freezer_id={freezer.id} idx={i} key={`location${freezer.id}`}><ExpandLess/></IconButton>
                          </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={12}>{detail}</ItemGrid>
                        </Grid>
                    ) : (
                      <Grid container>
                        <ItemGrid xs={3} sm={2} md={1}>
                          <Avatar src={img} className={classes.avatar} style={{ borderRadius: 4 }} ></Avatar>
                        </ItemGrid>
                        <ItemGrid xs={6} sm={8} md={10}>
                          <Typography variant="title" component="title" className={classes.cardSubtitle}>
                            {freezer.model.manufacturer} - {freezer.model.model} 
                          </Typography>
                          <Typography variant="caption" component="title" className={classes.cardSubtitle}>
                            Width/Height: {freezer.model.width}/{freezer.model.height} - Condition: {freezer.condition.name}
                          </Typography>
                          <Typography variant="caption" component="title" className={classes.cardSubtitle}>
                            Asset Tag: {freezer.asset_tag}
                          </Typography>
                        </ItemGrid>
                        <Grid item xs={3} sm={2} md={1}>
                        <IconButton aria-label="Details" onClick={
                          (res) => {
                            this.showFreezer(
                              res.currentTarget.getAttribute('freezer_id'),
                              res.currentTarget.getAttribute('idx')
                            )
                          }
                        } freezer_id={freezer.id} idx={i} key={`location${freezer.id}`}><ExpandMore /></IconButton>
                        </Grid>
                      </Grid>
                    )}
                    </ListItem>
                  </div>
                );
                return "";
            });
    return (<div>
        <RegularCard headerColor="iceq" cardTitle="Freezers List" cardSubtitle="" content={
          <div style={{height: "calc(100vh - 220px)",overflow:"auto"}}>
              <InfiniteScroll
                  pageStart={1}
                  loadMore={this.loadItems.bind(this)}
                  hasMore={FreezerStore.hasNextPage}
                  loader={
                    <div className="loader" key={0}> <LoaderCircle/></div>
                  }
                  useWindow={false}
              >
              <List>{items}</List>
              </InfiniteScroll>

          </div>
        }/>
        </div>)
      }
    }}
    </SignedInContext.Consumer>
    </div>
)
}
})
FreezersCard.propTypes = {
  classes: PropTypes.object.isRequired,
  tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  store: PropTypes.arrayOf(PropTypes.node)
};

export default withAuth(withStyles(tasksStyle)(FreezersCard));
