import React from "react";
import {
  withStyles,
  Typography,
  Avatar
} from "@material-ui/core";
import { AddLocation as AddIcon } from "@material-ui/icons";
import { List, Grid, ListItem } from "@material-ui/core";
import PropTypes from "prop-types";
import InfiniteScroll from 'react-infinite-scroller';
import  { observer } from "mobx-react";
import tasksStyle from "variables/styles/tasksStyle.jsx";
import LocationsStore from 'store/LocationStore';
import { Link } from "react-router-dom";
import qs from 'qs';
import {SignedInContext} from 'Contexts/signedin-context';

import {
withAuth,
RegularCard,
ItemGrid,
AlarmCard,
ButtonFab,
LoaderCircle
} from "components";



const LocationsCard = observer(
class LocationsCard extends React.Component {
  state = {
    checked: this.props.checkedIndexes,
    searchCrit: this.props.location.search
  };

  parseSearch = (locationSearch) => {
    const values = qs.parse(locationSearch, { ignoreQueryPrefix: true });
    return values;
  }

  componentDidMount() {
    if(this.props.location.search) {
      var para = this.parseSearch(this.props.location.search)
      LocationsStore.startSearch(para.search, para.order_by)
    } else if (LocationsStore.locations.length === 0) {
      LocationsStore.startSearch("", this.props.location.order_by)
    }

  }

  loadItems = (page) => {
    // LocationsStore.loadLocations(page, this.parseSearch(this.props.location.search));
    LocationsStore.loadLocations(page);
  }

  render() {
    const { classes } = this.props;
    if(LocationsStore.isLoading) {
      return (
        <LoaderCircle/>
      )
    }
    var items = [];
    var url = "/location/";
    LocationsStore.locations.map((track, i) => {
        items.push(
          <div key={`divloc${track.id}`}>
          <SignedInContext.Consumer>
          {({signedInUser, changeMe}) => {
            if(track.category_id ===1 &&
              signedInUser.plan_features_list.feat_inventory){
                url = '/warehouse/'
              } else {
                url = '/location/'
              }
            return (
            <ListItem component={Link} to={`${url}${track.id}`} key={`location${track.id}`} button divider>
                <Grid container>
                  <ItemGrid xs={3} sm={2} md={1}>
                    <Avatar className={classes.avatar}>{track.freezers.length}</Avatar>
                  </ItemGrid>
                  <ItemGrid xs={9} sm={8} md={5}>
                    <Typography variant="title" component="title"  className={classes.cardSubtitle}>
                        {track.name}
                      </Typography>
                      <Typography variant="caption" component="title"  className={classes.cardSubtitle}>
                        {track.street}, {track.city}, {track.state} {track.zip}
                      </Typography>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={2} md={6}>
                    {
                      track.freezers.map((freezer, y) => {
                      return(
                        <AlarmCard
                          key={`freezer_alarm${track.id}-${freezer.id}`}
                          hasErrors={freezer.iceq_last_reading ? false : true}
                          hasIceq={freezer.iceq_id ? true : false}
                          percentFull={freezer.iceq_last_reading ? freezer.iceq_last_reading.percent_full : -100}
                          percentAlarmLimit={27}
                          percentWarningLimit={50}
                          temperature={freezer.iceq_last_reading ? freezer.iceq_last_reading.temperature : -500}
                          tempAlarmLimit={32}
                          tempWarningLimit={29}
                          raiseTempAlert={true}
                          freezerIndex={y+1}
                        />
                      )})
                    }
                  </ItemGrid>
                </Grid>
              </ListItem>
                        )}}</SignedInContext.Consumer>
            </div>

        );
        return "";
    });


return (
    <div>



        <RegularCard headerColor="iceq" cardTitle="Locations List" cardSubtitle="" content={
          <div style={{height: "calc(100vh - 220px)",overflow:"auto"}}>

              <InfiniteScroll
                  pageStart={1}
                  loadMore={this.loadItems.bind(this)}
                  hasMore={LocationsStore.hasNextPage}
                  loader={
                    <div className="loader" key={0}> <LoaderCircle/></div>
                  }
                  useWindow={false}
              >
              <List>
                  {items}
                  </List>
              </InfiniteScroll>

          </div>
        }/>
      <ButtonFab
        color="primary"
        aria-label="add" arrayidx="-1"
        floating={true}
        className={classes.button}
        component={Link} to={`locations/add`}
        regionid="new">
        <AddIcon />
      </ButtonFab>
    </div>
)
}
})

LocationsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  store: PropTypes.arrayOf(PropTypes.node)
};

export default withAuth(withStyles(tasksStyle)(LocationsCard));
