import React, { Component, Fragment } from 'react';
import { Grid, TextField, Divider, Typography, Slide,
  Dialog, AppBar, Toolbar, Avatar } from "@material-ui/core";
import { Formik } from 'formik';
import { Close as CloseIcon, Clear } from "@material-ui/icons";
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types"
import {
  ItemGrid,
  PickDateTime,
  IconButton,
  ProgressButton,
  AsyncCustomSelect,
  TZConvert
} from "components";
import IceQUtils from 'store/IceQUtils';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import UserStore from 'store/UserStore';
import LocationStore from 'store/LocationStore';
import LuxonUtils from '@date-io/luxon';

import AssetLookup from 'views/locations/AssetLookup';

const ticketFornSchema = Yup.object().shape({
  description: Yup.string().max(2000).nullable(),
  title: Yup.string().min(1).max(250).required(),
  ticket_status_id: Yup.number().min(1).required(),
  ticket_type_id: Yup.number().min(1).required(),
  user: Yup.mixed().required("Please Select the Assignee"),
  dest_location: Yup.mixed().when('ticket_type_id', {
    is: 2,
    then: (ticketFornSchema) => ticketFornSchema.required('Please Select a Destination'),
    otherwise:  (ticketFornSchema) => ticketFornSchema.nullable()
  }),
  location: Yup.object().nullable(),
  location_id: Yup.number().min(1).nullable(),
  freezer_id: Yup.number().min(1).nullable(),
  due_at: Yup.string().max(50).nullable()
});

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  appBar: {
    position: 'relative',
    backgroundColor: "#EEEEEE"
  },
  colorPrimary: {
    backgroundColor: "#0b678f"
  },
  flex: {
    paddingLeft: '10px',
    flex: 1,
    color:  "#0b678f"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const defaultSaveMsgs = {
  loadingSaveMsg: "",
  loadingSaveSuccessMsg: "",
  oadingSaveFailureMsg: ""
}

function LabelDisplayWithAvatar(props){
  const { classes, label, sublabel, avatar, onDelete } = props;
  return (<div data-shrink="true">
    <Grid container>
    <ItemGrid xs={3} sm={2} md={1} style={{margin : "auto"}}>
      <Avatar src={avatar}  style={{ borderRadius: 4 }} ></Avatar>
    </ItemGrid>
      <ItemGrid xs={9} sm={10} md={10}>
      <Fragment>
        <label className={classes.cardTitleSmall} data-shrink="true">{label}</label>
        <Typography variant="caption" component="title" style={{color: "black", lineHeight:"50%"}}>
         {props.children}
        </Typography>
        {sublabel? (<label className={classes.cardTitleSmall} data-shrink="true">{sublabel}</label>):null}
        </Fragment>
        {onDelete ? (
          <IconButton aria-label="Clear" style={{marginLeft: "10px"}} color="white" onClick={
            (res) => {
              onDelete()
            }
          }><Clear /></IconButton>
        ): null}

      </ItemGrid>

    </Grid>

      </div>);
}

class TicketForm extends Component {

  state = { inputValue: '' };

  constructor(props) {
    super(props);
    this.state = {
      loadingSaveMsg: "",
      loadingSaveSuccessMsg: "",
      loadingSaveFailureMsg: "",
      ticketTypes:[],
      ticketStatuses: [],
      freezer: null,
      isDirty: false

    };
  }

  UserList(inputValue, callback) {
      UserStore.userRoaster(inputValue,
        (dd) => {
          callback(dd.map(opt => (
          { label: `${opt['firstname']} ${opt['lastname']}`, value: opt['id'] }
        )));
      }, null);
    }

    locationSearch(inputValue, callback) {
        LocationStore.quickSearch(inputValue,
          (dd) => {
            callback(dd.map(opt => (
            { label: `${opt['name']} ${opt['street']} ${opt['city']} ${opt['state']} ${opt['zip']}`, value: opt['id'] }
          )));
        }, null);
      }

    componentDidMount() {
    this.props.store.loadTicketStatuses(
      (res) => { this.setState({ticketStatuses: res}) }
    )
    this.props.store.loadTicketTypes(
      (res) => { this.setState({ticketTypes: res}) }
    )
  }

  handleTicket = (ticketInfo, onError) => {
    this.setState( { loadingSaveMsg: "Saving ...",
              loadingSaveSuccessMsg: "",
              loadingSaveFailureMsg: ""
            })
            if(ticketInfo.user && ticketInfo.user.value && ticketInfo.user.value > 0){
              ticketInfo.user_id = ticketInfo.user.value;
            }
            if(ticketInfo.dest_location && ticketInfo.dest_location.value && ticketInfo.dest_location.value > 0){
              ticketInfo.dest_location_id = ticketInfo.dest_location.value;
            }
            if(ticketInfo.location && ticketInfo.location.value && ticketInfo.location.value > 0){
              ticketInfo.location_id = ticketInfo.location.value;
            }
            if(ticketInfo.freezer && ticketInfo.freezer.id && ticketInfo.freezer.id > 0){
              ticketInfo.freezer_id = ticketInfo.freezer.id;
            } else if(this.props.freezer_id && this.props.freezer_id >0 ){
              ticketInfo.freezer_id = this.props.freezer_id
            }
    this.props.store.updateTicket(ticketInfo,
      (newTicket) => {
        this.setState(
          { loadingSaveMsg: "",
            loadingSaveSuccessMsg: "Saved",
            loadingSaveFailureMsg: ""
          })
        setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
        if(this.props.onSave){
          this.props.onSave(newTicket)
        }
        
      },
      (error) => {
        error.response.json().then((value) => {
          onError(value);
        })
        this.setState(
          {
            loadingSaveMsg: "",
            loadingSaveSuccessMsg: "",
            loadingSaveFailureMsg: "Error Saving"
          }
        )
        setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
      });
    }

    render() {
      const { classes, store, ticket } = this.props;

      if (!this.props.loaded) return null
      return (
        <div>
        <Dialog
        fullScreen
        open={true}
        TransitionComponent={Transition}
        >

        <Formik
        initialValues={{
          id: ticket.id,
          ticket_status_id: store.defaultToNonNull(ticket.ticket_status_id),
          ticket_type_id: ticket.ticket_type_id,
          title: store.defaultToNonNull(ticket.title),
          freezer: ticket.freezer ? ticket.freezer : {},
          location: ticket.freezer && ticket.freezer.location ? {
            value: ticket.freezer.location.id,
            label: `${ticket.freezer.location.name} ${ticket.freezer.location.street} ${ticket.freezer.location.city} ${ticket.freezer.location.state} ${ticket.freezer.location.zip}`         
          } : (ticket.location ? {
            value: ticket.location_id,
            label: `${ticket.location.name} ${ticket.location.street} ${ticket.location.city} ${ticket.location.state} ${ticket.location.zip}`
          }: null),
          description: store.defaultToNonNull(ticket.description),
          user:(ticket.user ? {value: ticket.user_id ,
            label: `${ticket.user.firstname} ${ticket.user.lastname}`}: null),
          due_at: ticket.due_at,
          freezer_id: store.defaultToNonNull(ticket.freezer_id),
          dest_location:(ticket.dest_location ? {
          value: ticket.dest_location_id ,
            label: `${ticket.dest_location.name} ${ticket.dest_location.street} ${ticket.dest_location.city} ${ticket.dest_location.state} ${ticket.dest_location.zip}`
          }: null),
          ticket_notes_attributes: store.defaultToNonNull(ticket.ticket_notes_attributes),
        }}
        onSubmit={(values, {setSubmitting, resetForm, setErrors}) => {
          
          this.handleTicket(values, setErrors)
          this.setState({isDirty: true})
          setSubmitting(false)
        }}

        validationSchema = {ticketFornSchema}

        render={({values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, setValues}) => (
            <div className="center">
            <MuiPickersUtilsProvider utils={LuxonUtils}>
            <div className="card">
            <form onSubmit={handleSubmit}>
            <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="primary" onClick={this.props.onClose} aria-label="Close">
                <CloseIcon />
                </IconButton>
                <Typography variant="title" className={classes.flex}>
                Ticket {ticket && ticket.id > 0 ? ticket.id:null}
                </Typography>
                {store.ticketDisabledOnStatus(ticket.ticket_status_id) ? null :(
                  <ProgressButton
                    className="form-submit"
                    color="primary"
                    type="submit"
                    loadingMsg={this.state.loadingSaveMsg}
                    loadingSuccessMsg={this.state.loadingSaveSuccessMsg}
                    loadingFailureMsg={this.state.loadingSaveFailureMsg}
                    disabled={isSubmitting || store.ticketDisabledOnStatus(ticket.ticket_status_id)}>SAVE
                  </ProgressButton>
                )}

                </Toolbar>
                </AppBar>
                <div>
                <Grid container>
                         <ItemGrid xs={12} sm={12} md={12}>
                           <TextField
                           id="ticket_type_id"
                           variant="outlined"
                           select
                           label="Type"
                           name="ticket_type_id"
                           disabled={store.ticketDisabledOnStatus(ticket.ticket_status_id)}
                           className={classes.textField}
                           value={values.ticket_type_id}
                           error={errors.ticket_type_id ? true : false}
                           onChange={handleChange}
                           SelectProps={{
                             native: true,
                             MenuProps: { className: classes.menu,},
                           }}
                           margin="normal"
                           > <option key="notSet" value="0">Not Set</option>
                           { this.state.ticketTypes.map((type) => (
                               <option key={"ticket_type_list_" + type.id}
                               value={type.id}>{type.description}</option>
                             ))
                           }
                           </TextField>

                         </ItemGrid>
                         </Grid>

                   <Grid container>
                    <ItemGrid xs={12} sm={12} md={12}>
                    {values.freezer  && values.freezer.id && values.freezer.id > 0 ?
                      (values.location || store.ticketDisabledOnStatus(ticket.ticket_status_id)) ? (
                        <LabelDisplayWithAvatar classes={classes}
                        sublabel={values.freezer.placement? values.freezer.placement.name : null }
                        label={`${values.freezer.condition? values.freezer.condition.name : "Asset" } ${values.freezer.asset_tag ? "Tag:" + values.freezer.asset_tag : null } `} avatar={IceQUtils.freezerIcon(values.freezer)}>
                        {values.freezer.model.manufacturer} {values.freezer.model.model}</LabelDisplayWithAvatar>
                      ) : (
                        <LabelDisplayWithAvatar
                        onDelete={()=>{
                          setFieldValue("freezer", null)
                          setFieldValue("location", null)
                          setFieldValue("freezer_id", null)
                        }}
                        classes={classes}
                        sublabel={values.freezer.placement? values.freezer.placement.name : null }
                        label={`${values.freezer.condition? values.freezer.condition.name : "Asset" } ${values.freezer.asset_tag ? "Tag:" + values.freezer.asset_tag : null } `} 
                        avatar={IceQUtils.freezerIcon(values.freezer)}>
                        {values.freezer.model.manufacturer} {values.freezer.model.model}
</LabelDisplayWithAvatar>
                      )
                     : null }
                    </ItemGrid>
                    </Grid>

                    {store.ticketDisabledOnStatus(ticket.ticket_status_id) || (values.freezer  && values.freezer.id && values.freezer.id > 0) ? null : (
                      <Grid container>
                       <ItemGrid xs={12} sm={12} md={12}>
                         <AssetLookup value={values.asset_id} classes={classes} onSuccess={(freezerUp) => {
                            setFieldValue('freezer', freezerUp)
                            setFieldValue('location', {
                              value: freezerUp.location.id ,
                              label: `${freezerUp.location.name} ${freezerUp.location.street} ${freezerUp.location.city} ${freezerUp.location.state} ${freezerUp.location.zip}`
                            })
                         }}/>
                        </ItemGrid>
                      </Grid>
                    ) }

                    <Grid container>
                    <ItemGrid xs={12} sm={12} md={12}>
                    <AsyncCustomSelect
                      formControlProps={{fullWidth: true}}
                      disabled={store.ticketDisabledOnStatus(ticket.ticket_status_id)}
                      error={ (errors != null && errors.location != null) ? true : false}
                      labelText={(errors != null && errors.location != null)? errors.location : "Current Location"}
                      inputProps={{
                        isDisabled: store.ticketDisabledOnStatus(ticket.ticket_status_id) || 
                          (values.freezer  && values.freezer.id && values.freezer.id > 0 && values.location),
                        defaultValue: values.location,
                        cacheOptions: true,
                        defaultOptions: true,
                        onInputChange: (newValue) => {
                          const inputValue = newValue.replace(/\W/g, '');
                          this.setState({ "inputValue_location":inputValue });
                          return inputValue;
                        },
                      id: "location",
                      name: "location",
                      onChange: (value) => {setFieldValue('location', value)
                    },
                      value: values.location,
                      loadOptions: this.locationSearch
                      }} />
                    </ItemGrid>
                    </Grid>


                    {((values.ticket_type_id) &&
                      Number(values.ticket_type_id) === 2 ) ? (
                        <Grid container>
                         <ItemGrid xs={12} sm={12} md={12}>
                      <AsyncCustomSelect
                        formControlProps={{fullWidth: true}}
                        disabled={store.ticketDisabledOnStatus(ticket.ticket_status_id)}
                        error={ (errors != null && errors.dest_location != null) ? true : false}
                        labelText={(errors != null && errors.dest_location != null)? errors.dest_location : "Move Asset To"}
                        inputProps={{
                          isDisabled: store.ticketDisabledOnStatus(ticket.ticket_status_id),
                          defaultValue: values.dest_location,
                          cacheOptions: true,
                          defaultOptions: true,
                          onInputChange: (newValue) => {
                            const inputValue = newValue.replace(/\W/g, '');
                            this.setState({ "inputValue":inputValue });
                            return inputValue;
                          },
                        id: "dest_location",
                        name: "dest_location",
                        onChange: value => setFieldValue('dest_location', value),
                        value: values.dest_location,
                        loadOptions: this.locationSearch
                        }} />
                      </ItemGrid>
                      </Grid>
                    ) : null }
 
                    <Grid container>
                     <ItemGrid xs={12} sm={12} md={12}>
                       <AsyncCustomSelect
                         formControlProps={{fullWidth: true}}
                         error={ (errors != null && errors.user != null)? true : false}
                         labelText={(errors != null && errors.user != null)? errors.user : "Assigned To"}
                         disabled={store.ticketDisabledOnStatus(ticket.ticket_status_id)}
                         inputProps={{
                           isDisabled: store.ticketDisabledOnStatus(ticket.ticket_status_id),
                           defaultValue: values.user,
                            cacheOptions: true,
                           defaultOptions: true,
                           onInputChange: (newValue) => {
                           const inputValue = newValue.replace(/\W/g, '');
                           this.setState({ "inputValue":inputValue });
                           return inputValue;
                         },
                         id: "user",
                         name: "user",
                         onChange: value => setFieldValue('user', value),
                         value: values.user,
                         loadOptions: this.UserList
                         }} />
                         </ItemGrid>
                         </Grid>




                         <Grid container>
                         <ItemGrid xs={12} sm={12} md={12}>
                       <TextField
                        error={errors.title ? true :false}
                        id="title"
                        label={errors.title ? errors.title :"Title"}
                        value={ values.title}
                        margin="normal"
                        name="title"
                        disabled={store.ticketDisabledOnStatus(ticket.ticket_status_id)}
                        onChange= {handleChange}
                        variant="outlined"
                        fullWidth
                      />
                      </ItemGrid>
                      </Grid>






                      <Grid container>
                       <ItemGrid xs={12} sm={12} md={12} style={{display: "flex"}}>
                          <TextField
                            error={errors.description ? true :false}
                            id="description"
                            variant="outlined"
                            label={errors.description ? errors.description :"Description"}
                            value={ values.description}
                            margin="normal"
                            name="description"
                            disabled={store.ticketDisabledOnStatus(ticket.ticket_status_id)}
                            multiline
                            rowsMax="10"
                            rows="4"
                            fullWidth
                            onChange= {handleChange}
                          />
                          </ItemGrid>
                        </Grid>
                      <Grid container>
                      <ItemGrid xs={6} sm={6} md={4}>
                        <TextField
                          id="ticket_status_id"
                          variant="outlined"
                          select
                          label="Status"
                          name="ticket_status_id"
                          disabled={store.ticketDisabledOnStatus(ticket.ticket_status_id)}
                          className={classes.textField}
                          value={values.ticket_status_id}
                          error={errors.ticket_status_id ? true : false}
                          onChange={handleChange}
                          SelectProps={{
                            native: true,
                            MenuProps: {className: classes.menu,},
                          }}
                          margin="normal"
                          > <option key="notSet" value="0">Not Set</option>
                          { store.workflow("",ticket.ticket_status_id).map((status, i) => {
                            if(status){
                              return (
                                <option key={"ticket_status_list_" + i}
                                value={status.id}>{status.description}</option>
                              )
                            } else { return null }
                          })}
                        </TextField>
                      </ItemGrid>

                      <ItemGrid xs={12} sm={12} md={4}>
                        <PickDateTime
                          name="due_at"
                          id="due_at"
                          value={values.due_at}
                          onChange={setFieldValue}
                          labelText="Due Date"
                          inputProps={{
                            disabled:store.ticketDisabledOnStatus(ticket.ticket_status_id)
                          }}
                          invalidLabel={errors.due_at}
                          invalidDateMessage={errors.due_at}
                        />
                      </ItemGrid>
                    </Grid>
                    <Divider/>
                    {ticket && ticket.id > 0 ? (
                      <div>
                      <Grid container>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <Typography variant="body1" component="title"
                            className={classes.cardSubtitle}>
                          Created at: <TZConvert utcSqlDate={ticket.created_at}/> By {ticket.updated_by.firstname} {ticket.updated_by.lastname}
                          </Typography>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <Typography variant="body1" component="title"
                            className={classes.cardSubtitle}>
                            {store.ticketDisabledOnStatus(ticket.ticket_status_id) ? (
                              <div>{store.getStatus(ticket.ticket_status_id).description} at:
                                <TZConvert utcSqlDate={ticket.updated_at}/> By {ticket.updated_by.firstname} {ticket.updated_by.lastname}
                              </div>
                            ) : (
                              <div>Updated at:
                                <TZConvert utcSqlDate={ticket.updated_at}/> By {ticket.updated_by.firstname} {ticket.updated_by.lastname}
                              </div>
                            )}

                          </Typography>
                        </ItemGrid>
                      </Grid>


                      {store.ticketDisabledOnStatus(ticket.ticket_status_id) ? null :(
                          <Grid container>
                          <ItemGrid xs={12} sm={12} md={12}>
                            <TextField
                            //  error={errors.ticket_notes_attributes.notes ? true :false}
                              id="ticket_notes_attributes.0.notes"
                              label="Add New Notes"
                              value={ values.ticket_notes_attributes.notes }
                              margin="normal"
                              disabled={store.ticketDisabledOnStatus(ticket.ticket_status_id)}
                              name="ticket_notes_attributes.0.notes"
                              multiline 
                              rowsMax="10"
                              fullWidth
                              onChange={handleChange}
                              variant="outlined"
                              />
                          </ItemGrid>
                        </Grid>
                        )}

                      {ticket.ticket_notes  && ticket.ticket_notes.length > 0 ? (
                        <Fragment>
                        <Grid container>
                          <ItemGrid xs={12} sm={12} md={12}>
                            <Typography variant="title" 
                              component="title" 
                              className={classes.cardSubtitle}>Notes ({ticket.ticket_notes.length})
                            </Typography>
                          </ItemGrid>
                        </Grid>
                        <Grid container>
                          <ItemGrid xs={12} sm={12} md={12}>
                            {this.props.ticket.ticket_notes.map((notes, i) => (
                              <Grid container key={`notes${i}`}>
                              <ItemGrid xs={3} sm={2} md={1}>
                                <Avatar>{notes.user.firstname} {notes.user.lastname}</Avatar>
                              </ItemGrid>
                              <ItemGrid xs={9} sm={10} md={11}>
                                <Typography variant="subheading" component="title" className={classes.cardSubtitle}>
                                    {notes.user.firstname} {notes.user.lastname}
                                </Typography>
                                <Typography variant="body1" component="title" className={classes.cardSubtitle}>{notes.notes}</Typography>
                                <Typography variant="caption" component="title" className={classes.cardSubtitle}>
                                  <TZConvert utcSqlDate={notes.created_at}/>
                                </Typography>
                              </ItemGrid>
                              </Grid>
                            ))
                          }
                          </ItemGrid>
                        </Grid>
                        </Fragment>
                      ) : null }
                      </div>
                    ) : null }
                  </div>
                    </form>
                    </div>
                    </MuiPickersUtilsProvider>
                    </div>
                  )}/>
                  </Dialog>
                  </div>);
              }


}
TicketForm.propTypes = {
  classes: PropTypes.object.isRequired,
  freezer_id: PropTypes.number,
  ticket: PropTypes.object,
  ticketTypeId: PropTypes.number,
  loaded: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}

 export default withStyles(styles)(TicketForm);
