import React, { Component } from 'react';
import LuxonUtils from '@date-io/luxon';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import DateTimePicker from 'material-ui-pickers/DateTimePicker';
import { withStyles, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
//import cx from "classnames";

import customDatePickStyle from "variables/styles/pickDateTimeStyle";
import PropTypes from "prop-types";

import lightBlue from '@material-ui/core/colors/lightBlue';
import { ChevronLeft, ChevronRight, Today, Schedule } from "@material-ui/icons";
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      "toolbar": {
        "backgroundColor": "#0b678f",
      },
    },
    MuiPickersCalendarHeader: {
      "switchHeader": {
        "backgroundColor": 'white',
        "color": "#0b678f",
      },
    },
    MuiPickerDTTabs: {
      "tabs": {
        "backgroundColor": "#0b678f"
      }
    },
    MuiPickersDay: {
      "day": {
        color: "#0b678f"        
      },
      "isSelected": {
        backgroundColor: "#0b678f",
      },
      "current": {
        "color": lightBlue['900'],
      },
    },
    MuiPickersClock: {
      "clock": {
        "backgroundColor": "#EEEEEE",
      },
      "pin": {
        "backgroundColor": "#0b678f",
      },
    },
    MuiPickersClockPointer: {
      "pointer": {
        "backgroundColor": "#0b678f",
      },
      "thumb": {
        backgroundColor: "#0b678f"
      }
    },
    MuiPickersClockNumber: {
      "clockNumber": {
        "color": "#0b678f",
        '&$selected': {
          backgroundColor: "#0b678f"
        }
      }
    },
    MuiDatePickersTabs: {
      "day": {
        "color": lightBlue.A700,
      },
      "isSelected": {
        "backgroundColor": lightBlue['400'],
      },
      "current": {
        "color": lightBlue['900'],
      },
    },
    MuiPickersModal: {
      "dialogAction": {
        "color": "#0b678f",
      },
    },
  },
});

class PickDateTime extends Component {

  handleDateChange = value => {
    this.props.onChange(this.props.name, value);
  };

  render() {
    const {classes, inputProps } = this.props
    return (
 <MuiThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={LuxonUtils} locale="en">
      <div className={classes.mainContainer} >
          <DateTimePicker
            variant="outlined"
            timeIcon={<Schedule/>}
            dateRangeIcon={<Today/>}
            rightArrowIcon={<ChevronRight/>}
            leftArrowIcon={<ChevronLeft/>}
            label={this.props.labelText}
            value={this.props.value}
            onChange={this.handleDateChange}
            name={this.props.name}
            autoOk={this.props.autoOk}
            format="MM/dd/yyyy hh:mm"
            invalidLabel={this.props.invalidLabel}
            invalidDateMessage={this.props.invalidDateMessage}
            margin="normal"
            {...inputProps}
          />
          </div>
      </MuiPickersUtilsProvider>
       </MuiThemeProvider>
    );
  }
}
PickDateTime.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  handleDateChange: PropTypes.func,
  inputProps: PropTypes.object,
  value: PropTypes.string,
  name: PropTypes.string,
  autoOk: PropTypes.bool,
  invalidLabel: PropTypes.string,
  invalidDateMessage: PropTypes.string
};
export default withStyles(customDatePickStyle)(PickDateTime);
