import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
Button
} from "components";

import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

class ConfirmationDialog extends Component {


  constructor(props) {
    super(props)
    if(props.dropSelection && props.dropSelectionValueDefault){
      this.state = {
        open: false,
        dropSelection: props.dropSelectionValueDefault,
      }
    } else {
      this.state = {
        open: false,
        dropSelection: '',
      }
    }

  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleClick = (idx) => {
     if(idx.currentTarget.getAttribute('val')){
       if(idx.currentTarget.getAttribute('val')==="agree"){
         this.props.onAgree(this.state.dropSelection);
       } else {
         this.props.onDisagree(this.state.dropSelection);
       }
     }
  };

  render() {
    const { isOpen, title, content, dropSelection,
      dropSelectionText } = this.props;
    return (
      <div>
      <Dialog
        open={ isOpen >= 0 }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
          {dropSelection ? (
            <form style={ {
    display: 'flex',
    flexWrap: 'wrap',
  }}>
                <FormControl  style={{minWidth: '100%'}}>
                  {dropSelectionText ? (
                    <InputLabel htmlFor="dropselection-simple">{dropSelectionText}</InputLabel>)
                  : null }

                  <Select
                    value={this.state.dropSelection }
                    onChange={this.handleChange('dropSelection')}
                    input={<Input id="dropselection-simple" />}
                  >
                    {dropSelection.map((selection, selectionIdx) => (
                      <MenuItem key={selectionIdx} value={selection.value}>{selection.desc}</MenuItem>
                    ))}
                  </Select>
              </FormControl>
            </form>
          ) : null
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClick} color="primary" val="disagree">Disagree</Button>
          <Button onClick={this.handleClick} color="primary" val="agree" autoFocus>Agree</Button>
        </DialogActions>
      </Dialog>
      </div>
    )
  }
}
ConfirmationDialog.defaultProps = {};

ConfirmationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
  content: PropTypes.node,
  isOpen: PropTypes.number,
  onDisagree: PropTypes.func,
  onAgree: PropTypes.func,
  dropSelection: PropTypes.array,
  dropSelectionValueDefault: PropTypes.string,
  dropSelectionText: PropTypes.string
};
export default withStyles(styles)(ConfirmationDialog);
