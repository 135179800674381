import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import {
  withStyles,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

import { HeaderLinks } from "components";

import sidebarStyle from "variables/styles/sidebarStyle.jsx";
import UserRegionSelection from 'views/UserProfile/user/UserRegionSelection.js'
import {SignedInContext} from 'Contexts/signedin-context';
import { Route } from "react-router-dom";
const hasPermission = (roleList, role) => {
  return (roleList.indexOf(role) >= 0)
}

const hasFeature = (featureList, userPlanFeaturesList) => {
  if(featureList){
    if(userPlanFeaturesList){
      if(featureList.findIndex( (o) => {return userPlanFeaturesList[o]}) >=0 ){
        return true
      }
    } else {
      return false;
    }
  } else {
    return true ;
  }

}
const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  const { classes, color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
    <SignedInContext.Consumer>
      {({signedInUser, changeMe}) => {
        return (routes.map((prop, key) => {
          if (prop.redirect) return null;
          if (prop.showme === false || !(signedInUser) ||
            !hasFeature(prop.features, signedInUser.plan_features_list) ||
            !hasPermission(prop.allowedRoles, signedInUser.roles)) return null;
          const listItemClasses = cx({
            [" " + classes[color]]: activeRoute(prop.path)
          });
          const whiteFontClasses = cx({
            [" " + classes.whiteFont]: activeRoute(prop.path)
          });
          return (
            <NavLink
              to={prop.path}
              className={classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                <ListItemIcon className={classes.itemIcon + whiteFontClasses}><prop.icon /></ListItemIcon>
                <ListItemText
                  primary={prop.sidebarName}
                  className={classes.itemText + whiteFontClasses}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
            );
          }))
      }}
    </SignedInContext.Consumer>
    </List>
  );
  var brand = (
  <div>
    <div className={classes.logo}>
      <div className={classes.logoLink} >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </div>
      <Route refresh={true} render={({ history}) => (
    <SignedInContext.Consumer>
      {({signedInUser, changeMe}) => {
        return (signedInUser ? (
          <div>
            <UserRegionSelection
              currentList={signedInUser.available_client_regions}
              selectedValue={signedInUser.client_region}
              onClose={(res) => {
                changeMe(res,(nUser)=>{
                  history.push('/refresh/dashboard')
                })
                
              }} />
          </div>
        ) : null)
      }}
    </SignedInContext.Consumer>
    )} />
    </div>
    </div>
  );
  return (
    <div>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <HeaderLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
