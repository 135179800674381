import React from "react";
import { withStyles, Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from "prop-types";
import cx from "classnames";
import { AddAlert } from "@material-ui/icons";
import { Snackbar } from "components";

import buttonStyle from "variables/styles/buttonStyle";

function ProgressButton({ ...props }) {
  const {
    classes,
    color,
    round,
    children,
    fullWidth,
    disabled,
    loadingMsg,
    floating,
    loadingSuccessMsg,
    loadingFailureMsg,
    ...rest
  } = props;
  const btnClasses = cx({
    [classes[((loadingSuccessMsg) ? "success" : (loadingFailureMsg ? "danger" : color))]]:  "success",//(loadingSuccessMsg) ? "success" : (loadingFailureMsg ? "danger" : color) ,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: (loadingMsg && !loadingSuccessMsg && !loadingFailureMsg) ? true : disabled
  });
  return (
  <div>
    <Button {...rest} className={(floating ? classes.buttonFloating : classes.button) + " " + btnClasses}>
    {
      loadingSuccessMsg ? (loadingSuccessMsg)
      : loadingFailureMsg ? (loadingFailureMsg): (children)
    }
    {
      (loadingMsg && ! loadingSuccessMsg && !loadingFailureMsg)? ( <CircularProgress size={24} className={classes.progress}/>)
      : null
    }

    </Button>
    <Snackbar
      place="tc"
      color={((loadingSuccessMsg) ? "success" : (loadingFailureMsg ? "danger" : color))}
      icon={AddAlert}
      message={
        loadingSuccessMsg ? (loadingSuccessMsg)
        : loadingFailureMsg ? (loadingFailureMsg): ""
      }
      open={loadingSuccessMsg || loadingFailureMsg ? true : false}
    />
    </div>
  );
}

ProgressButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "simple",
    "transparent",
    "iceq"
  ]),
  round: PropTypes.bool,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  loadingMsg: PropTypes.string,
  floating: PropTypes.bool,
  loadingSuccessMsg: PropTypes.string,
  loadingFailureMsg: PropTypes.string,
  notificationPosition: PropTypes.bool
};

export default withStyles(buttonStyle)(ProgressButton);
