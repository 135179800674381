import React, { Component } from 'react';
import SupportDataStore from 'store/SupportDataStore';
import { withAuth } from "components";
import Dashboard from "views/Dashboard/Dashboard.jsx";

class DashboardShell extends Component {

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     loaded: false
  //   }
  // }


  componentDidMount() {
 }

  render() {
    return (
      <div><Dashboard dashStore={SupportDataStore}/></div>
    );
  }
}

 export default withAuth(DashboardShell);
