// /*global google*/

import IceQIcon from "components/Icons/IceQIcon.js";
import {
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery90,
  BatteryAlert,
  BatteryFull,
  Whatshot,
  Home,
  PhonelinkErase as NoContact,
  LocationOff as LocationOnly,
  AccessTime as NoRecentComm
 } from "@material-ui/icons";
import { DateTime } from 'luxon';

const AccessTimeSvg = `data:image/svg+xml;utf-8,
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" ></path>
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
  </svg>`
const NoContactSvg = `data:image/svg+xml;utf-8,
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0V0z"/>
    <path d="M13 8.2l-1-1-4 4-4-4-1 1 4 4-4 4 1 1 4-4 4 4 1-1-4-4 4-4zM19 1H9c-1.1 0-2 .9-2 2v3h2V4h10v16H9v-2H7v3c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2z"/>
  </svg>`
const IceQIconSvg = `data:image/svg+xml;utf-8,
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path  d="M 17.817315,3.4917084 10.781401,0.23628505 c -0.665089,-0.31504673 -1.7852354,-0.31504673 -2.4853193,0 L 1.2601671,3.4917084 C 0.56007315,3.8067452 0,4.6818551 0,5.4519594 L 0,13.74804 c 0,0.770094 0.56007315,1.645214 1.2601671,1.960251 l 7.0359146,3.255423 c 0.6650887,0.315047 1.7852353,0.315047 2.4853193,0 l 7.035914,-3.255423 c 0.700094,-0.315037 1.260167,-1.190157 1.260167,-1.960251 l 0,-8.2960806 c 0,-0.7701043 -0.560073,-1.6452142 -1.260167,-1.960251 z m -1.155151,3.1154025 -5.32069,2.4503138 c -0.455058,0.2100312 -0.840105,0.8051095 -0.840105,1.2951713 l 0,5.635737 c 0,0.490062 -0.420052,0.910115 -0.910125,0.910115 l -0.1050057,0 c -0.4900727,0 -0.910125,-0.420053 -0.910125,-0.910115 l 0,-5.635737 c 0,-0.4900618 -0.3850472,-1.0851401 -0.8401048,-1.2951713 L 2.4153186,6.6071109 C 1.9602511,6.3970798 1.7502298,5.8370066 1.9602511,5.381949 L 1.9952264,5.2769335 C 2.2052576,4.8218759 2.7653307,4.6118547 3.2203883,4.8218759 L 8.681099,7.34221 c 0.4550576,0.2100212 1.2251619,0.2100212 1.680219,0 l 5.460711,-2.5203341 c 0.455058,-0.2100212 1.015131,0 1.225162,0.4550576 l 0.07005,0.1050155 c 0.210021,0.4550576 0,1.0151308 -0.455067,1.2251619 z" ></path>
  </svg>`

const WhatshotSvg =  `data:image/svg+xml;utf-8,
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z"/>
    <path d="M0 0h24v24H0z" fill="none"/>
</svg>`
const LocationOffSvg =  `data:image/svg+xml;utf-8,
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0zm11.75 11.47l-.11-.11z" fill="none"/>
    <path d="M12 6.5c1.38 0 2.5 1.12 2.5 2.5 0 .74-.33 1.39-.83 1.85l3.63 3.63c.98-1.86 1.7-3.8 1.7-5.48 0-3.87-3.13-7-7-7-1.98 0-3.76.83-5.04 2.15l3.19 3.19c.46-.52 1.11-.84 1.85-.84zm4.37 9.6l-4.63-4.63-.11-.11L3.27 3 2 4.27l3.18 3.18C5.07 7.95 5 8.47 5 9c0 5.25 7 13 7 13s1.67-1.85 3.38-4.35L18.73 21 20 19.73l-3.63-3.63z"/>
</svg>`

const Battery60Svg =  `data:image/svg+xml;utf-8,
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path fill-opacity=".3" d="M17 5.33C17 4.6 16.4 4 15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33V11h10V5.33z"/>
    <path d="M7 11v9.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V11H7z"/>
</svg>`
const Battery50Svg =  `data:image/svg+xml;utf-8,
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path fill-opacity=".3" d="M17 5.33C17 4.6 16.4 4 15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33V13h10V5.33z"/>
    <path d="M7 13v7.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V13H7z"/>
</svg>`

const Battery90Svg =  `data:image/svg+xml;utf-8,
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path fill-opacity=".3" d="M17 5.33C17 4.6 16.4 4 15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33V8h10V5.33z"/>
    <path d="M7 8v12.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V8H7z"/>
</svg>`

const Battery20Svg =  `data:image/svg+xml;utf-8,
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M7 17v3.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V17H7z"/>
    <path fill-opacity=".3" d="M17 5.33C17 4.6 16.4 4 15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33V17h10V5.33z"/>
</svg>`

const BatteryFullSvg =  `data:image/svg+xml;utf-8,
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33v15.33C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V5.33C17 4.6 16.4 4 15.67 4z"/>
</svg>`

// const Battery80Svg =  `data:image/svg+xml;utf-8,
// <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
//     <path d="M0 0h24v24H0z" fill="none"/>
//     <path fill-opacity=".3" d="M17 5.33C17 4.6 16.4 4 15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33V9h10V5.33z"/>
//     <path d="M7 9v11.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V9H7z"/>
// </svg>`

const BatteryAlertSvg =  `data:image/svg+xml;utf-8,
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33v15.33C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V5.33C17 4.6 16.4 4 15.67 4zM13 18h-2v-2h2v2zm0-4h-2V9h2v5z"/>
</svg>`

const Battery30Svg =  `data:image/svg+xml;utf-8,
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path fill-opacity=".3" d="M17 5.33C17 4.6 16.4 4 15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33V15h10V5.33z"/>
    <path d="M7 15v5.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V15H7z"/>
</svg>`

const HomeSvg =  `data:image/svg+xml;utf-8,
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
    <path d="M0 0h24v24H0z" fill="none"/>
</svg>`

const colorList = [
  "warning",
  "primary",
  "danger",
  "success",
  "info",
  "rose",
  "gray"
]

const PercentFullIconList = {
  0: BatteryAlert,
  1: BatteryAlert,
  2: Battery20,
  3: Battery30,
  4: Battery30,
  5: Battery50,
  6: Battery60,
  7: Battery60,
  8: Battery90,
  9: BatteryFull,
  10:BatteryFull
  }

  const iconList = {
    Temperature: {
      react: Whatshot,
      valueBased: false,
      map: WhatshotSvg,
      rank: 2,
      range: "30|warning|34|danger|100"
    },
    Time: {
      react: NoRecentComm,
      valueBased: false,
      map: AccessTimeSvg,
      rank: 1,
      range: "-10000000|danger|-10|warning|-4|success|4|warning|10|danger|100000000"
    },
    Fullness: {react: PercentFullIconList, valueBased: true, map: {
      0: BatteryAlertSvg,
      1: BatteryAlertSvg,
      2: Battery20Svg,
      3: Battery30Svg,
      4: Battery30Svg,
      5: Battery50Svg,
      6: Battery60Svg,
      7: Battery60Svg,
      8: Battery90Svg,
      9: BatteryFullSvg,
      10:BatteryFullSvg
    }, rank: 0, range: "0|danger|25|warning|50|success|200"},
    IceQ: {
      react: IceQIcon,
      valueBased: false,
      map: IceQIconSvg,
      rank: 4,
      range: "0|warning|10"
    },
    SiteOnly: {
      react: LocationOnly,
      valueBased: false,
      map: LocationOffSvg,
      rank: 5,
      range: "0|warning|10"
    },
    NoComm: {
      react: NoContact,
      valueBased: false,
      map: NoContactSvg,
      rank: 3,
      range: "-1000|warning|1000"
    },
    Home: {
      react: Home,
      valueBased: false,
      map: HomeSvg,
      rank: 6,
      range: "-1000|success|1000"
    }
  }

const between = (x, rangeA, rangeB) => {
  if(rangeA < rangeB) {
    return (x >= rangeA && x <= rangeB)
  } else {
    return (x <= rangeA && x >= rangeB)
  }
}

const getBestRank = (iconDataA,iconDataB) => {
  let alarmsRanks = ["warning","danger"]
  if(!iconDataA) { return iconDataB}
  if(!iconDataB) { return iconDataA}
  if(iconDataA.rank <=2 && iconDataB.rank > 2){ return iconDataA }
  if(iconDataB.rank <=2 && iconDataA.rank > 2){ return iconDataB }
  if(iconDataA.rank <=2 && iconDataB.rank <=2 &&
    alarmsRanks.indexOf(iconDataA.color) > alarmsRanks.indexOf(iconDataB.color)) {
  return iconDataA
  }
  if(iconDataA.rank <=2 && iconDataB.rank <=2 &&
    alarmsRanks.indexOf(iconDataB.color) > alarmsRanks.indexOf(iconDataA.color)) {
  return iconDataB
  }
  if(iconDataA.rank <= iconDataB.rank){
    return iconDataA
  } else {
    return iconDataB
  }
}

class AlarmGlobal {

colorFromIconDefault = (x, iconStyle) => {
  return this.colorFromRange(x, iconList[iconStyle].range)
}

colorFromRange = (x, stringRange) => {
    let ar = stringRange.split("|")
    if( ar.length % 2 !== 1 && ar.length >=3) {
      throw new Error("invalid array count " + ar.length)
    }
    let count = (ar.length-1)/2
    let rangeA = 0
    let rangeB = 0
    let i = 0
    for (i = 0; i < count; i++) {
      rangeA = Number.parseInt(ar[(i*2)+0], 10)
      rangeB = Number.parseInt(ar[(i*2)+2], 10)
      if(isNaN(rangeA) || isNaN(rangeB) || !colorList.includes(ar[(i*2)+1])) {
        throw new Error("Parameter is not valid! " + rangeA + "-" + rangeB +
          " "+ ar[(i*2)+1]  + "-" + isNaN(rangeA) + "-" + isNaN(rangeB) +
          "-" + !colorList.includes(ar[(i*2)+1]))
      }
      if(between(x,rangeA, rangeB)){ return ar[(i*2)+1] }
    }
    return "gray"
  }


  getLocationAlarms(freezers) {

    let currentIconData = {color: "rose", rank: 5, iconStyle: "SiteOnly", val: 5, overall: 0}

    let sumOfPercentFull = 0
    let countOfPercentFull = 0
    if(freezers){
      for(let freezer of freezers){
        if(freezer.iceq_id){
          if(freezer.iceq_last_reading){
            sumOfPercentFull += freezer.iceq_last_reading.percent_full
            countOfPercentFull += 1
            let tDiff = DateTime.fromISO(freezer.iceq_last_reading.callin_time,
              {zone: "UTC"}).diffNow().values.milliseconds/60000/60
            let timeColor = this.colorFromRange(tDiff, iconList["Time"].range)
            currentIconData = getBestRank(currentIconData,{color: timeColor, rank: 1, iconStyle: "Time",val: tDiff})
            let tempColor = this.colorFromRange(freezer.iceq_last_reading.temperature, iconList["Temperature"].range)
            currentIconData = getBestRank(currentIconData,{color: tempColor, rank: 2, iconStyle: "Temperature", val: freezer.iceq_last_reading.temperature})
            let fullColor = this.colorFromRange(freezer.iceq_last_reading.percent_full, iconList["Fullness"].range)
            currentIconData = getBestRank(currentIconData,{color: fullColor, rank: 0, iconStyle: "Fullness", val: freezer.iceq_last_reading.percent_full})
          }else {
          // add no comm
            currentIconData = getBestRank(currentIconData,{color: "gray", rank: 3, iconStyle: "NoComm", val: 5})
          }
        }else {
          // no cieq
          currentIconData = getBestRank(currentIconData,{color: "gray", rank: 4, iconStyle: "IceQ", val: 5})
        }
      }
    }
    currentIconData['overall'] = sumOfPercentFull/(countOfPercentFull === 0 ? 1 : countOfPercentFull)
    return currentIconData
  }
  getIcon(svgOnly, iconStyle, currentValue){
    return this.getFunky(svgOnly, iconStyle, currentValue, "success")
  }

  processSvgColor(iconSvg, color){
    let svgColor = {
      warning: "orange",
      primary: "blue",
      danger: "red",
      success: "green",
      info: "green",
      rose: "rose",
      gray: "gray"
    }
    return (iconSvg.split("<svg")[0] + "<svg fill=\"" + svgColor[color] + "\" stroke=\"" + svgColor[color] + "\" stroke-width=\"0\"" +  iconSvg.split("<svg")[1])
  }

  getWarehouse(){
      return {color: "success", rank: 6, iconStyle: "Home", val: 5}
  }

  getFunky(svgOnly, iconStyle, currentValue, color) {
    var val = iconList[iconStyle]
    if(val.valueBased) {
      if(svgOnly) {
        return this.processSvgColor(val.map[Math.round(currentValue/10)], color)
      } else {
        return val.react[Math.round(currentValue/10)]
      }
    } else {
      if(svgOnly) {
        return this.processSvgColor(val.map, color)
      } else {
        return val.react
      }
    }
  }
}

var alarmGlobal = window.store = new AlarmGlobal();
export default alarmGlobal
