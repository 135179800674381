import React, { Fragment } from "react";
import {
  withStyles,
  Typography,
  Avatar
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import  { observer } from "mobx-react";
import TicketStore from 'store/TicketStore';
import CommentIcon from '@material-ui/icons/Comment';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Chip from '@material-ui/core/Chip';
import IceQUtils from 'store/IceQUtils';
import { withAuth, ItemGrid, TZConvert } from "components";
import { defaultFont, grayColor} from "variables/styles";



const styles = theme => ({
  root: {
    width: '100%',
  },
  cardTitleSmall: {
    fontSize: "85%",
    fontWeight: "400",
    lineHeight: "0",
    color: "#777"
  },  cardCategory: {
      marginBottom: "0",
      color: grayColor,
      margin: "0 0 10px",
      ...defaultFont
    },
    cardTitle: {
      margin: "0",
      ...defaultFont,
      fontSize: "1.625em"
    },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  appBar: {
    position: 'relative',
    backgroundColor: "#EEEEEE"
  },
  colorPrimary: {
    backgroundColor: "#0b678f"
  },
  chip: {
    margin: '5px',
    fontSize: "75%",
    height: 'unset'
  },
  flex: {
    paddingLeft: '10px',
    flex: 1,
    color:  "#0b678f"
  }
});

function LabelDisplay(props){
  const { classes, label, sublabel } = props;
  return (<div data-shrink="true">
      <label className={classes.cardTitleSmall} data-shrink="true">{label}</label>
      <Typography variant="caption" component="title" style={{color: "black", lineHeight:"50%"}}>
         {props.children}
      </Typography>
      {sublabel? (<label className={classes.cardTitleSmall} data-shrink="true">{sublabel}</label>):null}
      </div>);
}
function LabelDisplayWithAvatar(props, onRemove){
  const { classes, label, sublabel, avatar } = props;
  return (<div data-shrink="true">
    <Grid container>
      <ItemGrid xs={7} sm={8} md={8}>
      <Fragment>    <label className={classes.cardTitleSmall} data-shrink="true">{label}</label>
          <Typography variant="caption" component="title" style={{color: "black", lineHeight:"50%"}}>
             {props.children}
          </Typography>
          {sublabel? (<label className={classes.cardTitleSmall} data-shrink="true">{sublabel}</label>):null}</Fragment>
      </ItemGrid>
      <ItemGrid xs={5} sm={4} md={4} style={{margin : "auto"}}>
        <Avatar src={avatar} className={classes.avatar} style={{ borderRadius: 4 }} ></Avatar>
      </ItemGrid>
    </Grid>
      </div>);
}

function LabelAddress(props){
  const { classes, label, address } = props;
  if(!address)
    return null;
  return (<Fragment>
      {label? (<label className={classes.cardTitleSmall} data-shrink="true">{label} </label>):null}
      <small className={classes.cardCategory} data-shrink="true">{address.name} {address.number} {address.street} {address.city} {address.state} {address.zip}</small>
      </Fragment>);
}

const Ticket = observer(
class Ticket extends React.Component {
  state = {
  };

  
  render() {
    const { classes, ticket, onClickTicket } = this.props;
    var items = null;
    items = (
             <Grid container style={{color: "#0B678F", backgroundColor: "#EEEEEE",borderWidth: "1px 1px 1px 1px",borderStyle: "solid", borderRadius: "8px 8px 8px 8px"}}>
               <ItemGrid xs={3} sm={2} md={1} style={{color: "#0B678F", backgroundColor: "#FFFFFF", borderWidth: "0px 0px 0px 0px", borderStyle: "solid", borderRadius: "8px 0px 0px 0px"}}>

                 <Typography variant="caption" component="title"  className={classes.cardSubtitle}>{ticket.id}</Typography>
                 <IconButton onClick={(res)=>{ onClickTicket(res.currentTarget.getAttribute('ticket_id')) }} aria-label="Comments" ticket_id={ticket.id}><CommentIcon /></IconButton>

               </ItemGrid>
               <ItemGrid xs={9} sm={10} md={11} style={{color: "#0B678F", backgroundColor: "#FAFAFA", borderWidth: "0px 0px 0px 0px",borderStyle: "solid", borderRadius: "0px 8px 0px 0px"}}>
                 <Grid container>
                   <ItemGrid xs={12} sm={12} md={12}>
                     <Typography variant="body1" component="title" className={classes.cardSubtitle}>
                     <Chip label={`${ticket.ticket_status.description}-${ticket.ticket_type.description}`} className={classes.chip} style={{color: "#FFFFFF", backgroundColor: TicketStore.getStatusColor(ticket.ticket_status_id)}}/>{ticket.title}</Typography>
                    </ItemGrid>
                   <Hidden xsDown>
                     <ItemGrid xs={12} sm={12} md={12}>
                       <Typography variant="caption" component="title" className={classes.cardSubtitle}>{ticket.description}</Typography>
                     </ItemGrid>
                   </Hidden>

                   <Hidden smDown>
                   {(TicketStore.moveTicketTypeId() === ticket.ticket_type_id && ticket.dest_location) ? (
                      <Grid container  style={{backgroundColor: "#FAFAFA"}}>
                                    <ItemGrid xs={6} sm={6} md={6}>
                                    <LabelAddress classes={classes} label="From" address={ticket.location}/>
                                    </ItemGrid>
                                    <ItemGrid xs={6} sm={6} md={6}>
                                    <LabelAddress classes={classes} label="Move To" address={ticket.dest_location}/>
                                    </ItemGrid>
                                    </Grid>
                  ) : (
                    <ItemGrid xs={12} sm={12} md={12} style={{backgroundColor: "#FAFAFA"}}>
                      <Typography variant="caption" className={classes.cardSubtitle}>
                      <LabelAddress classes={classes} label="Located:" address={ticket.location}/>
                      </Typography>
                    </ItemGrid>
                  )}
                  </Hidden>
                 </Grid>
               </ItemGrid>
               <Hidden mdUp>
               {(TicketStore.moveTicketTypeId() === ticket.ticket_type_id && ticket.dest_location) ? (
                  <Grid container  style={{backgroundColor: "#FAFAFA"}}>
                                <ItemGrid xs={6} sm={6} md={6}>
                                <LabelAddress classes={classes} label="From" address={ticket.location}/>
                                </ItemGrid>
                                <ItemGrid xs={6} sm={6} md={6}>
                                <LabelAddress classes={classes} label="Move To" address={ticket.dest_location}/>
                                </ItemGrid>
                                </Grid>
              ) : (<ItemGrid xs={12} sm={12} md={12} style={{backgroundColor: "#FAFAFA"}}>
                <Typography variant="caption" className={classes.cardSubtitle}>
                  <LabelAddress classes={classes} label="Located:" address={ticket.location}/>
                </Typography>
              </ItemGrid>)}
              </Hidden>

          <ItemGrid xs={12} sm={12} md={12} style={{color: "#0B678F", borderWidth: "1px 0px 0px 0px", borderStyle: "solid"}}>
            <Grid container>
            <Hidden xsDown>
              <ItemGrid xs={12} sm={6} md={3} style={{color: "#0B678F", borderWidth: "0px 1px 0px 0px", borderStyle: "solid"}}>
               {ticket.freezer ? (
                 <div>
                 <LabelDisplayWithAvatar classes={classes} sublabel={ticket.freezer.placement? ticket.freezer.placement.name : null } label="Asset" avatar={IceQUtils.freezerIcon(ticket.freezer)}>
                 {ticket.freezer.model.manufacturer} {ticket.freezer.model.model}</LabelDisplayWithAvatar>

                </div>
               ) :(
                    <LabelDisplay classes={classes} sublabel="" label="Asset">No Asset</LabelDisplay>
               )}
              </ItemGrid>
              <ItemGrid xs={12} sm={6} md={3} style={{color: "#0B678F", borderWidth: "0px 1px 0px 0px", borderStyle: "solid"}}>
              <LabelDisplay classes={classes} sublabel={(<TZConvert format="DATETIME_SHORT" utcSqlDate={ticket.created_at}/>)} label="Created">{ticket.created_by.firstname} {ticket.created_by.lastname}</LabelDisplay>
              </ItemGrid>
              <ItemGrid xs={12} sm={6} md={3} style={{color: "#0B678F", borderWidth: "0px 1px 0px 0px", borderStyle: "solid"}}>
                <LabelDisplay classes={classes} sublabel={(<TZConvert format="DATETIME_SHORT" utcSqlDate={ticket.due_at}/>)} label="Due At/By">{ticket.user.firstname} {ticket.user.lastname}</LabelDisplay>
              </ItemGrid>
              <ItemGrid xs={12} sm={6} md={3}>
                <LabelDisplay classes={classes}
                  label={TicketStore.ticketDisabledOnStatus(ticket.ticket_status_id) ? `${ticket.ticket_status.description} By/At`: "Updated By/At" }
                  sublabel={(<TZConvert format="DATETIME_SHORT" utcSqlDate={ticket.updated_at}/>)}>
                    {ticket.updated_by.firstname} {ticket.updated_by.lastname}
                </LabelDisplay>
              </ItemGrid>
            </Hidden>

            <Hidden smUp>
              <ItemGrid xs={12} sm={4} md={4} style={{color: "#0B678F", borderWidth: "0px 0px 1px 0px", borderStyle: "dotted"}}>
                <LabelDisplay classes={classes} sublabel={(<TZConvert format="DATETIME_SHORT" utcSqlDate={ticket.created_at}/>)} label="Created">{ticket.created_by.firstname} {ticket.created_by.lastname}</LabelDisplay>
              </ItemGrid>
              <ItemGrid xs={12} sm={4} md={4} style={{color: "#0B678F", borderWidth: "0px 0px 1px 0px", borderStyle: "dotted"}}>
                <LabelDisplay classes={classes} sublabel={(<TZConvert format="DATETIME_SHORT" utcSqlDate={ticket.due_at}/>)} label="Due At/By">{ticket.user.firstname} {ticket.user.lastname}</LabelDisplay>
              </ItemGrid>
              <ItemGrid xs={12} sm={4} md={4}>
                <LabelDisplay classes={classes}
                  label={TicketStore.ticketDisabledOnStatus(ticket.ticket_status_id) ? `${ticket.ticket_status.description} By/At`: "Updated By/At" }
                  sublabel={(<TZConvert format="DATETIME_SHORT" utcSqlDate={ticket.updated_at}/>)}>
                    {ticket.updated_by.firstname} {ticket.updated_by.lastname}
                </LabelDisplay>
              </ItemGrid>
            </Hidden>
            </Grid>
         </ItemGrid>
       </Grid>
  );
return items
}
})


//displayFormat: at-freezer, at-location, ticketList
Ticket.propTypes = {
  classes: PropTypes.object.isRequired,
  tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  store: PropTypes.object,
  displayFormat: PropTypes.string,
  ticket: PropTypes.object,
  onClickTicket: PropTypes.func
};

export default withAuth(withStyles(styles)(Ticket));
