/*eslint-disable no-unused-vars*/
/*global google*/
import React from "react";
import { Marker, InfoWindow } from "react-google-maps";
import PropTypes from "prop-types";

import { Grid, Typography, Divider } from "@material-ui/core";
import { DateTime } from 'luxon';

import { ItemGrid, AlarmIndicator, AlarmGlobal, Button } from "components";
import { Link } from "react-router-dom";
export default class LocationMarker extends React.Component {

  state = {
    isOpen: false,
//    activeMarker: this.props.activeMarker
  }

  toggleOpen = () => {
      this.props.onToggleOpen(this.props.isOpen,this.props.freezerLocation.id)
  }

  renderIcon = () => {

  }

  render(){
    let locationIconDef = (this.props.freezerLocation.category_id === 1 ?
      AlarmGlobal.getWarehouse() :
      AlarmGlobal.getLocationAlarms(this.props.freezerLocation.freezers)

    )
    return(
      <div>
        <Marker
          onClick={this.toggleOpen}
          position={this.props.location}
          icon={AlarmGlobal.getFunky(true, locationIconDef.iconStyle, locationIconDef.val, locationIconDef.color)}
        >
        { this.props.isOpen && this.props.isActiveMarker ?
          <InfoWindow maxWidth={800}
            defaultPosition={this.props.location}
            onCloseClick={this.toggleOpen}>
            <div>
            <Grid>
             <ItemGrid xs={12} sm={12} md={12}>

             <Typography variant="subheading" component="title">
                 {this.props.freezerLocation.name}
               </Typography>
               <Typography variant="caption" component="title">
                 {this.props.freezerLocation.street}, {this.props.freezerLocation.city}
               </Typography>
             </ItemGrid>
             <ItemGrid xs={12} sm={12} md={12}>
               <Typography variant="caption" component="title">
                {this.props.freezerLocation.state} {this.props.freezerLocation.zip}
               </Typography>
             </ItemGrid>
             <ItemGrid xs={12} sm={12} md={12}>
               <Typography variant="caption" component="title">
                Overall: {locationIconDef.overall}<small>%</small>
               </Typography>
             </ItemGrid>
             </Grid>
             <Divider/>
             {(this.props.freezerLocation.freezers &&
               this.props.freezerLocation.freezers.length ===0) ||
               !this.props.freezerLocation.freezers  ? (
                 <Grid>
                  <ItemGrid xs={12} sm={12} md={12}>
                  <AlarmIndicator
                   currentValue="5"
                   colorRange="0|warning|10"
                   iconStyle="SiteOnly"
                   iconSize="large"
                  />
                  <Typography variant="body1" component="title">
                    This location has NO Freezers.
                  </Typography>
                  </ItemGrid>
                  </Grid>
             ) : null }
            {this.props.freezerLocation.freezers.map((freezer, i) => {
             return (
               <Grid key={`mrkFrz${i}`}>
                <ItemGrid xs={12} sm={12} md={12}>
                  {i+1}.
                  {freezer.placement ? ( freezer.placement.name ) :( "Freezer") }:
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={12}>
                <div style={{display: "inline-flex"}}>
                  {freezer.iceq_last_reading? (
                    <div style={{display: "inherit"}}>
                    <AlarmIndicator
                     currentValue={DateTime.fromISO(freezer.iceq_last_reading.callin_time,
                       {zone: "UTC"}).diffNow().values.milliseconds/60000/60}
                     iconStyle="Time"
                     iconSize="small"
                    /><div>{Number.parseInt(DateTime.fromISO(freezer.iceq_last_reading.callin_time,
                      {zone: "UTC"}).diffNow().values.milliseconds/60000/-60,10)}hrs  </div></div>) : null}
                    {freezer.iceq_last_reading && freezer.iceq_last_reading.temperature >= 0 ? (
                    <div style={{display: "inherit"}}>
                      <AlarmIndicator
                     currentValue={freezer.iceq_last_reading.temperature}
                     iconStyle="Temperature"
                     iconSize="small"
                    /><div>{freezer.iceq_last_reading.temperature}F</div></div>
                   ) : null}
                   {freezer.iceq_last_reading && freezer.iceq_last_reading.percent_full >= 0 ? (
                     <div style={{display: "inherit"}}>
                     <AlarmIndicator
                      currentValue={freezer.iceq_last_reading.percent_full}
                      iconStyle="Fullness"
                      iconSize="small"
                     /><div>{freezer.iceq_last_reading.percent_full}%</div></div>) : null}
                    {(freezer.iceq_last_reading && 
                    freezer.iceq_last_reading.percent_full_left >= 0 && 
                    freezer.iceq_last_reading.percent_full_right >= 0) ? (
                     <div style={{display: "inherit"}}>
                     <div>[{freezer.iceq_last_reading.percent_full_left},{freezer.iceq_last_reading.percent_full_right}]%</div></div>
                     ) : null}

                  {!freezer.iceq_last_reading && freezer.iceq_id ? (
                    <AlarmIndicator
                     currentValue="5"
                     iconStyle="NoComm"
                     iconSize="small"
                    />) : null}
                    {!freezer.iceq_last_reading && !freezer.iceq_id ? (
                      <div>
                      <AlarmIndicator
                       currentValue="5"
                       iconStyle="IceQ"
                       iconSize="small"
                      /> No IceQ setup
                    </div>) : null}
                    </div>
                  </ItemGrid>
               </Grid>
             );
            })}
            <Grid container>
              <ItemGrid xs={12} sm={12} md={12}>
            <Button  color="primary"
            className="form-submit"
            component={Link} to={`/location/${this.props.freezerLocation.id}`}>
              GO TO LOCATION
            </Button>
            </ItemGrid>
         </Grid>

            </div>
          </InfoWindow> : null
        }
        </Marker>
      </div>
    )

  }
}

LocationMarker.propTypes = {
  classes: PropTypes.object,
  percentFull: PropTypes.node,
  percentWarningLimit: PropTypes.node,
  percentAlarmLimit: PropTypes.node,
  temperature: PropTypes.node,
  position: PropTypes.node,
  onClick: PropTypes.func,
  freezerLocation: PropTypes.object,
  tempAlarmLimit: PropTypes.node,
  tempWarningLimit: PropTypes.node,
  raiseTempAlert: PropTypes.node,
  closeMarkers:  PropTypes.func,
  onToggleOpen:  PropTypes.func,
  isActiveMarker:  PropTypes.bool,
  statIconColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ])
};
