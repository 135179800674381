import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import {SignedInContext} from 'Contexts/signedin-context';
import {
  Kitchen,
  Battery30,
  Warning,
  Whatshot,
  LocalOffer,
  AccessTime as NoRecentComm,
  Home
} from "@material-ui/icons";
import { withStyles, Grid } from "@material-ui/core";

import {
  withAuth,
  StatsCard,
  RegularCard,
  ItemGrid
} from "components";

import  { Cell, Bar, BarChart, ResponsiveContainer,
  CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

import dashboardStyle from "variables/styles/dashboardStyle";

const colorArray = ["#e53935", "#fb8c00", "#43a047", "#43a047", "#00acc1"]
class Dashboard extends Component {
  constructor(props){
    super(props)
    this.state = {
      value: 0,
      dashboardAlerts: {},
      dashboardIcefillDist:[],
      dashboardTicketCount: [],
      dashboardIncidents: {},
      dashboardWarehouses:{},
      redirectTo: null
    };
  }
  componentDidMount = () => {
    this.props.dashStore.loadDash((res) => {
      this.setState({
        dashboardAlerts: res['iceq_general'],
        dashboardIcefillDist: res['iceq_icefill_dist'],
        dashboardTicketCount: res['ticket_count'],
        dashboardIncidents: res['incident_counts'],
        dashboardWarehouses: res['warehouse']
      })
    })
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };


  handleClick = (data, index) => {
    var range = data.dist.split("-")
    var filt = ""
    if(Number(range[0]) ===0){
      filt =`/locations?search=percent_full<=${range[1]} and freezer_status=Active`
    }else if(Number(range[1]) ===100){
      filt = `/locations?search=percent_full>${range[0]} and freezer_status=Active`
    }else {
      filt = `/locations?search=percent_full>${range[0]} and percent_full<=${range[1]} and freezer_status=Active`
    }
    this.setState({redirectTo: filt})
  };

  handleChangeIndex = index => {
    this.setState({
      value: index,
   });
  };
  render() {
    const {redirectTo } = this.state
    if(redirectTo){
      return <Redirect to={redirectTo} push />
    }
    var tileList = []
    return (
      <div>
        <SignedInContext.Consumer>
        {({signedInUser, changeMe}) => {
          if(!signedInUser) return null
          if(signedInUser.plan_features_list &&
            signedInUser.plan_features_list.feat_inventory) {
              tileList.push(<StatsCard
                icon={LocalOffer}
                iconColor="green"
                title="Open Tickets"
                key="open_tickets"
                description={this.state.dashboardTicketCount.open_tickets}
                small=""
                statIcon={LocalOffer}
                statIconColor="primary"
                statLinkOnClick={() => {
                  this.setState({redirectTo: `/tickets?search=user_id=${signedInUser.id} and (ticket_status_id=1 or ticket_status_id=2)`})
                }}
                statLink={{ text: "My Open Tickets...", href: "#" }}
              />)
              tileList.push(<StatsCard
                key="dashboardWarehouses"
                icon={Home}
                iconColor="green"
                title="Warehouses"
                description={this.state.dashboardWarehouses.cnt}
                small=""
                statIcon={Home}
                statIconColor="primary"
                statLinkOnClick={() => {
                  this.setState({redirectTo: `/locations?order_by=percent_full asc&search=category_id:1`})
                }}
                statLink={{ text: "Warehouses...", href: "#" }}
              />)
          }
          if(this.state.dashboardAlerts.reporting){
          tileList.push(<StatsCard
            icon={Kitchen}
            iconColor="green"
            key="alert_reporting"
            title="Freezers"
            description={this.state.dashboardAlerts.reporting}
            small=""
            statIcon={Warning}
            statIconColor="danger"
            statLinkOnClick={() => {
              this.setState({redirectTo: "/locations?search=sensors>=0 and freezer_status=Active"})
            }}
            statLink={{ text: "Freezers reporting...", href: "#" }}
          />)}
          if(this.state.dashboardIncidents['no contact']){
          tileList.push(<StatsCard
            icon={NoRecentComm}
            iconColor="orange"
            key="alert_no_contact"
            title="No Contact Alert"
            description={this.state.dashboardIncidents['no contact'] ? this.state.dashboardIncidents['no contact'] : "NA"}
            small=""
            statIcon={Warning}
            statIconColor="danger"
            statLinkOnClick={() => {
              this.setState({redirectTo: "/locations?search=incident_type='no contact'"})
            }}
            statLink={{ text: "See list...", href: "#" }}
          />)}
          if(this.state.dashboardIncidents['no contact temp warehouse']){
            tileList.push(<StatsCard
            icon={NoRecentComm}
              iconColor="red"
              title="No Comm"
              description={this.state.dashboardIncidents['no contact temp warehouse'] ? this.state.dashboardIncidents['no contact temp warehouse'] : "NA"}
              small=""
              statIcon={Warning}
              statIconColor="danger"
              key="alert_no_contact_wh"
              statLinkOnClick={() => {
                this.setState({redirectTo: "/locations?search=incident_type='no contact temp warehouse'"})
              }}
              statLink={{ text: "No Comm Warehouse...", href: "#" }}
            />)}
          if(this.state.dashboardIncidents['iceq temp']){
          tileList.push(<StatsCard
            icon={Whatshot}
            iconColor="red"
            title="Hot Alert"
            key="alert_temp"
            description={this.state.dashboardIncidents['iceq temp'] ? this.state.dashboardIncidents['iceq temp'] : "NA"}
            small=""
            statIcon={Warning}
            statIconColor="danger"
            statLinkOnClick={() => {
              this.setState({redirectTo: "/locations?search=incident_type='iceq temp'"})
            }}
            statLink={{ text: "Freezers above 32F...", href: "#" }}
          />)}
          if(this.state.dashboardIncidents['iceq warehouse temp']){
            tileList.push(<StatsCard
              icon={Whatshot}
              iconColor="red"
              title="Hot Alert"
              key="alert_temp_wh"
              description={this.state.dashboardIncidents['iceq warehouse temp'] ? this.state.dashboardIncidents['iceq warehouse temp'] : "NA"}
              small=""
              statIcon={Warning}
              statIconColor="danger"
              statLinkOnClick={() => {
                this.setState({redirectTo: "/locations?search=incident_type='iceq warehouse temp'"})
              }}
              statLink={{ text: "Warehouse above 29F...", href: "#" }}
            />)}
          if(this.state.dashboardAlerts.pf){
          tileList.push(<StatsCard
            icon={Battery30}
            iconColor="orange"
            title="< 25% Full"
            key="alert_pf"
            description={this.state.dashboardAlerts.pf ? this.state.dashboardAlerts.pf : "NA"}
            small=""
            statIcon={Warning}
            statIconColor="danger"
            statLinkOnClick={() => {
              this.setState({redirectTo: "/locations?search=percent_full<25  and freezer_status=Active"})
            }}
            statLink={{ text: "Freezers below 25%...", href: "#" }}
          />)}
          if(this.state.dashboardAlerts.pf_left){
          tileList.push(<StatsCard
            icon={Battery30}
            iconColor="blue"
            title="Left < 25%"
            key="alert_pf_left"
            description={this.state.dashboardAlerts.pf_left ? this.state.dashboardAlerts.pf_left : "NA"}
            statIcon={Warning}
            statIconColor="danger"
            statLinkOnClick={() => {
              this.setState({redirectTo: "/locations?search=percent_full_left<25  and freezer_status=Active"})
            }}
            statLink={{ text: "Left Side below 25%...", href: "#" }}
          />)}
          if(this.state.dashboardAlerts.pf_right){
            tileList.push(<StatsCard
            icon={Battery30}
            iconColor="blue"
            title="Right < 25%"
            key="alert_pf_right"
            description={this.state.dashboardAlerts.pf_right ? this.state.dashboardAlerts.pf_right : "NA"}
            statIcon={Warning}
            statIconColor="danger"
            statLinkOnClick={() => {
              this.setState({redirectTo: "/locations?search=percent_full_right<25  and freezer_status=Active"})
            }}
            statLink={{ text: "Right Side below 25%...", href: "#" }}
          />)}

          return (
            <Fragment>
           <Grid container>
           {tileList.map((item, key) => {
             return(<ItemGrid xs={12} sm={6} md={4} lg={3} key={`tiledash${key}`}>
              {item}
              </ItemGrid>)
           })}

         
          </Grid>
          <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
          {this.state.dashboardIcefillDist && this.state.dashboardIcefillDist.length > 0 ?(
            <RegularCard plainCard={false} headerColor="orange" cardSubtitle="Ice Levels Distribution" content={(
            <ResponsiveContainer minWidth={200} minHeight={350} width="100%" height="100%">
            <BarChart width={250} height={40} data={this.state.dashboardIcefillDist}>
              <XAxis dataKey="dist"
                tickFormatter={(val) =>   {
                  var range = val.split("-")
                  return `${range[0]}%-${range[1]}%`
              }}/>
              <YAxis mirror/>
              <CartesianGrid strokeDasharray="3 3"/>
              <Tooltip labelFormatter={(val) =>   {
                var range = val.split("-")
                return `${range[0]}%-${range[1]}%`
              }}/>
              <Bar dataKey='totals' name="Freezers" onClick={this.handleClick}>
                {this.state.dashboardIcefillDist.map((entry, index) => (
                    <Cell cursor="pointer" fill={colorArray[index]} key={`cell-${index}`}/>
                ))}
               </Bar>
             </BarChart>
           </ResponsiveContainer>)} />
          ) : null}
        </ItemGrid>
         <ItemGrid xs={12} sm={12} md={12}></ItemGrid>
        </Grid>

            </Fragment>
        ) }}
        </SignedInContext.Consumer>
        </div>    
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  dashStore: PropTypes.object.isRequired
};

export default withAuth(withStyles(dashboardStyle)(Dashboard));
