/*eslint-disable no-unused-vars*/
/*global google*/
import React from "react";
import {
  withStyles
} from "@material-ui/core";
import PropTypes from "prop-types";

import { AlarmGlobal } from "components";

import alarmIndicatorStyle from "variables/styles/alarmIndicatorStyle";

function AlarmIndicator({ ...props }) {
  const {
    classes,
    currentValue,
    colorRange,
    iconStyle,
    iconSize
  } = props;
  let CurrentIcon = AlarmGlobal.getIcon(false, iconStyle,currentValue)
    var color = null
  if(colorRange){
    color = AlarmGlobal.colorFromRange(currentValue, colorRange)
  } else {
    color = AlarmGlobal.colorFromIconDefault(currentValue, iconStyle)
  }

  return ( <CurrentIcon className={classes[iconSize + "Icon"] + " " +
    classes[color + "Icon"]}/>)
}

AlarmIndicator.defaultProps = {
  iconColor: "gray"
  };

AlarmIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  currentValue: PropTypes.node,
  colorRange: PropTypes.string,
  iconStyle: PropTypes.oneOf([
    "Temperature",
    "Time",
    "Fullness",
    "IceQ",
    "SiteOnly",
    "NoComm"
  ]),
  iconSize: PropTypes.oneOf([
    "huge",
    "large",
    "medium",
    "small",
    "tiny"
  ])
};

export default withStyles(alarmIndicatorStyle)(AlarmIndicator);
