import React, { Fragment } from "react";
import AuthService from './AuthService';
import { Grid, withStyles, TextField, Checkbox, Typography, FormControlLabel } from "@material-ui/core";
import { AddAlert } from "@material-ui/icons";
import { Formik } from 'formik';
import Background from "assets/img/backdrop.jpg";
import Logo from "assets/img/icelogo-nameside-xtrasmall.png";
import * as Yup from 'yup';
import {
  RegularCard,
  Button,
  ItemGrid,
  Snackbar
} from "components";
import {
  NavLink
} from "react-router-dom";

const styles = theme => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    top: '20%'
  },
  demo: {
    height: 'inherit',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    height: '100%',
    color: theme.palette.text.secondary,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  toc: {
    width: '198px',
  }
});

var sectionStyle = {
  height: "1080px",
  backgroundImage: `url(${Background})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',

};

class Login extends React.Component {
  constructor() {
    super();
    //this.handleChange = this.handleChange.bind(this);
    this.Auth = new AuthService();
    this.state = {
      show_toc: false,
      tl: false,
      tc: false,
      tr: false,
      bl: false,
      bc: false,
      br: false,
      errMsg: ''
    };

  }

  componentDidMount(){
    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;

    this.setState({x:x,y:y});
    if(this.Auth.loggedIn())
      this.props.history.replace('/');
  }

  showNotification(place) {
    var x = [];
    x[place] = true;
    x["l_timer"] = setTimeout(
      function() {
        x[place] = false;
        this.setState(x);
      }.bind(this),
      2000
    );
    this.setState(x);
  }

  render () {
    const { classes } = this.props;
    return (
      <div style={sectionStyle}>
        <Grid container className={classes.root}>
          <ItemGrid xs={12}>
            <Formik
              validationSchema= {Yup.object().shape({
                email: Yup.string().email('Email not valid').required('Email is required'),
                password: Yup.string().min(4, 'Password must be 4 characters or longer')
                .required('Password is required')
              })}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                this.Auth.login(values.email, values.password, values.toc_version)
                .then(res =>{
                  clearTimeout(this.state.l_timer);
                  resetForm()
                  this.props.history.replace('/');
                })
                .catch(err =>{
                  setSubmitting(false)
                  var errProm = err.response.json();
                  errProm.then(errResp => {
                    if(err.response && err.response.status && err.response.status === 401){
                      if(errResp.error.user_invalid_toc){
                        this.setState({errMsg: "Please Agree to the Updated Terms and Conditions"})
                        this.showNotification("tc");
                        this.setState({show_toc: true})
                      }else {
                        this.setState({errMsg: "Email/Password credentials not valid"})
                        this.showNotification("tc");
                      }

                    } else {
                      this.setState({errMsg: "We are experiencing technical difficulties, Try again later"})
                      this.showNotification("tc");
                    }
                  })
                })
               }}
                initialValues={{
                  email: '',
                  password: '',
                  toc_version: false
                }}
                render={({values, errors, touched, isSubmitting, handleChange, handleSubmit}) =>(
                  <div>
                    <Grid container  alignItems='center' justify='center' direction='column' spacing={16} className={classes.demo}>
                      <ItemGrid  xs={12} item>
                         <form onSubmit={handleSubmit}>
                         <Snackbar
                           place="tc"
                           color="danger"
                           icon={AddAlert}
                           message={this.state.errMsg}
                           open={this.state.tc}
                           closeNotification={() => {
                             this.setState({ tc: false, errMsg: '' })
                           }}
                           close
                         />
                <RegularCard
                  cardTitle={<img src={Logo} alt="Logo" style={{
                    paddingTop: "20px",
                    display: 'block',
                    marginRight: 'auto',
                    width: '70%'
                  }}/>}
                  headerColor="white"
                  plainCard={false}
                  content={
                    <div>
                      <Grid container direction='column' justify='center' alignItems='center'
                      >
                        <ItemGrid xs={12} sm={12} md={12}>
                          <TextField
                            error={(errors != null && errors.email != null)? true : false}
                            id="email"
                            label={(errors != null && errors.email != null)? errors.email : "Email"}
                            value={ values.email}
                            margin="normal"
                            name="email"
                            onChange= {handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <TextField
                            id="password"
                            type="password"
                            label="Password"
                            margin="normal"
                            name="password"
                            onChange= {handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                        { this.state.show_toc ? 
                          <Fragment>
                            <FormControlLabel
                              control={
                                <Checkbox
                                id="toc_version"
                                name="toc_version"
                                color="primary"
                                checked={values.toc_version}
                                onChange={handleChange}
                                value="i agree"
                                />
                              }
                              label="Check the box to AGREE to the Terms of Service"
                              className={classes.toc}
                            />
                        </Fragment>
                        : null }
                       </ItemGrid>

                        <ItemGrid xs={12} sm={12} md={12} >
                        <Button className="form-submit"
                        type="submit" color="primary" disabled={isSubmitting}>Login</Button>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <a color="info" href="https://www.iceq.cool/terms">Terms of Service</a>
                        </ItemGrid>
                      </Grid>
                    </div>
                  }
                />

            </form>
            </ItemGrid>
            </Grid>
            </div>
          )}/>
          </ItemGrid>

                </Grid>
          </div>
    );
  }

}

export default withStyles(styles)(Login);
