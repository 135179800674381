import {
  autorun,
  observable,
  decorate,
  isObservable
} from "mobx";
import AuthService from 'components/AuthService';
class TicketStore {
  tickets = [];
  hasNextPage = true;
  currentPage = 1;
  isLoading = true;
  previousSize = -1;
  currentTicket = null;
  searchCrit = "";
  Auth = new AuthService();

  statusesList = []
  typesList = []
  placementsList = []
  modelsList = []
  productModelRatioList = []

  resetStore(){
    this.tickets = []
    this.hasNextPage = true
    this.currentPage = 1
    this.isLoading = true
    this.previousSize = -1
    this.currentTicket = null
    this.searchCrit = ""
  }

  workflow(user, status_id){
    var ar = {0 : [1,2], 1 : [2,4], 2 : [1,3,4], 3:[], 4:[] }
    if(!status_id){
      status_id = 0
    }
    if(ar[status_id]) {
      ar[status_id].push(status_id)
      return ar[status_id].map((track, i) => (
        this.statusesList.find(o => o.id === track)
      ))
    } else {
    return []
    }
  }
  moveTicketTypeId(){
   return 2
  }

  getStatusColor(status_id){
    return {1: "#F79637", 2:"#0B678F", 3:"#92C344",4:"#666E6F"}[status_id]
  }

  getStatus(status_id){
    var res = this.statusesList.find(o => o.id === status_id)
    if(res){
      return res
    } else {
      return this.statusesList.find(o => o.id === 1)
    }
  }

  ticketDisabledOnStatus(status_id){
    if([3,4].find(o => o === status_id)){
      return true
    } else {
      return false
    }
  }

  updateTicket(ticket, onComplete, onError){
    var httpmethod = "POST"
    var url = `${this.Auth.domain}/tickets`
    if (ticket.id && ticket.id > 0) {
       httpmethod = "PUT"
       url = `${this.Auth.domain}/tickets/${ticket.id}`
     }
    this.Auth.fetch(url, {
        method: httpmethod,
        body: JSON.stringify(ticket)
      })
      .then(response => {
        this.currentTicket = response;
        var idx = this.tickets.findIndex(function(element) { return element.id === response.id})
        if(idx < 0) {
          this.tickets.unshift(response)
        } else {
          this.tickets[idx] = response
        }
        if(onComplete){
          onComplete(response);
        }
        this.isLoading = false;
      }).catch(error => {
        if(onError){
          error.response.json().then((value) => { onError(value); })
        }
      })
    return true;
  }

 defaultToNonNull = (val, type) => {
    if(val){
      return val
    } else {
      return (type && type ==="n") ? 0 : ""
    }
  }

  isRowLoaded({
    index
  }) {
    return !this.hasNextPage || index < this.tickets.size
  }

  getTicket(ticketId, onComplete, onError) {
    this.currentTicket = null
    if (ticketId < 0) {
      //load default
    } else {
      this.Auth.fetch(`${this.Auth.domain}/tickets/${ticketId}`, {
          method: 'GET'
        })
        .then(response => {
          if (response.length <= 0) {
            //TODO: raise exception as not found.
          }
          this.currentTicket = response;
          if(onComplete){
            onComplete(response)
          }
      }).catch(error => {
        error.response.json().then((value) => { onError(value); })
        })
    }
    return true;
  }

  updateLocation(ori_location, onSuccess, onError) {
    //this.isLoading = true
    var location = JSON.parse(JSON.stringify(ori_location));
    var httpmethod = "POST"
    var url = `${this.Auth.domain}/locations`
    if (location.id && location.id > 0) {
      httpmethod = "PUT"
      url = `${this.Auth.domain}/locations/${location.id}`

    }
    delete location['id']
    this.Auth.fetch(url, {
        method: httpmethod,
        body: JSON.stringify(location)
      })
      .then(response => {
        this.currentLocation = response;
//update local array
        var idx = this.locations.findIndex(function(element) { return element.id === response.id})
        if(idx < 0) {
          this.locations.unshift(response)
        } else {
          this.locations[idx] = response
        }
        //this.isLoading = false;
        if(onSuccess) {
          onSuccess(response)
        }
      }).catch(error => {
        if(onError){
          error.response.json().then((value) => { onError(value); })
        }
      })
    return true;
  }

  setCurrentLocationToDefault(){
    this.isLoading = true
    this.currentLocation = this.getDefaultLocation();
    this.isLoading = false
  }

  getDefaultTicket(mergeProps){
    return Object.assign({
    "id": -1,
    "title": "",
    "description": "",
    "user_id": 0,
    "ticket_status_id": 1
  }, mergeProps)
  }

  loadTicketStatuses(onCompletion) {
    return this.loadSupportData(this.statusesList, "ticket_statuses", null, onCompletion);

  }

  loadTicketTypes(onCompletion) {
    return this.loadSupportData(this.typesList, "ticket_types", null, onCompletion);
  }

  loadSupportData(list, supportDataDesc, stateVarWhenLoaded, onCompletion) {
    //if already loaded then return with cached value
    if(list.length > 0 ) {
      onCompletion(list);
      return true;
    }
    this.Auth.fetch(`${this.Auth.domain}/${supportDataDesc}`, {
        method: 'GET'
      })
      .then(response => {
        if (response.length <= 0) {
          //TODO: raise exception as not found.
        }
        list.splice(0, list.length);
        list.push(...response);
        onCompletion(response);
      })

    return true;
  }

  startSearch(search){
  //  this.locations.clear()
    this.tickets = null;
    this.isLoading = true
    this.searchCrit = search
    this.previousSize = -1
    this.hasNextPage = true
    this.currentPage = 1
    this.loadTickets(this.currentPage)
  }

  loadTickets(page, onComplete, onFailure, fromFreezer) {
    if (page) {
      this.currentPage = page
    } else {
      this.currentPage = 1
    }
    var url = `${this.Auth.domain}/tickets?page=${this.currentPage}`
    if(fromFreezer) {
      url = `${this.Auth.domain}/freezers/${fromFreezer}/tickets?page=${this.currentPage}`
    }
    if(this.searchCrit) {
      url = `${url}&search=${this.searchCrit}`
    }
    this.Auth.fetch(url, {
      method: 'GET'
    }).then(response => {
      if(response.length ===0 || response.length < 50){
        this.hasNextPage = false;
      }
      else if (this.previousSize === -1 || this.previousSize < response.length) {
        this.previousSize = response.length;
        this.hasNextPage = true;
      } else if (this.previousSize === response.length) {
        this.hasNextPage = false;
      }
      this.tickets = response;
      if(onComplete){
        onComplete(response)
      }
      this.isLoading = false;
    })
    return true;
  }
}
decorate(TicketStore, {
  isLoading: observable,
  tickets: observable,
  hasNextPage: observable,
  currentTicket: observable
})
var ticketStore = window.ticketStore = new TicketStore()
export default ticketStore
autorun(() => {});
