import React, { Component } from 'react';
import { Grid, TextField,
  Typography, Dialog, AppBar, Toolbar } from "@material-ui/core";
import { Formik } from 'formik';
import { Close as CloseIcon, Clear } from "@material-ui/icons";
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types"
import {
  ItemGrid,
  PickDateTime,
  IconButton,
  ProgressButton,
  AsyncCustomSelect
} from "components";

import IceQUtils from 'store/IceQUtils';
import LuxonUtils from '@date-io/luxon';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import AssetLookup from 'views/locations/AssetLookup';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  appBar: {
    position: 'relative',
    backgroundColor: "#EEEEEE"
  },
  colorPrimary: {
    backgroundColor: "#0b678f"
  },
  flex: {
    paddingLeft: '10px',
    flex: 1,
    color:  "#0b678f"
  }
});

const defaultSaveMsgs = {
  loadingSaveMsg: "",
  loadingSaveSuccessMsg: "",
  oadingSaveFailureMsg: ""
}

class FreezerForm extends Component {


  constructor(props) {
    super(props);
    this.state = {
      conditionsLoaded:false,
      placementsLoaded: false,
      productsLoaded:false,
      modelsLoaded: false,
      productModelRatiosLoaded: false,
      loadingSaveMsg: "",
      loadingSaveSuccessMsg: "",
      loadingSaveFailureMsg: "",
      showQRReader: false
    };



  }

  componentDidMount(){
    this.props.store.loadAllSupportData(
      (res) => { this.setState({
        productModelRatiosLoaded:true,
        conditionsLoaded:true,modelsLoaded:true,
        productsLoaded:true, placementsLoaded:true,
      }) }
    )

  }
  handleFreezer = (freezerInfo, onError) => {
    this.setState( { loadingSaveMsg: "Saving ...",
              loadingSaveSuccessMsg: "",
              loadingSaveFailureMsg: ""
            })
    this.props.store.updateFreezer(freezerInfo,
      (newFreezer) => {
        this.setState(
          { loadingSaveMsg: "",
            loadingSaveSuccessMsg: "Saved",
            loadingSaveFailureMsg: ""
          })
        setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
      },
      (error) => {
        error.response.json().then((value) => {
          onError(value);
        })
        this.setState(
          {
            loadingSaveMsg: "",
            loadingSaveSuccessMsg: "",
            loadingSaveFailureMsg: "Error Saving"
          }
        )
        setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
      });
    }

    modelSearch = (inputValue, callback) => {
        this.props.store.loadModels((dd) => {
          var ddf = dd
          if(inputValue)
            ddf = dd.filter((item) => {
              var ar = inputValue.split(" ")
              for(var x =0 ; x < ar.length; x++){
                if(!(item.manufacturer + item.model + (item.indoor_outdoor ===1 ? "Indoor" : "Outdoor")).toLowerCase().includes(ar[x].toLowerCase())){
                  return false
                }
              }
              return true
            })
          callback(ddf.map(opt => ({ label: IceQUtils.modelDropDrownFormat(opt), value: opt['id'] })));
        })
    }

    render() {
      const { classes, store, loggedInUser } = this.props;
      if (!this.props.loaded || !this.props.data) return null
      return (
        <div>
        <Dialog
        fullScreen
        open={true}
        onClose={this.handleClose}
        >

        <Formik
        initialValues={{
          id: this.props.data.id,
          asset_tag: store.defaultToNonNull(this.props.data.asset_tag),
          status: store.defaultToNonNull(this.props.data.status),
          location_id: this.props.data.location_id,
          install_date: this.props.data.install_date,
          installed_by: store.defaultToNonNull(this.props.data.installed_by),
          removal_date: this.props.data.removal_date,
          removal_by: store.defaultToNonNull(this.props.data.removal_by),
          serial_number: store.defaultToNonNull(this.props.data.serial_number),
          model_id: this.props.data.model_id,
          model: this.props.data.model_id ? {
            value: this.props.data.model_id,
            label: IceQUtils.modelDropDrownFormat(this.props.data.model)
          } : null,
          freezer_type_id: this.props.data.freezer_type_id,
          freezer_type: this.props.data.freezer_type_id || this.props.data.freezer_type_id === 0 ? {
            value: this.props.data.freezer_type_id,
            label: IceQUtils.freezerTypeById(this.props.data.freezer_type_id.toString()).type 
          } : null,
          placement_id: this.props.data.placement_id,
          condition_id: this.props.data.condition_id,
          product_model_ratio_id: this.props.data.product_model_ratio_id,
          product_left_side_id: this.props.data.product_left_side_id,
          product_right_side_id: this.props.data.product_right_side_id,
          comment: store.defaultToNonNull(this.props.data.comment)
        }}
        onSubmit={(values, {setSubmitting, resetForm, setErrors}) => {
          this.handleFreezer(values, setErrors)
          setSubmitting(false)
        }}

        validationSchema= {Yup.object().shape({
          status: Yup.string().min(2).max(50).required(),
          asset_tag: Yup.string().min(2).max(50).nullable(),
          install_date: Yup.string().min(4).max(50).nullable(),
          installed_by: Yup.string().min(2).max(50).nullable(),
          removal_date: Yup.string().min(2).max(50).nullable(),
          removal_by: Yup.string().min(5).max(50).nullable(),
          serial_number: Yup.string().min(5).max(25).nullable(),
          model_id: Yup.number().min(1).required(),
          placement_id: Yup.number().min(1).required(),
          condition_id: Yup.number().min(1).required(),
          product_model_ratio_id: Yup.number().min(1).required(),
          product_left_side_id: Yup.number().min(1).required(),
          product_right_side_id: Yup.number().min(1).required(),
          comment: Yup.string().max(2000)
        })}

        render={({values, errors, touched, handleChange, handleBlur,
          isSubmitting, handleSubmit, setFieldValue, setFieldError, setValues}) => (
            <div className="center">
            <MuiPickersUtilsProvider utils={LuxonUtils}  locale="en">
            <div className="card">
            <form onSubmit={handleSubmit}>
            <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="primary" onClick={this.props.onClose}
              aria-label="Close">
                <CloseIcon />
                </IconButton>
                <Typography variant="title" className={classes.flex}>
                Freezer Configuration
                </Typography>
                <ProgressButton
                  className="form-submit"
                  color="primary"
                  type="submit"
                  loadingMsg={this.state.loadingSaveMsg}
                  loadingSuccessMsg={this.state.loadingSaveSuccessMsg}
                  loadingFailureMsg={this.state.loadingSaveFailureMsg}
                  disabled={isSubmitting}>SAVE
                </ProgressButton>
                </Toolbar>
                </AppBar>
                <Grid container>
                <ItemGrid xs={12}>
                <div>

                  { loggedInUser && loggedInUser.plan_features_list && loggedInUser.plan_features_list.feat_inventory ? (
                      <Grid container>
                    <ItemGrid xs={12} sm={12} md={12}>

                        {values.asset_tag ? (
                          <div>
                          <Typography variant="title" className={classes.flex}>
                          Asset Tag: {values.asset_tag}
                          </Typography>
                          <IconButton aria-label="Clear" style={{marginLeft: "10px"}} color="white" onClick={
                            (res) => { setFieldValue("asset_tag", "") }}><Clear /></IconButton>
                            </div>
                        ): (
                          <AssetLookup error={errors.asset_tag} disableScanErrors={true}
                            classes={classes}
                            onSuccess={
                              (freezerUp, data) => {
                                if(freezerUp){ setFieldError("asset_tag", "Tag already assigned") } else { setFieldValue("asset_tag", data) }
                              }
                            }
                            onFailure={(data) => { if(data){ setFieldValue("asset_tag", data) }}}/>
                        )}

                    </ItemGrid>
                    </Grid>


                  ) : null }
                <Grid container>
                <ItemGrid xs={12} sm={12} md={6} style={{display: "flex"}}>
                  <AsyncCustomSelect
                    formControlProps={{fullWidth: true}}
                    error={ (errors != null && errors.model != null) ? true : false}
                    labelText={(errors != null && errors.model != null)? errors.model : "Model"}
                    inputProps={{
                      menuPosition: 'absolute',
                      menuPlacement: 'auto',
                      menuPortalTarget: document.body,
                      styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
                      defaultValue: values.model,
                      cacheOptions: true,
                      defaultOptions: true,
                      id: "model",
                      name: "model",
                      onInputChange: (newValue) => {
                        const inputValue = newValue.replace(/\W/g, ' ');
                        this.setState({ "inputValue_model":inputValue });
                        return inputValue;
                      },
                      onChange: value => {
                        setFieldValue('model', value)
                        setFieldValue('model_id', value.value)
                      },
                      value: values.model,
                      loadOptions: this.modelSearch
                    }} />
                    
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={6}>
                  <TextField
                  variant="outlined"
                    id="product_model_ratio_id"
                    select
                    disabled={(values.model_id && values.model_id > 0 && !(errors.model_id)) ? false : true}
                    label="Product Ratio"
                    name="product_model_ratio_id"
                    className={classes.textField}
                    error={errors.product_model_ratio_id ? true : false}
                    value={values.product_model_ratio_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    >
                    <option key="notSet" value="0">Not Set</option>
                    { (this.state.productModelRatiosLoaded &&
                    values.model_id && values.model_id > 0 && !(errors.model_id)) ?
                    this.props.store.productModelRatioList.filter((cval) => {
                        return (cval.model_id.toString() === values.model_id.toString())
                      }).map((product_model_ratio) => (
                        <option key={"product_model_ratios_list_" + product_model_ratio.id}
                          value={product_model_ratio.id}>{product_model_ratio.ratio}
                          {product_model_ratio.model.manufacturer} {product_model_ratio.model.model}
                        </option>
                      )): null
                    }
                    </TextField>
                    </ItemGrid>
                    </Grid>

                    <Grid container>
                    <ItemGrid xs={6} sm={6} md={6}>
                    <TextField
                    id="product_left_side_id"
                    variant="outlined"
                    select
                    label="Left Product"
                    name="product_left_side_id"
                    className={classes.textField}
                    value={values.product_left_side_id}
                    error={errors.product_left_side_id ? true : false}
                    onChange={handleChange}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Select left side product"
                    margin="normal"
                    > <option key="notSet" value="0">Not Set</option>
                    { this.state.productsLoaded ?
                      this.props.store.productsList.map((product) => (
                        <option key={"left_product_list_" + product.id}
                        value={product.id}>{product.name}</option>
                      )): null
                    }

                    </TextField>
                    </ItemGrid>
                    <ItemGrid xs={6} sm={6} md={6}>
                    <TextField
                    id="product_right_side_id"
                    variant="outlined"
                    select
                    label="Right Product"
                    name="product_right_side_id"
                    className={classes.textField}
                    error={errors.product_right_side_id ? true : false}
                    value={values.product_right_side_id}
                    onChange={handleChange}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Select right side product"
                    margin="normal"
                    >
                    <option key="notSet" value="0">Not Set</option>
                    { this.state.productsLoaded ?
                      this.props.store.productsList.map((product) => (
                        <option key={"right_product_list_" + product.id}
                        value={product.id}>{product.name}</option>
                      )): null
                    }
                    </TextField>
                    </ItemGrid>
                    </Grid>

                    <Grid container>
                    <ItemGrid xs={12} sm={12} md={6} style={{display: "flex"}}>
                    <div style={{  display: "flex",
                    alignItems: "flex-end"}}>
                    <TextField
                    variant="outlined"
                    error={errors.serial_number ? true :false}
                    id="serial_number"
                    label={errors.serial_number ? errors.serial_number :"Freezer Serial #"}
                    value={ values.serial_number}
                    margin="normal"
                    name="serial_number"
                    onChange= {handleChange}
                    />
                    </div>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                    variant="outlined"
                    id="status"
                    select
                    label={errors.status ? errors.status : "Status"}
                    name="status"
                    error={errors.status ? true : false}
                    className={classes.textField}
                    value={values.status}
                    onChange={handleChange}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    >
                    {this.props.store.freezerStatus.map((optionValue ) => (
                      <option key={"status_list_" +optionValue.val} value={optionValue.val}>{optionValue.desc}</option>
                    ))}
                    </TextField>
                    </ItemGrid>
                    </Grid>

                    <Grid container>
                    <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                    id="condition_id"
                    variant="outlined"
                    select
                    label="Condition"
                    name="condition_id"
                    error={errors.condition_id ? true : false}
                    className={classes.textField}
                    value={values.condition_id}
                    onChange={handleChange}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Please select Condition"
                    margin="normal"
                    >
                    <option key="notSet" value="0">Not Set</option>
                    { this.state.conditionsLoaded ?
                      this.props.store.conditionsList.map((condition) => (
                        <option key={"condition_list_" + condition.id}
                        value={condition.id}>{condition.name}</option>
                      )): null
                    }

                    </TextField>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                    id="placement_id"
                    variant="outlined"
                    select
                    label="Placement"
                    name="placement_id"
                    className={classes.textField}
                    error={errors.placement_id ? true : false}
                    value={values.placement_id}
                    onChange={handleChange}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    helperText="Please select Placement"
                    margin="normal"
                    >  <option key="notSet" value="0">Not Set</option>
                    { this.state.placementsLoaded ?
                      this.props.store.placementsList.map((placement) => (
                        <option key={"placement_list_" + placement.id}
                        value={placement.id}>{placement.name}</option>
                      )): null
                    }

                    </TextField>
                    </ItemGrid>
                    </Grid>

                    <Grid container>
                    <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                            //  error={errors.ticket_notes_attributes.notes ? true :false}
                              id="comment"
                              label="Comment"
                              value={ values.comment }
                              margin="normal"
                              name="comment"
                              multiline 
                              rowsMax="10"
                              fullWidth
                              onChange={handleChange}
                              variant="outlined"
                              />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={6}>
                    <AsyncCustomSelect
                    formControlProps={{fullWidth: true}}
                    error={ (errors != null && errors.freezer_type_id != null) ? true : false}
                    labelText={(errors != null && errors.freezer_type_id != null)? errors.freezer_type_id : "Freezer Type"}
                    inputProps={{
                      menuPosition: 'absolute',
                      menuPlacement: 'auto',
                      menuPortalTarget: document.body,
                      styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
                      defaultValue: values.freezer_type_id,
                      cacheOptions: true,
                      defaultOptions: true,
                      id: "freezer_type_id",
                      name: "freezer_type_id",
                      onInputChange: (newValue) => {
                        const inputValue = newValue.replace(/\W/g, ' ');
                        this.setState({ "inputValue_freezer_type_id":inputValue });
                        return inputValue;
                      },
                      onChange: value => {
                        setFieldValue('freezer_type', value)
                        setFieldValue('freezer_type_id', value.value)
                      },
                      value: values.freezer_type,
                      loadOptions:  (inputValue, callback) => {
                          var ddf = []
                          if(inputValue){
                            ddf = IceQUtils.freezerTypes().filter(item => item.type.toLowerCase().includes(inputValue.toLowerCase()))
                          } else {
                            ddf = IceQUtils.freezerTypes()
                          }
                          callback(ddf.map(opt => ({ label: `${opt['type']}`, value: opt['id'] })));
                        }
                     }} />
                    </ItemGrid>
                    </Grid>

                    <Grid container>
                    <ItemGrid xs={12} sm={12} md={6}>

                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={6}>

                    </ItemGrid>
                    </Grid>

                    <Grid container>
                    <ItemGrid xs={12} sm={12} md={6}>
                    <PickDateTime
                    variant="outlined"
                    name= "install_date" id="install_date"
                    value={values.install_date} autoOk={true}
                    onChange={setFieldValue}
                    labelText="Installed Date"
                    invalidLabel={errors.install_date}
                    invalidDateMessage = {errors.install_date}
                    />
                    </ItemGrid>

                    <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                    variant="outlined"
                    error={errors.installed_by ? true :false}
                    id="installed_by"
                    label={errors.installed_by ? errors.installed_by :"Installed By"}
                    value={ values.installed_by}
                    margin="normal"
                    name="installed_by"
                    onChange= {handleChange}
                    />
                    </ItemGrid>
                    </Grid>

                    <Grid container>
                    <ItemGrid xs={12} sm={12} md={6}>
                    <PickDateTime
                    name= "removal_date" id="removal_date"
                    value={values.removal_date} autoOk={true}
                    onChange={setFieldValue} labelText="Removed Date"
                    />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={6}>
                    <TextField
                    variant="outlined"
                    error={errors.removal_by ? true :false}
                    id="removal_by"
                    label={errors.removal_by ? errors.removal_by :"Removal By"}
                    value={ values.removal_by}
                    margin="normal"
                    name="removal_by"
                    onChange= {handleChange}
                    />
                    </ItemGrid>
                    </Grid>

                    </div>
                    </ItemGrid>
                    </Grid>
                    </form>
                    </div>
                    </MuiPickersUtilsProvider>
                    </div>

                  )}/>
                  </Dialog>
                  </div>);
              }


}
FreezerForm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  loggedInUser: PropTypes.object
}

 export default withStyles(styles)(FreezerForm);
