import React from "react";
import { withStyles, FormControl, InputLabel, NoSsr, TextField  } from "@material-ui/core";
import { Clear, Check } from "@material-ui/icons";
import PropTypes from "prop-types";
import cx from "classnames";

import customInputStyle from "variables/styles/customInputStyle";
import Select from 'react-select';

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function CustomSelect({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    errorText
  } = props;

  const labelClasses = cx({
    [" " + classes.labelRootError]: (error || (errorText !== undefined && errorText !=='')),
    [" " + classes.labelRootSuccess]: success && !(error || (errorText !== undefined && errorText !==''))
  });

  const marginTop = cx({
    [classes.marginTop]: labelText === undefined
  });

  return (
    <NoSsr>
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      <Select
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: classes.underline,
          ...classes
        }}
        id={id}
        components={{Control,ValueContainer}}
        textFieldProps={{
          label: labelText,
          variant:"outlined",
          InputLabelProps: {
            shrink: true
          }
        }}
        className="basic-single"
          classNamePrefix="select"
        {...inputProps}
      />

      {error || errorText !== undefined ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
    </NoSsr>
  );
}

CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  errorText: PropTypes.node
};

export default withStyles(customInputStyle)(CustomSelect);
