import React, { Component } from 'react';
import { Grid, Typography,
Slide, Dialog, AppBar, Toolbar } from "@material-ui/core";
import { Formik } from 'formik';
import { Close as CloseIcon } from "@material-ui/icons";
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import {
ProgressButton,
CustomInput,
ItemGrid,
IconButton
} from "components";

import SupportDataStore from 'store/SupportDataStore';
import ClientRegionStore from 'store/ClientRegionStore';
import  { observer } from "mobx-react";

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  appBar: {
    position: 'relative',
    backgroundColor: "#EEEEEE"
  },
  colorPrimary: {
    backgroundColor: "#0b678f"
  },
  flex: {
    paddingLeft: '10px',
    flex: 1,
    color:  "#0b678f"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const defaultSaveMsgs = {
  loadingSaveMsg: "",
  loadingSaveSuccessMsg: "",
  oadingSaveFailureMsg: ""
}

const RegionForm = observer(
class RegionForm extends Component {
  constructor(props) {
    super(props)
    this.state = defaultSaveMsgs

  }

  componentDidMount() {
    SupportDataStore.loadTimezones();
  }

  handleClose = () => {
  ClientRegionStore.isSelected = false;
  };

  render() {
    const { classes } = this.props;
    return (
    <div>
      <Dialog
         fullScreen
         open={ClientRegionStore.isSelected}
         onClose={this.handleClose}
         TransitionComponent={Transition}
       >
       <Formik
       initialValues={{
         name: ClientRegionStore.selectedRegion.name,
         region_code: ClientRegionStore.selectedRegion.region_code,
         timezone: ClientRegionStore.selectedRegion.timezone,
       }}
       onSubmit={(values, {setSubmitting, resetForm}) => {
         var region = values;
        if(ClientRegionStore.selectedRegion.id > 0 ){
          region.id = ClientRegionStore.selectedRegion.id
        }
        ClientRegionStore.updateRegion(region)
        setSubmitting(false)
        ClientRegionStore.isSelected=false;

       }}

       validationSchema= {Yup.object().shape({
         name: Yup.string().min(2).max(50).required(),
         region_code: Yup.string().min(1).max(10)
       })}

       render={({values, errors, touched, handleChange, isSubmitting, handleSubmit,  setValues}) => (
       <div>
        <form onSubmit={handleSubmit}>
       <AppBar className={classes.appBar} color="primary">
         <Toolbar>

           <IconButton color="primary" onClick={this.handleClose} aria-label="Close">
             <CloseIcon />
           </IconButton>
           <Typography variant="title" color="primary" className={classes.flex}>
             {ClientRegionStore.selectedRegion.id > 0 ? "Edit Region" : "Add Region"}
           </Typography>
           <ProgressButton
             className="form-submit"
             color="primary"
             type="submit"
             loadingMsg={this.state.loadingSaveMsg}
             loadingSuccessMsg={this.state.loadingSaveSuccessMsg}
             loadingFailureMsg={this.state.loadingSaveFailureMsg}
             disabled={isSubmitting}>SAVE
           </ProgressButton>
         </Toolbar>
       </AppBar>
       <div>
               <Grid container>
                 <ItemGrid xs={12} sm={12} md={4}>
                 <CustomInput
                   labelText="Region Description"
                   id="name"
                   errorText={errors.name}
                   formControlProps={{fullWidth: true}}
                   inputProps={{
                     name: "name", value: values.name, onChange: handleChange
                   }}
                 />
                 </ItemGrid>
                 <ItemGrid xs={12} sm={12} md={4}>
                 <CustomInput
                   labelText="Region Code"
                   id="region_code"
                   errorText={errors.region_code}
                   formControlProps={{fullWidth: true}}
                   inputProps={{
                     name: "region_code", value: values.region_code, onChange: handleChange
                   }}
                 />
                 </ItemGrid>
                 </Grid>
                 </div>
          </form>
        </div>
      )}/>

    </Dialog>
      </div>
    )
  }
})

export default withStyles(styles)(RegionForm);
