import React, {Component, Fragment, useState} from 'react';
import { Grid, Typography, Divider, Tabs, Tab } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import FreezerEdit from "./FreezerForm";

import {Field, Formik} from 'formik';
import * as Yup from 'yup';
import Ticket from "views/Tickets/Ticket";
import FreezerStore from "store/FreezerStore";
import TicketForm from "views/Tickets/TicketForm";

import TicketStore from "store/TicketStore";
import IceQFormDialog from "./IceQFormDialog";
import  { observer } from "mobx-react";
import { DateTime } from 'luxon';
import IceQUtils from 'store/IceQUtils';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from "react-router-dom";
import {SignedInContext} from 'Contexts/signedin-context';



import {
  ProfileCard,
  Button,
  ItemGrid,
  TZConvert,
  ConfirmationDialog,
  ZoomChart,
  LoaderCircle,
  ProgressButton, CustomInput
} from "components";

import PropTypes from "prop-types";
const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  secondaryHeading: {
  fontSize: theme.typography.pxToRem(15),
  color: theme.palette.text.secondary,
},
});
const defaultSaveMsgs = {
  loadMsg: "",
  successMsg: "",
  failureMsg: ""
}



export const IceQReadings = (props) => {
  const {classes, readings} = props;
  return (
    <React.Fragment>
      <Grid container>
        <ItemGrid xs={4} sm={3} md={3} style={{textAlign: "left"}}>Call Time</ItemGrid>
        <ItemGrid xs={2} sm={2} md={2} style={{textAlign: "left"}}>Full</ItemGrid>
        <ItemGrid xs={2} sm={1} md={1} style={{textAlign: "left"}}>Temp</ItemGrid>
         <Hidden xsDown>
        <ItemGrid xs={false} sm={1} md={1}>Sig</ItemGrid>
        <ItemGrid xs={false} sm={1} md={1}>Rsn</ItemGrid>
        </Hidden>
        <ItemGrid xs={1} sm={1} md={1} style={{textAlign: "right"}}>S1</ItemGrid>
        <ItemGrid xs={1} sm={1} md={1} style={{textAlign: "right"}}>S2</ItemGrid>
        <ItemGrid xs={1} sm={1} md={1} style={{textAlign: "right"}}>S3</ItemGrid>
        <ItemGrid xs={1} sm={1} md={1} style={{textAlign: "right"}}>S4</ItemGrid>
      </Grid>
      {readings.map((reading, readingIdx) => (
        <Grid container key={`reading${readingIdx}`}>
          <ItemGrid xs={4} sm={3} md={3} style={{textAlign: "left"}}><TZConvert utcSqlDate={reading.callin_time}/>
          
          {(reading.sensor6 < 0) ? '*' : '' }
          </ItemGrid>
          <ItemGrid xs={2} sm={2} md={2} style={{textAlign: "left"}}>{reading.percent_full}%</ItemGrid>
          <ItemGrid xs={2} sm={1} md={1} style={{textAlign: "left"}}>{reading.temperature}</ItemGrid>
          <Hidden xsDown>
          <ItemGrid xs={false} sm={1} md={1}>{reading.signal_strength}</ItemGrid>
          <ItemGrid xs={false} sm={1} md={1}>{reading.callin_reason}</ItemGrid>
          </Hidden>
          <ItemGrid xs={1} sm={1} md={1} style={{textAlign: "right"}}>{reading.sensor1}</ItemGrid>
          <ItemGrid xs={1} sm={1} md={1} style={{textAlign: "right"}}>{reading.sensor2}</ItemGrid>
          <ItemGrid xs={1} sm={1} md={1} style={{textAlign: "right"}}>{reading.sensor3}</ItemGrid>
          <ItemGrid xs={1} sm={1} md={1} style={{textAlign: "right"}}>{reading.sensor4}</ItemGrid>
        </Grid>
      ))}
    </React.Fragment>
  )
}

export const IceQInfo = (props) => {
  const [tabState, setTab] = useState(0);
  const {classes, freezer, onIceQChartSelect, customReadings, customReadingsLoading, sendCommandButtonOnClick, sendCommandButtonMsg, sendRestartButtonOnClick, sendReConfigButtonOnClick, sendReConfigChanges, showSuccess, showFailuire,} = props;
  const initialConfig = {};
  // Loop through the iceq_config object and populate initialValues
  Object.entries(freezer.iceq_config).forEach(([key, value]) => {
    initialConfig[key] = value;
  });
  const configObjInput = { ...freezer.iceq_config};
  let [success] = useState(false);
  let[fail] = useState(false);
  let[checkin,setCheckin] = useState(false);
  let[restart,setRestart] = useState(false);
  let[config,setConfig] = useState(false);
  return (
  <React.Fragment>
<Grid container>
  <ItemGrid xs={12} sm={12} md={12}>
{freezer.iceq ? (
<div>
  <Divider/>
  <Typography variant="caption" component="title"  className={classes.cardSubtitle}>
  <Grid container>
    <Tabs value={tabState} onChange={(evt, value) => { setTab(value);}}>
      <Tab label="IceQ Device Information" />
      <Tab label="IceQ Configuration" />
    </Tabs>
    {tabState === 0 &&  <Grid container>
      <ItemGrid xs={4} sm={4} md={1} style={{textAlign: "left"}}>Serial:</ItemGrid>
      <ItemGrid xs={8} sm={8} md={3} style={{textAlign: "left"}}>{freezer.iceq.serial_number}</ItemGrid>
      <ItemGrid xs={4} sm={4} md={1} style={{textAlign: "left"}}>MEID:</ItemGrid>
      <ItemGrid xs={8} sm={8} md={3} style={{textAlign: "left"}}>{freezer.iceq.meid}</ItemGrid>
      <ItemGrid xs={4} sm={4} md={1} style={{textAlign: "left"}}>Mobile:</ItemGrid>
      <ItemGrid xs={8} sm={8} md={3} style={{textAlign: "left"}}>{freezer.iceq.mobile_number}</ItemGrid>
    </Grid>
    }
    {tabState === 1 &&  <Grid container>

      <Formik
          initialValues={
        initialConfig
          }
          onSubmit={(values, {setSubmitting, resetForm, setErrors,event}) => {
            const updatedConfig = { ...configObjInput, ...values };
            FreezerStore.storeApiConfigInfo(updatedConfig, freezer.id)
                .then(response => {
                  if (response === "success") {

                    success = true;
                    fail = false;

                  } else {
                    success = false;
                    fail = true;// Set success state to false
                  }
                  setSubmitting(false); // Reset loading state after API call completes
                })
                .catch(error => {
                  setErrors({ apiError: "An error occurred. Please try again." }); // Set form error
                  setSubmitting(false); // Reset loading state after error
                });
          }}
          validationSchema= {Yup.object().shape({
            test: Yup.string().min(1).max(10).required(),
            url: Yup.string().min(1).max(75).required(),
            udp_IP: Yup.string().min(1).max(16).required(),
            udp_PT: Yup.string().min(1).max(16).required(),
            freq: Yup.string().min(1).max(7).required(),
            level_TG: Yup.string().min(1).max(3).required(),
            melt_TG: Yup.string().min(1).max(3).required(),
            sensor_BK: Yup.string().min(1).max(5).required(),
            sensor_TB: Yup.string().min(1).max(5).required(),
            sensor_TS: Yup.string().min(1).max(5).required(),
            sonar_GN: Yup.string().min(1).max(5).required(),
            lidar_TB: Yup.string().min(1).max(7).required(),
            lidar_SR: Yup.string().min(1).max(7).required(),
            lidar_PP: Yup.string().min(1).max(7).required(),
            lidar_PF: Yup.string().min(1).max(7).required(),
            lidar_LT: Yup.string().min(1).max(7).required(),
            rangesource: Yup.string().min(1).max(5).required(),

          })}
          >
        {({ values, handleChange, errors, touched,handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {Object.entries(freezer.iceq_config).map((iconfig, iconfigidx) => (
                  <ItemGrid xs={12} sm={12} md={12} style={{ textAlign: "left" }} key={iconfig[0]}>
                    {iconfig[0] === 'url' || iconfig[0] === 'udp_IP' ? (
                        <CustomInput
                            labelText={iconfig[0]}
                            id={iconfig[0]}
                            formControlProps={{ fullWidth: false }}
                            // Disable input for url and udp_IP
                            inputProps={{
                              name: iconfig[0],
                              value: values[iconfig[0]],
                              disabled: true, // Disable editing
                              style: {
                                fontStyle: 'italic', // Apply italic for visual distinction
                              },
                            }}
                        />
                    ) : (
                        <CustomInput
                            labelText={iconfig[0]}
                            id={iconfig[0]}
                            formControlProps={{ fullWidth: false }}
                            errorText={errors[iconfig[0]]}
                            inputProps={{
                              name: iconfig[0],
                              value: values[iconfig[0]],
                              onChange: handleChange,
                              style: {
                                fontStyle: 'italic', // Apply italic on touch (for other fields)
                              },
                            }}
                        />
                    )}
                  </ItemGrid>
              ))}

              <ProgressButton
                  onClick= {sendReConfigButtonOnClick}
                  color="primary"
                  type="submit"
                  //loadingFailureMsg={success? showFailuire:sendCommandButtonMsg}
                  loadingFailureMsg={fail?"ERROR SAVING":""}
                  loadingMsg=""
                  loadingSuccessMsg={success?"SAVED SUCCESFULLY":""}
              >Save Config</ProgressButton>
            </form>
        )}
      </Formik>

    <SignedInContext.Consumer>
      {({signedInUser, changeMe}) => {
        if (signedInUser.roles === "iceq_admin"){
          return (

      <ProgressButton onClick= {()=>{
        sendCommandButtonOnClick()
        setCheckin(true)
        setTimeout(() =>{ setCheckin(false) }, 2000 );
      }}
                      color="primary"
                      className="form-submit"
                      type="submit"
                      loadingSuccessMsg= {checkin?"Sent please refresh shortly":""}
                      >

        Send Check In  </ProgressButton>)}}}
    </SignedInContext.Consumer>
      <SignedInContext.Consumer>
        {({signedInUser, changeMe}) => {
          if (signedInUser.roles === "iceq_admin"){
            return (

                <ProgressButton onClick= {()=>{
                                  sendRestartButtonOnClick()
                                  setRestart(true)
                                  setTimeout(() =>{ setRestart(false) }, 2000 );
                                }}
                                color="primary"
                                className="form-submit"
                                type="submit"
                                loadingSuccessMsg= {restart?"Sent please refresh shortly":""}
                >

                  Send Restart  </ProgressButton>)}}}
      </SignedInContext.Consumer>
      <SignedInContext.Consumer>
        {({signedInUser, changeMe}) => {
          if (signedInUser.roles === "iceq_admin"){
            return (
                <ProgressButton onClick= {()=>{
                                  sendReConfigChanges()
                                  setConfig(true)
                                  setTimeout(() =>{ setConfig(false) }, 2000 );
                                }}
                                color="primary"
                                className="form-submit"
                                type="submit"
                                loadingSuccessMsg= {config?"Sent please refresh shortly":""}
                >

                  Send Config</ProgressButton>)}}}
      </SignedInContext.Consumer>
    </Grid>
    } 
    
    

  </Grid>  

  <Grid container>
  <ItemGrid xs={12} sm={12} md={12}>
  { freezer.model.temp_only === 0 ? (
    <ZoomChart chartData={Array.from(freezer.summary_reading_activity)}
    onClick={(evt) => { onIceQChartSelect(evt.activeLabel*1000) }}
    xAxis={{datakey: 'unix_ts', type: 'number',
    tickFormatter: (unixTime) => DateTime.fromMillis(unixTime*1000, {zone: 'utc'}).toLocaleString(DateTime.DATE_SHORT)
  }}
    yAxis={[
      {datakey: 'avg_percent_full', type: 'number', color: '#8884d8', description: "Avg % Full"},
      {datakey: 'avg_temp', type: 'number', color: '#82ca9d', description: "Avg Temp"},
      {datakey: 'max_percent_full', type: 'number', color: '#0B678F',  description: "Max % Full"},
      {datakey: 'min_percent_full', type: 'number', color: '#939599',  description: "Min % Full"}
    ]}/>
  ): (
    <ZoomChart chartData={Array.from(freezer.summary_reading_activity)}
    onClick={(evt) => { onIceQChartSelect(evt.activeLabel*1000) }}
    xAxis={{datakey: 'unix_ts', type: 'number',
    tickFormatter: (unixTime) => DateTime.fromMillis(unixTime*1000, {zone: 'utc'}).toLocaleString(DateTime.DATE_SHORT)
  }}
    yAxis={[
      {datakey: 'avg_temp', type: 'number', color: '#82ca9d', description: "Avg Temp"},
      {datakey: 'min_temp', type: 'number', color: '#0B678F',  description: "Min Temp"},
      {datakey: 'max_temp', type: 'number', color: '#939599',  description: "Max Temp"}
    ]}/>
  )}


    
    </ItemGrid>
  </Grid>
  </Typography>
  </div>
): null}

{
  (freezer.last_5_iceq_readings.length > 0 ) ? (
    <div>
      <Divider/>
      <Typography variant="caption" component="title"  className={classes.cardSubtitle}>
      <Grid container>
        <ItemGrid xs={12} sm={12} md={12}>Last Communication</ItemGrid>
      </Grid>
        <Grid container>
          <ItemGrid xs={3} sm={3} md={3}>Last Call</ItemGrid>
          <ItemGrid xs={3} sm={3} md={3}>% Full</ItemGrid>
          <ItemGrid xs={3} sm={3} md={3}>Left {(freezer.product_left_side) ? (freezer.product_left_side.name) : "Not Set"}</ItemGrid>
          <ItemGrid xs={3} sm={3} md={3}>Right {(freezer.product_right_side) ? (freezer.product_right_side.name) : "Not Set"}</ItemGrid>
        </Grid>
        <Grid container>
          <ItemGrid xs={3} sm={3} md={3} style={{textAlign: "left"}}><TZConvert utcSqlDate={freezer.last_5_iceq_readings[0].callin_time}/></ItemGrid>
          <ItemGrid xs={3} sm={3} md={3}>{freezer.last_5_iceq_readings[0].percent_full}</ItemGrid>
          <ItemGrid xs={3} sm={3} md={3}>{freezer.last_5_iceq_readings[0].percent_full_left}</ItemGrid>
          <ItemGrid xs={3} sm={3} md={3}>{freezer.last_5_iceq_readings[0].percent_full_right}</ItemGrid>
        </Grid>
        <Divider/>
        <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>Details</ItemGrid>
        </Grid>
        {customReadingsLoading ? (
          <LoaderCircle/>
        ) : (
          <IceQReadings readings={customReadings ? customReadings : freezer.last_5_iceq_readings} />
        )}
        

      </Typography>
    </div>
    ) : (
      <Grid container><ItemGrid xs={12} sm={12} md={12}>No Communication History</ItemGrid></Grid>
    )
  }
  </ItemGrid></Grid>

  </React.Fragment>)

}

const Freezer = observer(
class Freezer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      showAddFreezer: false,
      showIceQ: false,
      showIceQDeletePrompt: -1,
      showAddTicket: false,
      expanded: null,
      customReadings: null,
      customReadingsLoading: false,
      loadMsg: "",
      successMsg: "",
      failureMsg: ""
     };
  }

  handleAddTicket = () => {
    this.setState({showAddTicket: true})
  }




  handleChangePanel = panel => (event, expanded) => {
  this.setState({
    expanded: expanded ? panel : false,
  })};

  handleIceQChartSelect = (selectedDate) => {
    var fdate = DateTime.fromISO(DateTime.fromMillis(selectedDate, {zone: 'utc'}).toFormat("yyyy-MM-dd'T'00:00:00")).toLocal().toUTC().toSQL()
    var tdate = DateTime.fromISO(DateTime.fromMillis(selectedDate + (1000*60*60*24), {zone: 'utc'}).toFormat("yyyy-MM-dd'T'00:00:00")).toLocal().toUTC().toSQL()
    FreezerStore.currentLocationId = this.props.freezer.location_id
    this.setState({ customReadingsLoading: true })
    FreezerStore.loadIceQReadings(this.props.freezer.id, fdate, tdate, (res) => {
      this.setState({customReadings: res, customReadingsLoading: false })
    })    
  }

  checkInResp = () => {
    this.setState( { loadMsg: "Sent please refresh",
      successMsg: "success",
      failureMsg: ""
    })
  }

  render() {
    const { classes, freezer, loggedInUser } = this.props;
    const { expanded } = this.state;
    if (!this.props.loaded) return null
    var freezers = null;

    if(freezer){
        var img = ""
        try {
          img = freezer.model.manufacturer === "Leer" ? require("assets/img/freezers/"+freezer.model_id+ ".jpg") :
          require("assets/img/freezers/"+freezer.model_id+ ".png")
        } catch(err) {
          img = require("assets/img/freezers/noimageavailable.svg")
        }
        freezers = (
          <ItemGrid xs={12} sm={12} md={12}  key={`freezerCard`}>

          <ProfileCard
            avatar={img}
            subtitle={(freezer.placement) ? (freezer.placement.name) : "Placement not set"}
            title={IceQUtils.modelDropDrownFormat(freezer.model)  + "  " + freezer.serial_number}
            description={
              <div>
                <Divider/>
                <Grid container>
                {loggedInUser.plan_features_list && loggedInUser.plan_features_list.feat_inventory? (
                  <Fragment>
                  <Grid container>
                    <ItemGrid xs={12} sm={4} md={4}>{freezer.status} {IceQUtils.freezerTypeById(freezer.freezer_type_id.toString()).type} since <TZConvert format="DATE_SHORT" utcSqlDate={freezer.install_date}/> </ItemGrid>
                    <ItemGrid xs={12} sm={4} md={4}>{((freezer.asset_tag) ? ("Asset Tag:" + freezer.asset_tag) : "No Asset Tag")}</ItemGrid>
                    <ItemGrid xs={12} sm={4} md={4}>{((freezer.condition) ? (freezer.condition.name) : "Unknown") + " Condition"}</ItemGrid>
                  </Grid>
                  </Fragment>
                ): (
                  <Fragment>
                  <Grid container>
                    <ItemGrid xs={12} sm={6} md={6}>{freezer.status} {IceQUtils.freezerTypeById(freezer.freezer_type_id.toString()).type} since <TZConvert format="DATE_SHORT" utcSqlDate={freezer.install_date}/> </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={6}>{((freezer.condition) ? (freezer.condition.name) : "Unknown") + " Condition"}</ItemGrid>
                  </Grid>
                  </Fragment>                 
                )}
                </Grid>
                {loggedInUser.plan_features_list && loggedInUser.plan_features_list.feat_inventory? (
                  <div>
                  <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChangePanel('panel1')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>Tickets</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{paddingLeft:"0px", paddingRight:"0px"}}>
                      <Grid container>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <Typography variant="caption"
                            component="title"
                            className={classes.cardSubtitle}>
                          {freezer.open_tickets.concat(freezer.last_5_closed_tickets).map((ticket, readingIdx) => (
                            <div  key={`ticket${readingIdx}`}>
                            <Grid container>
                              <ItemGrid xs={12} sm={12} md={12} style={{textAlign: "left"}}>
                                <Ticket store={TicketStore} displayFormat="at-location"
                                  onClickTicket={(ticket_id) => {
                                    TicketStore.getTicket(ticket_id, (res) => {
                                      this.setState({ showIceQ: false, showAddFreezer: false, showAddTicket: true})
                                    })
                                }} ticket={ticket}/>
                              </ItemGrid> 
                            </Grid>
                            </div>
                          ))}
                        </Typography>
                      </ItemGrid>
                    </Grid>
                  </ExpansionPanelDetails>
                  <ExpansionPanelActions style={{paddingLeft:"24px", paddingRight:"24px"}}>
                  <Button color="primary" aria-label="Add"
                    component={Link} to={`/tickets?title=${encodeURIComponent("History for " + IceQUtils.modelDropDrownFormat(freezer.model))}&search=${encodeURIComponent("freezer_id=" + freezer.id)}`}
                     className={classes.button}
                     arrayidx="-1"
                     floating={false}
                   >History</Button>
                    <Button color="primary" aria-label="Add Ticket"
                      onClick={ (res) =>{
                        FreezerStore.quickSearch({freezer_id: freezer.id},
                          (res) => {
                            TicketStore.currentTicket = TicketStore.getDefaultTicket({
                              freezer: res,
                              location_id: res.location.id,
                              location: res.location,
                              freezer_id: freezer.id
                            })
                            this.setState({
                              showIceQ: false,
                              showAddFreezer: false,
                              showAddTicket: true,
                              freezerdata: this.props.freezer
                            })
                          })
                        }
                      }
                      className={classes.button}
                      arrayidx="-1"
                      floating={false}
                      freezer_id={freezer.id}>Add Ticket
                    </Button>
                  </ExpansionPanelActions>
                </ExpansionPanel>


<ExpansionPanel expanded={expanded === 'panel2'} onChange={this.handleChangePanel('panel2')}>
  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
    <Typography className={classes.heading}>IceQ</Typography>
  </ExpansionPanelSummary>
  <ExpansionPanelDetails  style={{paddingLeft:"0px", paddingRight:"0px"}}>
  <IceQInfo customReadingsLoading={this.state.customReadingsLoading}
            sendCommandButtonOnClick={(res)=>{
              alert("ell0")
              this.setState( { loadMsg: "Sending...",
                successMsg: "",
                failureMsg: ""
              });        FreezerStore.storeApiCall(freezer.id)
              setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
            }}
            sendRestartButtonOnClick={(res)=>{
              this.setState( { loadMsg: "Sending...",
                successMsg: "",
                failureMsg: ""
              });        FreezerStore.storeApiRestartCall(freezer.id)
              setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
            }}
            sendReConfigChanges={(res)=>{
              this.setState( { loadMsg: "Sent, please refresh shortly",
                successMsg: "",
                failureMsg: ""
              });        FreezerStore.storeApiConfigSend(freezer.id)
              setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
            }}
            sendReConfigButtonOnClick={(res)=>{
              this.setState( { loadMsg: "Sending...",
                successMsg: "",
                failureMsg: ""
              });
              setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
            }}
            showSuccess={(res)=>{
              this.setState( { loadMsg: "",
                successMsg: "",
                failureMsg: ""
              });
              setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
            }}
            showFailuire={(res)=>{
              this.setState( { loadingSaveMsg: "",
                loadingSaveSuccessMsg: "",
                loadingSaveFailureMsg: "Error"
              });
              setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
            }}
            sendCommandButtonMsg=""
            onIceQChartSelect={this.handleIceQChartSelect}
            customReadings={this.state.customReadings}{...this.props}/>
  </ExpansionPanelDetails>
</ExpansionPanel></div>
                ): (<IceQInfo customReadingsLoading={this.state.customReadingsLoading}
                              sendCommandButtonOnClick={(res)=>{
                                this.setState( { loadMsg: "Sent, please refresh shortly",
                                  successMsg: "",
                                  failureMsg: ""
                                });        FreezerStore.storeApiCall(freezer.id)
                                setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
                              }}
                              sendRestartButtonOnClick={(res)=>{
                                this.setState( { loadMsg: "Sent, please refresh shortly",
                                  successMsg: "",
                                  failureMsg: ""
                                });        FreezerStore.storeApiRestartCall(freezer.id)
                                setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
                              }}
                              sendReConfigChanges={(res)=>{
                                this.setState( { loadMsg: "Sent, please refresh shortly",
                                  successMsg: "",
                                  failureMsg: ""
                                });         FreezerStore.storeApiConfigSend(freezer.id)

                                setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
                              }}
                              sendReConfigButtonOnClick={(res)=>{
                                this.setState( { loadMsg: "Sending...",
                                  successMsg: "",
                                  failureMsg: ""
                                });
                                setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
                              }}
                              showSuccess={(res)=>{
                                this.setState( { loadMsg: "Saving Successful",
                                  successMsg: "",
                                  failureMsg: ""
                                });
                                setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
                              }}
                              showFailuire={(res)=>{
                                this.setState( { loadingSaveMsg: "",
                                  loadingSaveSuccessMsg: "",
                                  loadingSaveFailureMsg: "Error"
                                });
                                setTimeout(() =>{ this.setState(defaultSaveMsgs); }, 2000 );
                              }}
                              sendCommandButtonMsg={this.state.loadMsg}
                              onIceQChartSelect={this.handleIceQChartSelect}
                              customReadings={this.state.customReadings} {...this.props}/>)}


                </div>
              }
            footer={<div>
              {loggedInUser && loggedInUser.plan_features_list && loggedInUser.plan_features_list.feat_inventory ? (<Button
                color="primary"
                className={classes.button}
                onClick={ (res) =>{
                  FreezerStore.quickSearch({freezer_id: freezer.id},
                    (res) => {
                      TicketStore.currentTicket = TicketStore.getDefaultTicket({
                        freezer: res,
                        location_id: res.location.id,
                        location: res.location,
                        freezer_id: freezer.id,
                        ticket_type_id: TicketStore.moveTicketTypeId(),
                        title: "Move this freezer"
                      })
                      this.setState({
                        showIceQ: false,
                        showAddFreezer: false,
                        showAddTicket: true,
                        freezerdata: this.props.freezer
                      })
                    })
      }
                }
                arrayidx="-1"
                freezer_id={freezer.id}
                >Move</Button>
              ):null}
              <Button
              color="primary"
              className={classes.button}
              location_id={this.props.freezer.location_id}
              onClick={ (res) => {
                  this.setState({
                    showIceQ: false,
                    showAddFreezer: true,
                    freezerdata: this.props.freezer
                  })
                }
              }
              >Edit</Button>
               {(freezer.iceq_id) ? (
                  <Button color="danger"
                    onClick={ (res) => {
                    this.setState({
                      showIceQ: false,
                      showAddFreezer: false,
                      showIceQDeletePrompt: Number(res.currentTarget.getAttribute('freezeridx'))})
                    }}
                    className={classes.button}
                    location_id={this.props.freezer.location_id}>Remove ICEQ
                  </Button>
               ) : (
                 <Button color="success"
                  onClick={(res) => {
                       this.setState({
                         showIceQ: true,
                         showAddFreezer: false,
                         freezerdata: this.props.freezer
                       })
                     }
                   }
                   className={classes.button}
                   arrayidx="-1"
                   location_id={this.props.freezer.location_id}>
                   Add IceQ
                </Button>
              )}
              {(freezer.iceq_id &&
                Math.abs(DateTime.fromISO(freezer.updated_at,{zone: "UTC"}).diffNow().values.milliseconds) < 600000 &&
                !(freezer.last_5_iceq_readings && freezer.last_5_iceq_readings.length > 0)) ? (
                  <Button  color="primary"
                  onClick={(res) => {
                      this.props.store.getLocation(this.props.freezer.location_id)
                      }
                    }
                  >Refresh</Button>

              ):(null)}
              </div>
            }
          />

        </ItemGrid>
        );
  }
    return (
    <div className={classes.root}>
      <Grid container>
        {freezers}
        <ConfirmationDialog
         isOpen={this.state.showIceQDeletePrompt}
         dropSelectionText="Reason for removal?"
         dropSelectionValueDefault="Provisioned"
         dropSelection={
           [{"value": "Provisioned", "desc": "Uninstall"},
           {"value": "Disfunction", "desc": "IceQ not working"}]
         }
         onAgree={(value)=> {
           this.props.store.removeIceQ({id: this.props.freezer.id, iceq_remove_type: value})
           this.setState({
             showIceQ: false,
             showAddFreezer: false,
             showIceQDeletePrompt: -1,
             freezerdata: this.props.freezer
           })
           }
         }
         onDisagree={(value)=> {
           this.setState({
             showIceQ: false,
             showAddFreezer: false,
             showIceQDeletePrompt: -1,
             freezerdata: this.props.freezer
           })
           }
         }
         title="Remove IceQ Device from this freezer?"
         content="By selecting Agree, you will remove this IceQ device mapping with this location.  You will not be able to undo this."/>
      </Grid>
        <FreezerEdit
          data={freezer}
          store={this.props.store}
          loaded={(this.state.showAddFreezer)}
          loggedInUser={this.props.loggedInUser}
          onClose={ (res) => {
            this.setState({showAddFreezer: false})
          }
        }/>
        <TicketForm
          freezer_id={freezer.id}
          store={TicketStore}
          ticket={TicketStore.currentTicket ? TicketStore.currentTicket : TicketStore.getDefaultTicket({freezer_id: freezer.id })}
          loaded={(this.state.showAddTicket)}
          onClose={ (res) => {
            this.setState({showAddTicket: false})
          }}
          onSave={ (res) => {
            this.props.store.refreshLocation()
          }}
          />
        <IceQFormDialog
          data={freezer}
          store={this.props.store}
          loaded={(!this.props.store.isLoading && this.state.showIceQ)}
          onClose={ (res) => {
            this.setState({showIceQ: false})
          }
        }/>

      </div>
    );
  }
})
Freezer.propTypes = {
  freezer: PropTypes.object,
  store: PropTypes.object,
  loggedInUser: PropTypes.object,
  loaded: PropTypes.bool,
  preferredFreezer: PropTypes.string

};
 export default withStyles(styles, { withTheme: true })(Freezer);
 // export default withStyles(styles)(Freezers);
