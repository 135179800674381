import React, { Component } from 'react';
import { Grid, TextField, Typography, Paper, Avatar } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { AuthService, withAuth, ItemGrid, CustomInput } from "components";

import PropTypes from "prop-types";
import Downshift from 'downshift';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  appBar: {
    position: 'relative',
    backgroundColor: "#EEEEEE"
  },
  flex: {
    paddingLeft: '10px',
    flex: 1,
    color:  "#0b678f"
  },
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  colorPrimary: {
      backgroundColor: "#0b678f"
    },
  content: {
    flex: '1 0 auto',
  },
  avatar: {
    margin: 10,
    borderRadius: '10px'
  }
});

const itemToString = (item) => {
  if(item) {
    return item.serial_number;
  } else {
    return "";
  }
}

function renderSuggestion({classes, suggestion, index, itemProps, highlightedIndex, selectedItem,lockToRegion }) {
  const isHighlighted = highlightedIndex === index;
  //const isSelected = (selectedItem || '').indexOf(suggestion) > -1;
const isSelected = true;
  return (

    <MenuItem
      {...itemProps}
      key={index}
      disabled={(lockToRegion && lockToRegion > 0) &&
      ((!(suggestion.freezer) || !(suggestion.freezer.location)) ||
        (suggestion.freezer &&
        suggestion.freezer.location &&
        suggestion.freezer.location.client_region_id !== lockToRegion))
      }
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        height: "auto"
      }}
    >
    <Grid container>
      <ItemGrid xs={3} sm={2} md={1}>
         <Avatar alt="Carrier" src={require("assets/img/carriers/3.png")} className={classes.avatar}/>
      </ItemGrid>
      <ItemGrid xs={9} sm={10} md={11}>
        <Grid container>
          <ItemGrid xs={12} sm={5} md={5}>
            <Typography variant="title" component="title">
            Serial: {suggestion.serial_number}
            </Typography>
            <Typography variant="caption" component="title">
            MEID: {suggestion.meid}
            </Typography>
            <Typography  variant="caption" component="title">
            Number: {suggestion.mobile_number}
            </Typography>
            <Typography  variant="caption" component="title">
            Status: {suggestion.status} Event Status: {suggestion.event_status}
            </Typography>
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={6}>
          {(suggestion.freezer && suggestion.freezer.location ) ? (
            <div>
            <Typography  variant="caption" component="title">
              {suggestion.freezer.location.name}
            </Typography>
            <Typography  variant="caption" component="title">
              {suggestion.freezer.location.street}
            </Typography>
            <Typography  variant="caption" component="title">
              {suggestion.freezer.location.city}, {suggestion.freezer.location.state}, {suggestion.freezer.location.zip}
            </Typography>
            <Typography  variant="caption" component="title">
              Region: {suggestion.freezer.location.client_region.name}
            </Typography>
            </div>
            ): null}
            </ItemGrid>
          </Grid>
      </ItemGrid>
    </Grid>

    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  classes: PropTypes.object,
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  lockToRegion: PropTypes.number,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function stateReducer(state, changes) {
  // this prevents the menu from being closed when the user
  // selects an item with a keyboard or mouse
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        isOpen: state.isOpen,
        highlightedIndex: state.highlightedIndex,
      }
    default:
      return changes
  }
}

function debounce(fn, time) {
  let timeoutId
  return wrapper
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      timeoutId = null
      fn(...args)
    }, time)
  }
}

class IceQFormSub extends Component {

  constructor(props) {
    super(props);
    this.state = {items: [], selectedIceq: {}}
  }

componentDidMount(){

}



onSelectedPuppy = debounce((item, changes) => {
  this.setState({selectedIceq: item});
  if(this.props.onSelected) {
      this.props.onSelected(item)
  }

},300)

renderInput = (inputProps) => {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      variant="outlined"
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

handleInputChange = (inputValue, stateAndHelpers) => {
  this.getSuggestions(inputValue)
   //this.setState({ inputValue: event.target.value });
 };


getSuggestions = debounce(inputValue => {
  let Auth = new AuthService()
  let strArr = {}
  var url = `${Auth.domain}/iceqs`
  if(inputValue) {
    strArr["search"] = inputValue
  }
  if(this.props.allStatuses){
    strArr["all"] = "true"
  }
  var strArg = Object.entries(strArr).map(([key, val]) => `${key}=${val}`).join('&');
  if(strArg) {
    url = `${Auth.domain}/iceqs?${strArg}`
  }
  Auth.fetch(url, { method: 'GET' }).then(response => {
  // onchange(response)
  const items = response.map(
    item => {
      item.label = item.serial_number;
      return item;
    }
  )
  this.setState({items: items})
 }
)
}, 300)

// addIceQ = debounce(evnt => {
// this.props.store.addIceQ(this.state.selectedIceq, {id: this.props.data.id});
//   this.props.onClose(evnt);
// }, 300)

  render() {
    const { classes, lockToRegion } = this.props;
    if (!this.props.loaded) return null
    return (
        <div className="center">
            <div className="card">
              <Grid container>
                <ItemGrid xs={12}>
                  <div>
                    <Grid container>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <Downshift itemToString={itemToString} stateReducer={stateReducer}
                          onSelect={this.onSelectedPuppy} onInputValueChange={this.handleInputChange} >
                          {({ getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex }) => (
                          <div className={classes.container}>
                          {this.renderInput({
                            fullWidth: true,
                            classes,
                            InputProps: getInputProps({
                              placeholder: 'Search an IceQ Unit',
                              id: 'selectIceQ'
                            }),
                          })}
                          {isOpen ? (
                            <Paper className={classes.paper} square>
                            {this.state.items.map((suggest, index) => {
                              return (
                              renderSuggestion({classes, suggestion: suggest ,
                                index,
                                itemProps: getItemProps({ item: suggest }),
                                highlightedIndex,
                                selectedItem,
                                lockToRegion
                              }))
                            })}
                            </Paper>
                          ) : null }

                          </div>
                          )}
                        </Downshift>
                      </ItemGrid>
                    </Grid>
                  </div>
                </ItemGrid>
              </Grid>
            </div>
          </div>);
  }


}
IceQFormSub.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  store: PropTypes.object,
  loaded: PropTypes.bool,
  allStatuses: PropTypes.bool,
  lockToRegion: PropTypes.number,
  onSelected: PropTypes.func
}
 export default withStyles(styles)(withAuth(IceQFormSub));
