import React from 'react';
import ReactDOM from 'react-dom';
import Login from './components/Login';


//import registerServiceWorker from './registerServiceWorker';
import "./assets/css/material-dashboard-react.css";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/css/material-dashboard-react.css";
import indexRoutes from "routes/index.jsx";
//import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
const hist = createBrowserHistory();
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

// ReactDOM.render(<App />, document.getElementById('root'));


ReactDOM.render(
  <Router history={hist}>
    <Switch>
    <Route exact path="/login" component={Login}/>
      {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  </Router>
,
    document.getElementById('root')
  );
//registerServiceWorker();
