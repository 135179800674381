import {autorun, observable, decorate } from "mobx";
import AuthService from 'components/AuthService';
class SupportDataStore  {
timezones = [];
isLoading = true;
dashboard = {}
vale = 0;
Auth = new AuthService();



 loadTimezones(){
   this.isLoading = true
     //this.users = []
         this.Auth.fetch(`${this.Auth.domain}/timezones`,
         {method: 'GET'}
       )
        .then(response => {
         if(response.length <= 0 ){
           //TODO: raise exception as not found.
         }
         this.timezones = response;
         this.isLoading = false;
        }
        )
        return true;
 }

 loadDash(onSuccess, onFailure){
     //this.users = []
         this.Auth.fetch(`${this.Auth.domain}/dashboard`,
         {method: 'GET'}
       )
        .then(response => {
         if(response.length <= 0 ){
           //TODO: raise exception as not found.
         }
         this.dashboard = response;
         onSuccess(response)
        }
        )
        return true;
 }
}
decorate(SupportDataStore, {
  isLoading: observable
})
  var supportDataStore = window.supportDataStore = new SupportDataStore()
export default supportDataStore
autorun(() => {});
