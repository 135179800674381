import React from "react";
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  CardActions
} from "@material-ui/core";
import PropTypes from "prop-types";
import cx from "classnames";

import switchCardStyle from "variables/styles/switchCardStyle";

function SwitchCard({ ...props }) {
  const {
    classes,
    headerColor,
    plainCard,
    cardTitle,
    cardSubtitle,
    content,
    footer,
    titleOnly
  } = props;
  const plainCardClasses = cx({
    [" " + classes.cardPlain]: plainCard
  });
  const cardPlainHeaderClasses = cx({
    [" " + classes.cardPlainHeader]: plainCard
  });

  const cardHeaderTitleOnlyClasses = cx({
    [" " + classes.cardHeaderTitleOnly]: titleOnly
  });

  return (
    <Card className={classes.card + plainCardClasses}>
      <CardHeader
        classes={{
          root:
            classes.cardHeader + " " + cardHeaderTitleOnlyClasses + " " +
            classes[headerColor + "CardHeader"] +
            cardPlainHeaderClasses ,
            title: classes.cardTitle,
            subheader: classes.cardSubtitle
        }}
        title={cardTitle}
        subheader={cardSubtitle}
      />
      {titleOnly ? null : (
  <CardContent style={{paddingLeft:"2px", paddingRight:"2px"}} >{content}</CardContent>
      )}
    </Card>
  );
}

SwitchCard.defaultProps = {
  headerColor: "purple"
};

SwitchCard.propTypes = {
  plainCard: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple","white","iceq"]),
  cardTitle: PropTypes.node,
  cardSubtitle: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node,
  titleOnly: PropTypes.bool
};

export default withStyles(switchCardStyle)(SwitchCard);
