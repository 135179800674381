import React from "react";
import {
  withStyles,
  Card,
  CardContent,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";

 import {AlarmIndicator } from "components"


import alarmCardStyle from "variables/styles/alarmCardStyle";

function AlarmCard({ ...props }) {
  const {
    classes,
    percentFull,
    temperature,
    hasErrors,
    hasIceq
  } = props;



  if(hasErrors || !hasIceq){
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
      <div>

        {!hasIceq ? (
        <AlarmIndicator currentValue="5"
         iconStyle="IceQ" iconSize="small" />
        ) : (
        <AlarmIndicator currentValue="5"
         iconStyle="NoComm" iconSize="small" />
          )}
        </div>
      </CardContent>
    </Card>
  );
  }else {
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
      <div className={classes.cardStats}>
        <Typography component="p" className={classes.cardCategory}>
        <AlarmIndicator
          currentValue={percentFull}
          iconStyle="Fullness"
          iconSize="tiny"
        />{percentFull}{percentFull !== undefined ? (
            <small className={classes.cardTitleSmall}>%</small>
          ) : null}
        </Typography>
        <Typography
          component="p"
          className={classes.cardCategory}
        >
          <AlarmIndicator
            currentValue={temperature}
            iconStyle="Temperature"
            iconSize="tiny"
          />
        {temperature}
          {temperature !== undefined ? (
            <small className={classes.cardTitleSmall}>F</small>) : null}
        </Typography>
        </div>

      </CardContent>

    </Card>
  );
  }
}

AlarmCard.defaultProps = {
  iconColor: "purple",
  statIconColor: "gray"
};

AlarmCard.propTypes = {
  classes: PropTypes.object.isRequired,
  percentFull: PropTypes.node,
  hasErrors: PropTypes.bool,
  hasIceq: PropTypes.bool,
  percentWarningLimit: PropTypes.node,
  percentAlarmLimit: PropTypes.node,
  temperature: PropTypes.node,
  tempAlarmLimit: PropTypes.node,
  tempWarningLimit: PropTypes.node,
  raiseTempAlert: PropTypes.bool,
  statIconColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ])
};

export default withStyles(alarmCardStyle)(AlarmCard);
