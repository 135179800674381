import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import { PersonAdd  } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import {
withAuth,
RegularCard,
ButtonFab,
ItemGrid
} from "components";

import userStore from 'store/UserStore';
import  { observer } from "mobx-react";
import InfiniteScroll from 'react-infinite-scroller';
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

const Users = observer(
class Users extends Component {

  componentDidMount(){
    userStore.loadUsers();
  }

  loadItems(page) {
    userStore.loadUsers(page)
  }

  hasMoreItems() {
    return userStore.hasNextPage
  }

  render() {
    const { classes } = this.props;
    if(userStore.isLoading) { return "<div>Loading</div>"}
    var items = [];
    userStore.users.map((cuser, i) => {
        items.push(
          <div key={`div${cuser.id}`}>
            <ListItem component={Link} to={`userdd/${cuser.id}`} key={`list${cuser.id}`} button divider>
                <Grid container>
                  <ItemGrid xs={3} sm={2} md={1}>
                    <Avatar className={classes.avatar}><PersonIcon/></Avatar>
                  </ItemGrid>
                  <ItemGrid xs={9} sm={7} md={5}>
                    <Typography variant="title" component="title" className={classes.cardSubtitle}>
                      {cuser.firstname} {cuser.lastname}
                    </Typography>
                    <Typography variant="caption" component="title" className={classes.cardSubtitle}>
                      {cuser.email}
                    </Typography>
                  </ItemGrid>
                  <ItemGrid xs={3} sm={1} md={1}></ItemGrid>
                  <ItemGrid xs={9} sm={2} md={3}>
                    <Typography variant="caption" component="title" className={classes.cardSubtitle}>
                      {userStore.role_list[cuser.roles]}
                    </Typography>
                  </ItemGrid>
                  <ItemGrid xs={3} sm={1} md={1}></ItemGrid>
                  <ItemGrid xs={9} sm={1} md={3}>{cuser.mobile_number}
                  <Typography variant="caption" component="title"  className={classes.cardSubtitle}>
                     {cuser.client_region ? cuser.client_region.name : null} {cuser.id}
                  </Typography>
                  </ItemGrid>
                </Grid>
              </ListItem>
            </div>
        );
        return "";
    });

    return (
      <div>
    <RegularCard
      headerColor="iceq"
      cardTitle="Manage Users "
      content={
        <div style={{height:"700px",overflow:"auto"}}>
            <InfiniteScroll pageStart={1}
                        loadMore={this.loadItems.bind(this)}
                        hasMore={userStore.hasNextPage}
                        loader={<div  className="loader" key={0}>Loading ...</div>}
                        useWindow={true}
                    >
        <List>
          {items}
        </List>
        </InfiniteScroll>
        </div>
      }/>
      <ButtonFab 
        color="primary"
        aria-label="add"
        floating={true}
        component={Link} to={`users/add`}
        className={classes.button}
        arrayidx="-1"
        regionid="new">
       <PersonAdd />
     </ButtonFab>
</div>
    )
  }
})

export default withAuth(withStyles(styles)(Users));
