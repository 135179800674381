import DashboardPage from "views/Dashboard/DashboardShell.js";
import User from "views/UserProfile/user/User.js";
import Users from "views/UserProfile/Users.js";
import DeepLink from "views/UserProfile/DeepLink.js";
import LocationsCard from "views/locations/LocationsCard.js";
import TicketsCard from "views/Tickets/TicketsCard.js";
import FreezersCard from "views/locations/FreezersCard.js";
import Location from "views/locations/Location.js";
import Warehouse from "views/locations/Warehouse.js";
import IceQSearch from "views/locations/IceQSearch.js";
import FreezerSearch from "views/locations/FreezerSearch.js";
import Freezer from "views/locations/Freezer.js";
import Account from "views/Account/Account.js";

import Signup from "components/Signup.js";

import Maps from "views/Maps/Maps.jsx";
import NotificationsPage from "views/Notifications/Notifications.jsx";

import {
  Dashboard,
  Person,
  LocationOn,
  Notifications,
  Map,
  People,
  PersonAdd,
  AccountBox,
  Face,
  FiberNew,
  MyLocation,
  LocalOffer,
  LocationSearching
} from "@material-ui/icons";

const appRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    showme: true,
    navbarName: "Dashboard",
    icon: Dashboard,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: DashboardPage
  }, {
    path: "/me",
    sidebarName: "Me",
    showme: false,
    navbarName: "Profile",
    icon: Face,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: User
  }, {
    path: "/account",
    sidebarName: "Account",
    showme: true,
    navbarName: "Account",
    icon: AccountBox,
    allowedRoles: ["iceq_admin", "account_admin"],
    component: Account
  }, {
    path: "/users/add",
    sidebarName: "Add User",
    showme: false,
    navbarName: "Add User",
    icon: PersonAdd,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin"],
    component: User
  }, {
    path: "/userdd/:id",
    sidebarName: "User Profile",
    showme: false,
    navbarName: "Profile",
    icon: Person,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin"],
    component: User
  }, {
    path: "/users",
    sidebarName: "Users",
    showme: true,
    navbarName: "Users",
    icon: People,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin"],
    component: Users
  }, {
    path: "/locations/add",
    sidebarName: "Add Location",
    showme: false,
    navbarName: "Add Location",
    icon: LocationOn,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: Location
  }, {
    path: "/location/:id",
    sidebarName: "Location",
    showme: false,
    navbarName: "Location",
    icon: LocationOn,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: Location
  }, {
    path: "/warehouse/:id/freezers/:freezer_id",
    sidebarName: "Location",
    showme: false,
    navbarName: "Location",
    icon: LocationOn,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: Freezer
  }, {
    path: "/warehouse/:id/freezers",
    sidebarName: "Location",
    showme: false,
    navbarName: "Location",
    icon: LocationOn,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: FreezersCard
  }, {
    path: "/warehouse/:id",
    sidebarName: "Location",
    showme: false,
    navbarName: "Location",
    icon: LocationOn,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: Warehouse
  }, {
    path: "/locations",
    sidebarName: "Locations",
    navbarName: "Locations",
    icon: LocationOn,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: LocationsCard
  },  {
    path: "/iceqs",
    sidebarName: "Search IceQs",
    showme: true,
    navbarName: "Search IceQs",
    icon: MyLocation,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: IceQSearch
  }, {
    path: "/maps",
    sidebarName: "Maps",
    showme: true,
    navbarName: "Map",
    icon: Map,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: Maps
  }, {
    path: "/tickets",
    sidebarName: "Tickets",
    showme: true,
    navbarName: "Tickets",
    features: ["feat_inventory"],
    icon: LocalOffer,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: TicketsCard
  }, {
    path: "/freezerscan",
    sidebarName: "Scan Tag",
    showme: true,
    navbarName: "ScanTag",
    features: ["feat_inventory"],
    icon: LocationSearching,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: FreezerSearch
  },
  {
    path: "/deeplink",
    sidebarName: "Deep Link",
    showme: false,
    navbarName: "DeepLink",
    icon: LocationSearching,
    allowedRoles: ["iceq_admin", "account_admin", "region_admin", "user"],
    component: DeepLink
  },
   {
    path: "/signup",
    sidebarName: "New Client",
    navbarName: "New Client",
    icon: FiberNew,
    allowedRoles: ["iceq_admin"],
    component: Signup
  }, {
    path: "/notifications",
    sidebarName: "Notifications",
    showme: false,
    navbarName: "Notifications",
    icon: Notifications,
    component: NotificationsPage
  }, {
    redirect: true,
    path: "/",
    to: "/dashboard",
    navbarName: "Redirect"
  }
];

export default appRoutes;
